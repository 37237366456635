import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectDepartment } from '../../../select/SelectDepartment';


import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';

export default function MultipleRegistration() {
    const { Option } = Select;

    const fetchshiftList = useStoreActions((state) => state.student.fetchShiftList);
    const shiftList = useStoreState((state) => state.student.shiftList);
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const [selectedSessionYear, setselectedSessionYear] = useState<any>();
    const [selectedClassDepartment, setSelectedClassDepartment] = useState();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const [selectedSemesterShift, setSelectedSemesterShift] = useState();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const checkReg = useStoreState((state) => state.student.checkReg);
    const setcheckReg = useStoreActions((state) => state.student.setcheckReg);
    // const setRegistrationTableRow = useStoreActions((state) => state.student.setRegistrationTableRow);
    // const registrationTableRow = useStoreState((state) => state.student.registrationTableRow);
    const createRegistrationdatawithCustomId = useStoreActions((state) => state.student.createRegistrationdatawithCustomId)
    const [tableRowStore, setTableRowStore] = useState<any>([]);
    useEffect(() => {
        fetchClassConfigList();
        fetchshiftList();
        fetchSessionList();
    }, []);

    const setSessionYearId = (e) => {

    }

    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        getSemeterYear(value);
    }

    const getshiftData = (val) => {
        setSelectedSemesterShift(val);
        if (selectedSessionYear == undefined || selectedClassDepartment == undefined || selectedSemesterYear == undefined) {
            notification.error({ message: 'Please select required fields' });
        } else {
            if (tableRowStore.length === 0) {
                addTableRow();
            }
        }
    }

    const addTableRow = () => {
        let tableRow = [
            {
                key: uuidv4(),
                customStudentId: "",
                fatherName: "",
                gender: "",
                motherName: "",
                registrationNo: "",
                religion: "",
                studentMobile: "",
                studentName: "",
                studentRoll: ""
            }
        ]
        let temp = tableRowStore.concat(tableRow);
        setTableRowStore(temp);
        let lastItem = tableRowStore[tableRowStore.length - 1]
    }


    const deleteMe = (val) => {
        let temp = tableRowStore.filter(item => item.key != val.key)
        setTableRowStore(temp)
    }



    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        };

    const onchangeValueDropDown: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e;
            setTableRowStore(newData);
        };

    const [validation, setValidation] = useState<boolean>(false);

    /****Registration Table Start*****/
    const [updateForm] = Form.useForm();
    const columns: any = [
        // {
        //     title: 'Serial',
        //     dataIndex: '',
        //     key: '',
        //     render: (text, record, index) => (
        //         <span>{index + 1}</span>
        //     ),
        // },
        {
            title: 'Student Id',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Custom Id" name="customStudentId" className="stdName" style={{ width: 100 }} onChange={onchangeValue("customStudentId", record, index)} />
                    {validation && record.customStudentId == "" && <span className="error">Student id is required</span>}
                </>
            ),
        },
        {
            title: 'Student Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Student name" name="studentName" className="stdName" style={{ width: 200 }} onChange={onchangeValue("studentName", record, index)} />
                    {validation && record.studentName == "" && <span className="error">Student name is required</span>}
                </>
            ),
        },
        {
            title: 'Student Mobile',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Mobile" className="stdMobile" name="studentMobile" style={{ width: 130 }} onChange={onchangeValue("studentMobile", record, index)} />
                    {validation && (record.studentMobile.length > 11 ? <span className="error">Mobile number should be 11 digit</span> : record.studentMobile.length < 11 ? <span className="error">Mobile number should be 11 digit</span> : null)}
                </>
            ),
        },
        {
            title: 'Student Roll',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Roll" className="stdRoll" name="studentRoll" style={{ width: 80 }} onChange={onchangeValue("studentRoll", record, index)} />
                    {validation && record.studentRoll == "" && <span className="error">Student roll is required</span>}
                </>
            ),
        },
        {
            title: 'Registration No',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Registration No" className="regRoll" name="registrationNo" style={{ width: 100 }} onChange={onchangeValue("registrationNo", record, index)} />

                </>
            ),
        },
        {
            title: 'Father Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Father name" className="fatherName" name="fatherName" style={{ width: 200 }} onChange={onchangeValue("fatherName", record, index)} />
                    {validation && record.fatherName == "" && <span className="error">Father name is required</span>}
                </>
            ),
        },
        {
            title: 'Mother Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Mother name" className="motherName" name="motherName" style={{ width: 200 }} onChange={onchangeValue("motherName", record, index)} />
                    {validation && record.motherName == "" && <span className="error">Mother name is required</span>}
                </>
            ),
        },
        {
            title: 'Religion',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Select
                        placeholder="Select religion"
                        className="religion"

                        style={{ width: 150 }}
                        onChange={onchangeValueDropDown("religion", record, index)}
                    >
                        <Option value="Islam">Islam</Option>
                        <Option value="Hinduism">Hinduism</Option>
                        <Option value="Christian">Christian</Option>
                        <Option value="Buddist">Buddist</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                    {validation && record.religion == "" && <span className="error">Religion is required</span>}
                </>
            ),
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: '',
            render: (text, record, index) => (
                <>
                    <Select
                        placeholder="Select Gender"
                        className="gender"

                        style={{ width: 150 }}
                        onChange={onchangeValueDropDown("gender", record, index)}
                    >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                    </Select>
                    {validation && record.gender == "" && <span className="error">Gender is required</span>}
                </>
            ),
        },
        {
            title: 'Add/Del',
            dataIndex: '',
            key: '',
            fixed: "right",
            render: (text, record, index) => {

                return (
                    <Space size="small">
                        <Button danger onClick={() => deleteMe(record)} disabled={index === 0 ? true : false} icon={<DeleteOutlined />} />
                        <Button type="primary" onClick={() => addTableRow()} disabled={tableRowStore.length !== index + 1 ? true : false} icon={<PlusOutlined />} />
                    </Space>
                )
            },
        },
    ];

    const submitRegForm = () => {
        setValidation(true);

        setTimeout(() => {
            let error = document.getElementsByClassName("error");
            if (error.length > 0) {
                notification.error({ message: "Please fill all the required fields", description: "" });
            } else {
                let postData = {
                    "classDepartmentConfigId": selectedClassDepartment,
                    "sessionId": selectedSessionYear,
                    "sessionYearId": selectedSemesterYear,
                    "shiftId": selectedSemesterShift,
                    "studentList": tableRowStore
                }
                createRegistrationdatawithCustomId(postData);
    


            }

        }, 500);
    }

    useEffect(() => {
        if (checkReg===true) {
            setValidation(false);
            setTableRowStore([]);
            updateForm.resetFields();
            setcheckReg(false);
        }
    }, [checkReg]);
    /****Registration Table End******/
    return (
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={updateForm}
                    // onFinish={createSessionYearForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="classId"
                                    label="Session"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select session" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select session"
                                        onChange={(e) => { setselectedSessionYear(e); }}
                                    >
                                        {sessionList ? (
                                            sessionList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Session</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="departmentId"
                                    label="Class Department"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select Class Department" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Class Department"
                                        onChange={(e) => { getSemesterYear(e); }}
                                    >
                                        {classConfigList ? (
                                            classConfigList.map((type, idx) => (
                                                <Option key={type.configId} value={type.configId}>
                                                    {type.configName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="semesterYear"
                                    label="Semester Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select semester year" },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select semester year"
                                        optionFilterProp="children"
                                        onChange={(e) => { setSelectedSemesterYear(e); }}
                                    >
                                        {semesterYearByDepartmentId ? (
                                            semesterYearByDepartmentId.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                    name="shift"
                                    label="Shift"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select shift" },
                                    ]}
                                >
                                    <Select placeholder="Select semester year" onChange={(e) => getshiftData(e)}>
                                        {shiftList ? (
                                            shiftList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching shift</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30" style={{ display: tableRowStore.length > 0 ? 'block' : 'none' }}>
                <Col span="24">
                    <Table
                        columns={columns}
                        dataSource={tableRowStore}
                        bordered={true}
                        pagination={false}
                        className="p-datatable-responsive-demo"
                    />
                    <Button type="primary" icon={<SaveOutlined />} style={{ float: 'right' }} onClick={(e) => submitRegForm()} className="m-t-mo-30 mt-30">
                        Save
                    </Button>
                </Col>
            </Row>
        </>
    )
}