import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification } from 'antd';
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { SelectDepartment } from '../../select/SelectDepartment';


import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';
import { SelectClass } from '../../select/SelectClass';
import { SelectDepartmentByClass } from '../../select/SelectDepartmentByClass';
import { SelectSemesterYearByClass } from '../../select/SelectSemesterYearByClass';

export default function CreatePaySlipBatch() {
    const { Option } = Select;

    const fetchdepartmentListByClassId = useStoreActions((state) => state.common.fetchdepartmentListByClassId);
    const fetchsessionYearListByClassId = useStoreActions((state) => state.common.fetchsessionYearListByClassId);
    const [selectedSessionYear, setselectedSessionYear] = useState<any>();
    const [selectedClass, setSelectedClass] = useState<any>();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const [selectedDepartment, setselectedDepartment] = useState<any>();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const createClassWisePayslip = useStoreActions((state) => state.payslip.createClassWisePayslip);


    useEffect(() => {
        fetchSessionList();
    }, []);

    const savePaySlip = (value) => {
        createClassWisePayslip(value);
        submitForm.resetFields() 
        setselectedSessionYear(null);
        setSelectedClass(null);
        setSelectedSemesterYear(null);
        setselectedDepartment(null);

    }



    const [submitForm] = Form.useForm();


    /****Registration Table End******/
    return (
        <>
         
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            form={submitForm}
                            onFinish={savePaySlip}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="sesssionId"
                                        label="Session"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select session" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select session"
                                            value={selectedSessionYear}
                                            onChange={(e) => { setselectedSessionYear(e); }}
                                        >
                                            {sessionList ? (
                                                sessionList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Session</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="classId"
                                        label="Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                        <SelectClass selected={selectedClass} onChange={(e) => { fetchsessionYearListByClassId(e); fetchdepartmentListByClassId(e); setSelectedClass(e); setselectedDepartment(null); setSelectedSemesterYear(null) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="departmentId"
                                        label="Department"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select department" },
                                        ]}
                                    >
                                        <SelectDepartmentByClass selected={selectedDepartment} onChange={(e) => { setselectedDepartment(e) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                    <Form.Item
                                        name="semesterYearId"
                                        label="Semester Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester year" },
                                        ]}
                                    >
                                        <SelectSemesterYearByClass selected={selectedSemesterYear} onChange={(e) => { setSelectedSemesterYear(e) }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Button type="primary" htmlType="submit" style={{ height: 40 }} >
                                        Create
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

        </>
    )
}