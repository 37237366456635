
import { Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react'
import WaiverConfig from './FeeWaiver/WaiverConfig.page';
import WaiverConfigList from './FeeWaiver/WaiverConfigList.page';

const { TabPane } = Tabs;

export default function FeeWaiverConfig(props) {

    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <Card title="Waiver Configuration" >
            <Tabs defaultActiveKey="1" size={"large"} style={{ marginBottom: 32 }} onChange={(e) => { setActiveTab(e) }}>
                <TabPane tab="Waiver Configuration" key="1">
                    {activeTab === "1" && <WaiverConfig />}
                </TabPane>
                <TabPane tab="Waiver Configuration List" key="2">
                    {activeTab === "2" && <WaiverConfigList />}
                </TabPane>

            </Tabs>
        </Card>
    )
}
