import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { createDesignation, deleteDepartmentTeacherAssign, deleteDesignation, doMultipleStaffRegistration, fetchdepartmentTeacherAssignList, fetchdesignationList, fetchstaffBasicInfoList, saveDepartmentTeacherAssign, saveSingleStaffRegistration, updateDesignation, updateSingleStaffBasicInfo, updateSingleStaffPhoto } from '../../../http/staff/staff';

export interface Staff {

	designationList: any;
	setdesignationList: Action<Staff, any>;
	fetchdesignationList: Thunk<Staff>
	createDesignation: Thunk<Staff, any>
	updateDesignation: Thunk<Staff, any>
	deleteDesignation: Thunk<Staff, any>

	saveSingleStaffRegistration: Thunk<Staff, any>
	doMultipleStaffRegistration: Thunk<Staff, any>

	staffBasicInfoList: any;
	setstaffBasicInfoList: Action<Staff, any>;
	fetchstaffBasicInfoList: Thunk<Staff>
	updateSingleStaffPhoto: Thunk<Staff, any>
	updateSingleStaffBasicInfo: Thunk<Staff, any>

	departmentTeacherAssignList: any;
	setdepartmentTeacherAssignList: Action<Staff, any>;
	fetchdepartmentTeacherAssignList: Thunk<Staff>
	saveDepartmentTeacherAssign: Thunk<Staff, any>
	deleteDepartmentTeacherAssign: Thunk<Staff, any>

}

export const staffStore: Staff = {

	designationList: null,
	fetchdesignationList: thunk(async (actions) => {
		const response = await fetchdesignationList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setdesignationList(body?.item);
		} else {

		}
	}),

	setdesignationList: action((state, payload) => {
		state.designationList = payload;
	}),

	createDesignation: thunk(async (actions, payload) => {
		const response = await createDesignation(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateDesignation: thunk(async (actions, payload) => {
		const response = await updateDesignation(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteDesignation: thunk(async (actions, payload) => {
		const response = await deleteDesignation(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	saveSingleStaffRegistration: thunk(async (actions, payload) => {
		const response = await saveSingleStaffRegistration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	doMultipleStaffRegistration: thunk(async (actions, payload) => {
		const response = await doMultipleStaffRegistration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdesignationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	updateSingleStaffBasicInfo: thunk(async (actions, payload) => {
		const response = await updateSingleStaffBasicInfo(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstaffBasicInfoList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateSingleStaffPhoto: thunk(async (actions, payload) => {

		console.log(payload)

		var data = new FormData()
		data.append('file', payload.file)


		const response = await updateSingleStaffPhoto(data, payload.staffId);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstaffBasicInfoList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	staffBasicInfoList: null,
	fetchstaffBasicInfoList: thunk(async (actions) => {
		const response = await fetchstaffBasicInfoList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setstaffBasicInfoList(body?.item);
		} else {

		}
	}),

	setstaffBasicInfoList: action((state, payload) => {
		state.staffBasicInfoList = payload;
	}),

	departmentTeacherAssignList: [],
	fetchdepartmentTeacherAssignList: thunk(async (actions) => {
		const response = await fetchdepartmentTeacherAssignList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setdepartmentTeacherAssignList(body?.item);
			} else {
				notification.error({ message: "No data found" })
				actions.setdepartmentTeacherAssignList([])
			}
		} else {

		}
	}),

	setdepartmentTeacherAssignList: action((state, payload) => {
		state.departmentTeacherAssignList = payload;
	}),

	saveDepartmentTeacherAssign: thunk(async (actions, payload) => {
		const response = await saveDepartmentTeacherAssign(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdepartmentTeacherAssignList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	deleteDepartmentTeacherAssign: thunk(async (actions, payload) => {
		const response = await deleteDepartmentTeacherAssign(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchdepartmentTeacherAssignList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


}
