import { ContainerOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import TableView from '../../../../contents/AntTableResponsive';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';


const { Option } = Select;

export default function VoucherDelete(props) {

    const [form] = Form.useForm();

    const viewFeeInvoice = useStoreState((state) => state.saccount.viewFeeInvoice);
    const fetchviewFeeInvoice = useStoreActions((state) => state.saccount.fetchviewFeeInvoice);
    const deleteFeeInvoice = useStoreActions((state) => state.saccount.deleteFeeInvoice);



    const formSubmit = (value) => {
        fetchviewFeeInvoice(value.invoiceId);
    }
    const columns = [
        {
            title: 'Feehead Name',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payable',
            dataIndex: 'payable',
            key: 'payable',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Due',
            dataIndex: 'due',
            key: 'due',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ]




    return (
        <Card title="Feeinvoice Delete">

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>

                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>  </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="invoiceId"
                            label="Invoice Id"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please input voucher id" },
                            ]}
                        >
                            <Input placeholder='Invoice Id' />

                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>

            {viewFeeInvoice !== null &&
                <>
                    <div style={{ marginTop: 20 }}>
                        <Row gutter={8}>
                            <Col xs={24} sm={24} md={24} lg={5} xl={5}></Col>
                            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                <table style={{ width: "100%" }} id='vdel'>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Student Id </td>
                                        <td>{viewFeeInvoice?.customStudentId}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Student Name </td>
                                        <td>{viewFeeInvoice?.studentName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Class Department </td>
                                        <td>{viewFeeInvoice?.classDepartment}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Created Date</td>
                                        <td>{viewFeeInvoice?.createDate}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Payment Date </td>
                                        <td>{viewFeeInvoice?.paymentDate}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Total Payable </td>
                                        <td>{viewFeeInvoice?.totalPayable}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Total Due </td>
                                        <td>{viewFeeInvoice?.totalDue}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>Total Paid </td>
                                        <td>{viewFeeInvoice?.totalPaid}</td>
                                    </tr>
                                </table>
                                <br />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={2} xl={2}></Col>
                            {viewFeeInvoice?.moneyReceiptSubList?.length > 0 &&
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: viewFeeInvoice?.moneyReceiptSubList,
                                            pagination: false,
                                            bordered: true,
                                            // rowKey: "feeHeadId",
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                </Col>
                            }
                        </Row>
                    </div>
                    <Space size={'middle'} style={{ float: "right", marginTop: 10 }}>
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteFeeInvoice(viewFeeInvoice?.masterId)}
                        >
                            <Button danger> Delete</Button>
                        </Popconfirm>
                    </Space>
                </>
            }

        </Card>
    )
}
