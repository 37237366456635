import { Button, Card, Col, DatePicker, Form, Row, Space } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectLedgerDFPSMulti } from "../../../select/SelectLedgerDFPSMulti";
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Ledger Name", dataKey: "ledgerName" },
        { title: "Debit", dataKey: "debit" },
        { title: "Credit", dataKey: "credit" },
    ];
};

var getColumnsGrand = function () {
    return [
        { title: "Grand Total", dataKey: "grandTotal" },
        { title: "", dataKey: "totalDebit" },
        { title: "", dataKey: "totalCredit" },
    ];
};
let fd = '';
let td = '';
export default function AccountsBookCash(props) {
    const [cashdeb, setcashDeb] = useState<any>();

    const onchangecashdeb = (value) => {
        setcashDeb(value)
    }

    const accountBookCash = useStoreState((state) => state.ledger.accountBookCash);
    const check = useStoreState((state) => state.ledger.check);
    const fetchaccountBookCash = useStoreActions((state) => state.ledger.fetchaccountBookCash);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        //console.log(value)
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
            cashLedgerIds: value.cashLedgerIds.join()
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchaccountBookCash(postData);
    };

    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        let datas = accountBookCash;
        if (accountBookCash?.details?.length > 0) {
            option = option + "<thead><th>Ledger Name</th><th style='text-align: center'>Debit </th><th style='text-align: center'>Credit</th></tr></thead>";
            option = option + "<tbody>";

            var trialBalanceResponses = datas.details;

            for (var i = 0; i < trialBalanceResponses.length; i++) {

                option = option + "<tr><th colspan='3'>" + trialBalanceResponses[i].ledgerName + "</th></tr>";

                var details = trialBalanceResponses[i].insideDetails;

                for (var j = 0; j < details.length; j++) {
                    option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + details[j].ledgerName + "</td><td style='text-align: right'>" + details[j].debitAmount + "</td><td style='text-align: right'>" + details[j].creditAmount + "</td></tr>";

                }

                // option = option + "<tr><th style='text-align: right'>Total</th><th style='text-align: right'>" + trialBalanceResponses[i].totalDebit + "</th><th style='text-align: right'>" + trialBalanceResponses[i].totalCredit + "</th></tr>";

            }

            option = option + "</tbody>";

            option = option + "<tfoot>";
            option = option + "<tr><th style='text-align: right'>Grand Total</th><th style='text-align: right'>" + datas.totalDebit + "</th><th style='text-align: right'>" + datas.totalCredit + "</th></tr>";
            option = option + "</tfoot>";
            table.append(option);
        }
    }, [check])

    function exportPdf() {
        let modifyList = accountBookCash?.details.map(function (element) {
            let final = element.insideDetails.map(function (item, indx) {
                return {
                    ledgerName: element?.ledgerName,
                   // ledgerName: item?.ledgerName,
                    debit: item?.debitAmount,
                    credit: item?.creditAmount,
                    indx: indx,
                    total: "Total",
                }
            })
            return final
        })

        let grandValue = [
            {
                totalDebit: accountBookCash?.totalDebit,
                totalCredit: accountBookCash?.totalCredit,
                grandTotal: "Grand Total",
            }
        ]

        var doc = new jsPDF("p", "mm", "a4");

        var details = `Cash book from ${fd} to ${td}`;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };
        for (let i = 0; i < modifyList.length; i++) {

            let first = doc.autoTable.previous;
            doc.autoTable(getColumns(), modifyList[i], {
                startY: i == 0 ? 45 : first.finalY + 0,
                showHeader: i == 0 ? "firstPage" : "never",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    ledgerName: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    debit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    credit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },
                drawRow: function (row, data) {
                    if (row.index === 0) {
                        doc.setFontSize(10);
                        doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                        doc.autoTableText(" " + modifyList[i][0].ledgerName, data.settings.margin.left, (row.y + row.height / 2) - 1,
                            {
                                halign: "left",
                                valign: "top",
                                fontSize: 10
                            }
                        );
                        data.cursor.y += 7;
                    }
                },
                // addPageContent: pageContent,
            });

            // doc.text("hi", 20, doc.autoTable.previous.finalY + 5);
        }
        doc.autoTable(getColumnsGrand(), grandValue, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                grandTotal: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 75
                },
                totalDebit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                totalCredit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },

            // addPageContent: pageContent,
        });
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    return (
        <>

            <Form
                layout="vertical"
                onFinish={reportFormSubmit}
                id="basic-info"
                form={reportForm}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="fromDate"
                            label="From Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter from date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="toDate"
                            label="To Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter to date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                        <Form.Item
                            name="cashLedgerIds"
                            label="Ledger:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select ledger" },
                            ]}
                        >
                            <SelectLedgerDFPSMulti onChange={(val) => onchangecashdeb(val)} selected={cashdeb} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                </Row>
            </Form>
            {accountBookCash?.details?.length > 0 &&
                <>
                    <div className="table-responsive">
                        <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                        </table>
                    </div>
                    <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                </>
            }

        </>
    )
}
