import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Table, Tag} from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';



export default function SessionYear(){
    const createSessionYear = useStoreActions((state) => state.student.createSessionYear);
    const updateSessionYear = useStoreActions((state) => state.student.updateSessionYear);
    const deleteSessionYear = useStoreActions((state) => state.student.deleteSessionYear);
    const fetchSessionYearList = useStoreActions((state) => state.student.fetchSessionYearList);
    const sessionYearList = useStoreState((state) => state.student.sessionYearList);
    const [sessionYearForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    ///modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStatus, setselectedStatus] = useState<any>();
    const [updateSessionYearId, setupdateSessionYearId] = useState<any>();

    useEffect(() => {
        fetchSessionYearList();
        
    },[])

    const createSessionYearForm = (value) => {
        
        let postData = {
            name: value.sessionYearName,
            serial: value.serialNumber,
            status: 1
        }
        createSessionYear(postData);
        sessionYearForm.resetFields();
    }

    /////////////

    const updateSessionYearForm = (value) => {
        let updatePostData = {
            id:updateSessionYearId,
            name: value.name,
            serial: value.serial,
            status: value.status == true ? 1 : 0
        }
        updateSessionYear(updatePostData);
        setIsModalVisible(false);
        updateForm.resetFields();
    }

    

    const success = () => {
        message.success('This is a success message');
      };
      
      const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Year',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <>
                   <span>{ record.status == 1 ? 'Enable' : 'Disable'}</span>
                </>
            )
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setselectedStatus(record.status);
                            setupdateSessionYearId(record.id);
                            updateForm.setFieldsValue({
                                serial: record.serial,
                                name: record.name,
                                status: record.status,
                            });
                        }} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteSessionYear(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
      ];
    return(
        <>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={createSessionYearForm}
                        form={sessionYearForm}
                    >
                        <Row>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                        name="sessionYearName"
                                        label="Semester Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write session year" },
                                        ]}
                                    >
                                    <Input placeholder="Enter semester Year" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                        name="serialNumber"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} sessionYearName="p-datatable-responsive-demo" /> */}
                        <Table bordered={true} dataSource={sessionYearList} columns={columns} className="p-datatable-responsive-demo" />
                    </div>
                </Col>
            </Row>
            <Modal
                title="Session Year Edit"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateSessionYearForm}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                        name="name"
                                        label="Session Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write session Year name" },
                                        ]}
                                    >
                                    <Input placeholder="Enter session Year name" value={updateSessionYearId} />
                                </Form.Item>
                            </Col> 
                            <Col span={24}>
                                <Form.Item
                                        name="serial"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status:"
                                        valuePropName="checked"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please input serial" },
                                        ]}
                                    >
                                    <Checkbox>Enable</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            </Modal>
        </>
    )
    
}