import { ContainerOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import { SelectLedgerCashEqui } from '../../select/SelectLedgerCashEqui';
import moment from 'moment';
import { SelectSemesterYear } from '../../select/SelectSemesterYear';


export default function ColllectSingle(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const singlePaySlip = useStoreState((state) => state.payslip.singlePaySlip);
    const fetchSinglePaySlip = useStoreActions((state) => state.payslip.fetchSinglePaySlip);
    const collectPayslip = useStoreActions((state) => state.payslip.collectPayslip);
    const deletePayslip = useStoreActions((state) => state.payslip.deletePayslip);
    const deletePayslipPaid = useStoreActions((state) => state.payslip.deletePayslipPaid);
    const downloadPayslip = useStoreActions((state) => state.payslip.downloadPayslip);



    // useEffect(() => {
    //     fetchfeeHeadList();
    // }, [])
    const [data, setData] = useState<any>();
    const formSubmit = (value) => {
        setData(value)
        fetchSinglePaySlip(value)
    }

    const updateFomrSubmit = (value) => {
        value.payslipIds = selectedValue.map(item => item.payslipId);
        value.paymentDate = moment(value?.paymentDate).format("YYYY-MM-DD");
        collectPayslip(value);
        setIsModalVisible(false);
        updateForm.resetFields()

        setTimeout(() => {
            fetchSinglePaySlip(data)
        }, 1000);


    }


    const columns = [

        {
            title: 'Payslip Id',
            dataIndex: 'payslipId',
            key: 'payslipId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Status',
            dataIndex: 'payslipStatusString',
            key: 'payslipStatusString',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'payslipId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            data?.payslipStatus === 1 ? deletePayslipPaid(record?.payslipId) : deletePayslip(record?.payslipId);
                            setTimeout(() => {
                                fetchSinglePaySlip(data)
                            }, 1000);
                        }}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                    <Tooltip title="Download">
                        <Button type="primary" onClick={() => downloadPayslip(record?.payslipId)} icon={<DownloadOutlined />} />
                    </Tooltip>

                </Space>
            ),
        },

    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [dep, setDep] = useState<any>();
    const [semy, setsemy] = useState<any>();

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const openModal = () => {
        if (selectedRowKeys.length > 0) {
            setIsModalVisible(true);
        } else {
            notification.error({
                message: 'Error',
                description: 'Please select at least one payslip',
            });
            return;
        }
    }

    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="customStudentId"
                            label="Student Id"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please input student id" },
                            ]}
                        >

                            <Input placeholder="Student Id" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="semesterYearId"
                            label="Semester Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >

                            <SelectSemesterYear selected={semy} onChange={e => { setsemy(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="payslipStatus"
                            label="Status"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select status" },
                            ]}
                        >

                            <Select placeholder="Select Status" allowClear>
                                <Select.Option value={0}>Unpaid</Select.Option>
                                <Select.Option value={1}>Paid</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>

            {singlePaySlip?.length > 0 &&
                <>
                    <div className="datatable-responsive">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: singlePaySlip,
                                pagination: false,
                                bordered: true,
                                rowKey: "payslipId",
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <br />
                    {data?.payslipStatus === 0 && <Button type="primary" icon={<ContainerOutlined />} style={{ float: 'right' }} onClick={() => openModal()}> Collect</Button>}
                </>
            }


            <Modal
                title="Collect"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Form.Item
                        name="paymentDate"
                        label="Payment Date"
                        className="title-Text"
                        initialValue={moment()}
                        rules={[
                            { required: true, message: "Please select date" },
                        ]}
                    >

                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item
                        name="debitLedgerId"
                        label="Ledger:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee head" },
                        ]}
                    >
                        <SelectLedgerCashEqui style={{ width: "100%" }} />
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
