import { DeleteOutlined, EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClassDepartmentMulti } from '../../../select/SelectClassDepartmentMulti';


export default function StaffAssign(props) {


    const staffBasicInfoList = useStoreState((state) => state.staff.staffBasicInfoList);
    const fetchstaffBasicInfoList = useStoreActions((state) => state.staff.fetchstaffBasicInfoList);
    const saveDepartmentTeacherAssign = useStoreActions((state) => state.staff.saveDepartmentTeacherAssign);


    useEffect(() => {
        fetchstaffBasicInfoList();
    }, [])

    const [form] = Form.useForm();

    const formSubmit = (value) => {
        if (selectedRowKeys.length === 0) {
            message.error("Please select at least one staff");
            return
        }
        let data = {
            "classDepartmentIds": value.classDepartmentIds,
            "staffIds": selectedValue?.map(item => item.staffId)
        }

        saveDepartmentTeacherAssign(data);
        form.resetFields();
        setselectedRowKeys([]);
        setselectedValue([]);
    }


    const columns = [

        {
            title: 'Serial',
            dataIndex: 'staffSerial',
            key: 'staffSerial',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designationName',
            key: 'designationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Father's Name ",
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Mother's Name",
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true
        },

    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        < >
            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={7} xl={7}> </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="classDepartmentIds"
                            label="Class Department"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please input serial" },
                            ]}
                        >

                            <SelectClassDepartmentMulti />
                        </Form.Item>
                    </Col>
   
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                </Row>

                <div className="datatable-responsive">
                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: staffBasicInfoList,
                            pagination: false,
                            bordered: true,
                            rowKey: 'staffId',
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                </div>
                <Space size="small" style={{ float: 'right' }} >
                    <Button type="primary" id="searchButton" htmlType="submit" icon={<SettingOutlined />} >
                        Assign
                    </Button>
                </Space>
            </Form>
        </>
    )
}
