import { Button, Card, Col, DatePicker, Form, Row, Space } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Account Ledger", dataKey: "ledgerName" },
        { title: "Balance", dataKey: "balance" },
    ];
};
var getColumnsTotal = function () {
    return [
        { title: "Total", dataKey: "total" },
        { title: "", dataKey: "balance" },
    ];
};
var getColumnsGrand = function () {
    return [
        { title: "", dataKey: "profitLossLabel" },
        { title: "", dataKey: "profitLossValue" },
    ];
};
let fd = '';
let td = '';
export default function BalanceSheet(props) {

    const balanceSheet = useStoreState((state) => state.ledger.balanceSheet);
    const check = useStoreState((state) => state.ledger.check);
    const fetchbalanceSheet = useStoreActions((state) => state.ledger.fetchbalanceSheet);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            trnDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            // toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        // td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchbalanceSheet(postData);
    };

    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        let datas = balanceSheet;
        if (balanceSheet?.balanceSheetResponses?.length > 0) {
            option = option + "<thead><tr><th>Account Ledger</th><th style='text-align: center'>Balance </th></tr></thead>";
            option = option + "<tbody>";

            var balanceSheetResponses = datas.balanceSheetResponses;

            for (var i = 0; i < balanceSheetResponses.length; i++) {

                option = option + "<tr><th colspan='2'>" + balanceSheetResponses[i].categoryName + "</th></tr>";

                var details = balanceSheetResponses[i].balanceSheetDetailsResponses;

                for (var j = 0; j < details.length; j++) {
                    option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + details[j].ledgerName + "</td><td style='text-align: right'>" + details[j].balance + "</td></tr>";

                }

                option = option + "<tr><th style='text-align: right'>Total</th><th style='text-align: right'>" + balanceSheetResponses[i].totalBalance + "</th></tr>";

            }

            option = option + "</tbody>";

            option = option + "<tfoot>";
            option = option + "<tr><th style='text-align: right'>" + datas.profitLossLabel + "</th><th style='text-align: right'>" + datas.profitLossValue + "</th></tr>";
            option = option + "</tfoot>";
            table.append(option);
        }
    }, [check])

    function exportPdf() {
        let modifyList = balanceSheet?.balanceSheetResponses.map(function (element) {
            let final = element.balanceSheetDetailsResponses.map(function (item, indx) {
                return {
                    categoryName: element?.categoryName,
                    totalBalance: element?.totalBalance,
                    balanceSheetDetailsResponses: element?.balanceSheetDetailsResponses,
                    len: element?.balanceSheetDetailsResponses.length,
                    ledgerName: item?.ledgerName,
                    balance: item?.balance,
                    indx: indx,
                    total: "Total",
                }
            })
            return final
        })
        let colList = balanceSheet?.balanceSheetResponses.map(function (element) {
            return [{
                categoryName: element?.categoryName,
                len: element?.balanceSheetDetailsResponses.length,
                total: "Total",
                balance: element?.totalBalance,
            }]
        })
        let grandValue = [
            {
                profitLossLabel: balanceSheet?.profitLossLabel,
                profitLossValue: balanceSheet?.profitLossValue,
            }
        ]

        var doc = new jsPDF("p", "mm", "a4");
        var details = `Balance Sheet of ${fd}`;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        for (let i = 0; i < modifyList.length; i++) {

            let first = doc.autoTable.previous;
            doc.autoTable(getColumns(), modifyList[i], {
                startY: i == 0 ? 45 : first.finalY + 0,
                showHeader: i == 0 ? "firstPage" : "never",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    ledgerName: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    balance: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },
                drawRow: function (row, data) {
                    if (row.index === 0) {
                        doc.setFontSize(10);
                        doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                        doc.autoTableText(" " + modifyList[i][0].categoryName, data.settings.margin.left, (row.y + row.height / 2) - 1,
                            {
                                halign: "left",
                                valign: "top",
                                fontSize: 10
                            }
                        );
                        data.cursor.y += 7;
                    }
                },
                // addPageContent: pageContent,
            });
            doc.autoTable(getColumnsTotal(), colList[i], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    total: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 100
                    },
                    balance: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 81.8
                    },

                },

                addPageContent: pageContent,
            });
            // doc.text("hi", 20, doc.autoTable.previous.finalY + 5);
        }
        doc.autoTable(getColumnsGrand(), grandValue, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                profitLossLabel: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 100
                },
                profitLossValue: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 81.8
                },
            },

            // addPageContent: pageContent,
        });
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    return (
        <>
            <Card title="Balance Sheet" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
 
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {balanceSheet?.balanceSheetResponses?.length > 0 &&
                    <>
                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                        <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                    </>
                }
            </Card>
        </>
    )
}
