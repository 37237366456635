import { post, get, del} from "../http";


export const download = (payload) => get("/general/accounts/jasper/download/voucher?trnIds="+payload);
export const fetchledgerList = () => get("/general/accounts/report/all/ledger/list");
export const fetchcategoryList = () => get("/general/accounts/report/category/list");
export const fetchreceiptledgerlist = () => get("/general/accounts/report/ledger/list/for/receipt");
export const fetchpaymentledgerlist = () => get("/general/accounts/report/ledger/list/for/payment");
export const fetchcashEquivalanceLedgerList = () => get("/general/accounts/report/cash-equivalance/ledger/list");
export const fetchcashDFPSLedgerList = () => get("/general/accounts/report/cash-equivalance/dfps/ledger/list");
export const fetchofpsLedgerList = () => get("/general/accounts/report/account/ofps/ledger/list");
export const fetchallLedgerList = () => get("/general/accounts/report/all/ledger/list");
export const fetchaccountRegularIncomeLedgerList = () => get("/general/accounts/report/account/regular-income/ledger/list");
export const createLedger = (payload) => post(`/general/accounts/ledger/create`, payload);
export const updateLedger = (payload) => post(`/general/accounts/ledger/update?accountCategoryId`, payload);
export const deleteLedger = (payload) => del(`/general/accounts/ledger/delete?ledgerId=${payload}`);
export const doReceiptVoucher = (payload) => post('/general/accounts/transaction/receipt',payload);
export const doPaymentVoucher = (payload) => post('/general/accounts/transaction/payment',payload);
export const doContraVoucher = (payload) => post('/general/accounts/transaction/contra',payload);
export const doJournalVoucher = (payload) => post('/general/accounts/transaction/journal',payload);
///report
export const fetchtrialBalance = (payload) => get("/general/accounts/report/trial-balance?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const fetchcashSummary = (payload) => get("/general/accounts/report/cash-summary?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const fetchincomeStatement = (payload) => get("/general/accounts/report/income-statement?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const fetchjournalStatement = (payload) => get("/general/accounts/report/journal?fromDate="+payload.fromDate+"&toDate="+payload.toDate);
export const fetchbalanceSheet = (payload) => get("/general/accounts/report/balance-sheet?trnDate="+payload.trnDate);
export const fetchaccountBookCash = (payload) => get("/general/accounts/report/cashbook?fromDate="+payload.fromDate+"&toDate="+payload.toDate+"&cashLedgerIds="+payload.cashLedgerIds);
export const fetchledgerBook = (payload) => get("/general/accounts/report/ledgerbook?fromDate="+payload.fromDate+"&toDate="+payload.toDate+"&ledgerId="+payload.ledgerId);
export const fetchfundsFlow = (payload) => get("/general/accounts/report/funds-flow?year="+payload.year);
///report

export const fetchvoucherInfo = (payload) => get("/general/accounts/report/show-voucher/by/voucher-id?voucherId="+payload.voucherId);
export const deletevoucher = (payload) => del("/general/accounts/transaction/delete?trnId="+payload.val);


