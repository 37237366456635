import { Alert, Form, Input, Button, Checkbox, Layout, Row, Col, Tooltip, Spin } from 'antd';
import React from 'react';
import { Redirect } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined, LoadingOutlined, HomeOutlined, MailOutlined, PhoneOutlined, KeyOutlined, FacebookOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import logo from "../../../../src/assets/images/mainlogo.png";
import containerBG from "../../../../src/assets/images/container_bg.png";
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
// const FormItem = Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// interface IProps {
//     logInConnect: (cred: any) => void;
//     authFailed: boolean;
//     authFailedMessage: string | undefined;
// }

// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 8 },
// };

// const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
// };

// const { Option } = Select;



// function onFinish() {

// }

export function Login() {
    // const authFailed = false;
    // const authFailedMessage = '';
    const authenticate = useStoreActions(state => state.auth.authenticate);
    const setBusy = useStoreActions(state => state.auth.setBusy);

    const error = useStoreState(state => state.auth.error);
    const isBusy = useStoreState(state => state.auth.busy);
    const user = useStoreState(state => state.auth.user);
    let rememberChoice: boolean = true;

    if (user) {
        return <Redirect to="/" />
    }

    const onRememberChange = (event) => {
        rememberChoice = event.target.checked;
    }

    const handleSubmit = async (payload) => {
        setBusy(true);
        payload.remember = rememberChoice;
        authenticate(payload);
    };

    const style = {
        container: {
            position: 'absolute' as 'absolute',
            background: '#ecf0f1',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };
    const flexClass = 'd-flex align-items-center justify-content-start flex-wrap';
    return (
        // <Layout style={style.container} className="login-form-wrapper">
        <Layout className="login-form-wrapper" >
                <div>
                    <div>
                       
                        <div>


                            <div className="login-form-inner-right h-200" style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                                
                                <div className="login-form">
                                    <div style={{marginTop:'20px',marginBottom:'20px'}}>
                                      
                                        <img src={logo} className="img-fluid" style={{margin: 'auto',display: 'block',width:'135px',height:'110px'}}/>
                                    </div>
                                    <Form name="login" onFinish={handleSubmit}>
                                        <Form.Item name='username' rules={[{ required: true, message: 'Please input your username or email!' }]}>
                                            <Input
                                                name="username"
                                                className="custom-form"
                                                placeholder="Your username"
                                                prefix={<UserOutlined className="site-form-item-icon" />}
                                                suffix={
                                                    <Tooltip title="username">
                                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                    </Tooltip>
                                                }
                                            />
                                        </Form.Item>

                                        <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                                            <Input.Password
                                                name="password"
                                                className="custom-form"
                                                placeholder="Password"
                                                prefix={<KeyOutlined className="site-form-item-icon" />}
                                                suffix={
                                                    <Tooltip title="password">
                                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                    </Tooltip>
                                                }
                                            />
                                        </Form.Item> 

                               
                                        <Row>
                                            <Col md={24} lg={24} xs={24}>
                                                <div className="text-center">
                                                    <Button type="primary" className="submitButton" htmlType="submit" disabled={isBusy}>
                                                        {isBusy ? <Spin indicator={antIcon} /> : 'Login'}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            error ? <Alert style={{ marginTop: '20px' }} message={error} type="error" /> : null
                                        }

<Row>
                                            <Col md={24} lg={24} xs={24} className="mb-20">
                                                <div className="text-center">
                                                    <div className="signUp-wrapper text-center">
                                                        <span> <Link className="forgetPassword" to="/forgot-password">Forgot Password?</Link></span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Form>
                                     <div className="social-wrapper">
                                        <div className="d-flex align-items-center justify-content-start">
                                            <a href="https://www.facebook.com/" target="_blank" className="m-0"><FacebookOutlined /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Layout>
    );
}
