import React from 'react'
import { Card, Tabs } from 'antd';
import Session from './Session.page';
import Class from './Class.page';
import Department from './Department.page';
import SessionYear from './SessionYear.page';
import Shift from './Shift.page';

export default function StudentWrapper(props) {

    const { TabPane } = Tabs;

    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Settings">
                <Tabs defaultActiveKey="1" onChange={(e) => { setActiveTab(e) }}>
                    <TabPane tab="Session" key="1">
                        {activeTab === "1" && <Session />}
                    </TabPane>
                    <TabPane tab="Class" key="2">
                        {activeTab === "2" && <Class />}
                    </TabPane>
                    <TabPane tab="Department" key="3">
                        {activeTab === "3" && <Department />}
                    </TabPane>
                    <TabPane tab="Semester Year" key="4">
                        {activeTab === "4" && <SessionYear />}
                    </TabPane>
                    <TabPane tab="Shift" key="5">
                        {activeTab === "5" && <Shift />}
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}