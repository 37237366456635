import { Button, Card, Col, DatePicker, Form, notification, Row, Space, Tooltip } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectSession } from '../../../select/SelectSession';
import { SelectClass } from '../../../select/SelectClass';
import { SelectDepartmentByClass } from '../../../select/SelectDepartmentByClass';
import { SelectSemesterYearByClass } from '../../../select/SelectSemesterYearByClass';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



export default function StudentFeeheadDueSummary(props) {

    const showStudentFeeHeadDue = useStoreState((state) => state.payslip.showStudentFeeHeadDue);
    const feeHeadNames = useStoreState((state) => state.payslip.feeHeadNames);
    const fetchshowStudentFeeHeadDue = useStoreActions((state) => state.payslip.fetchshowStudentFeeHeadDue);
    const fetchdepartmentListByClassId = useStoreActions((state) => state.common.fetchdepartmentListByClassId);
    const fetchsessionYearListByClassId = useStoreActions((state) => state.common.fetchsessionYearListByClassId);
    const [selectedClass, setSelectedClass] = useState<any>();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const [selectedDepartment, setselectedDepartment] = useState<any>();
    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {


        value.fromDate = moment(value?.fromDate).format("YYYY-MM-DD");
        value.toDate = moment(value?.toDate).format("YYYY-MM-DD");
        fetchshowStudentFeeHeadDue(value);
    };

console.log(showStudentFeeHeadDue)
const [columns, setcolumns] = useState<any>([]);
const [excelx, setexcelx] = useState<any>([]);

    useEffect(() => {

        if (showStudentFeeHeadDue?.studentList?.length > 0) {
            setcolumns([{
                title: 'Student Id',
                dataIndex: 'customStudentId',
                key: 'customStudentId',
                showOnResponse: true,
                showOnDesktop: true,
            },

            {
                title: 'Student Name',
                dataIndex: 'studentName',
                key: 'studentName',
                showOnResponse: true,
                showOnDesktop: true,
            },
            {
                title: 'Roll',
                dataIndex: 'studentRoll',
                key: 'studentRoll',
                showOnResponse: true,
                showOnDesktop: true,
            },            
            {
                title: 'Total Payable',
                dataIndex: 'totalPayable',
                key: 'totalPayable',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName1 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName1,
                dataIndex: 'feeHeadPayable1',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName2 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName2,
                dataIndex: 'feeHeadPayable2',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName3 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName3,
                dataIndex: 'feeHeadPayable3',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName4 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName4,
                dataIndex: 'feeHeadPayable4',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName5 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName5,
                dataIndex: 'feeHeadPayable5',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName6 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName6,
                dataIndex: 'feeHeadPayable6',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName7 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName7,
                dataIndex: 'feeHeadPayable7',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName8 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName8,
                dataIndex: 'feeHeadPayable8',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName9 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName9,
                dataIndex: 'feeHeadPayable9',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName10 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName10,
                dataIndex: 'feeHeadPayable10',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName11 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName11,
                dataIndex: 'feeHeadPayable11',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName12 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName12,
                dataIndex: 'feeHeadPayable12',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName13 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName13,
                dataIndex: 'feeHeadPayable13',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName14 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName14,
                dataIndex: 'feeHeadPayable14',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName15 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName15,
                dataIndex: 'feeHeadPayable15',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName16 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName16,
                dataIndex: 'feeHeadPayable16',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName17 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName17,
                dataIndex: 'feeHeadPayable17',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName18 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName18,
                dataIndex: 'feeHeadPayable18',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName19 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName19,
                dataIndex: 'feeHeadPayable19',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName20 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName20,
                dataIndex: 'feeHeadPayable20',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName21 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName21,
                dataIndex: 'feeHeadPayable21',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName22 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName22,
                dataIndex: 'feeHeadPayable22',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName23 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName23,
                dataIndex: 'feeHeadPayable23',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName24 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName24,
                dataIndex: 'feeHeadPayable24',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            showStudentFeeHeadDue?.feeHeadNames?.feeHeadName25 !== "" && {
                title: showStudentFeeHeadDue?.feeHeadNames?.feeHeadName25,
                dataIndex: 'feeHeadPayable25',
                key: 'feeHeadPayable1',
                showOnResponse: true,
                showOnDesktop: true,
            },
            

            ]);
            setexcelx([
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName1 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName1} value="feeHeadPayable1" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName2 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName2} value="feeHeadPayable2" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName3 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName3} value="feeHeadPayable3" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName4 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName4} value="feeHeadPayable4" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName5 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName5} value="feeHeadPayable5" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName6 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName6} value="feeHeadPayable6" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName7 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName7} value="feeHeadPayable7" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName8 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName8} value="feeHeadPayable8" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName9 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName9} value="feeHeadPayable9" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName10 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName10} value="feeHeadPayable10" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName11 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName11} value="feeHeadPayable11" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName12 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName12} value="feeHeadPayable12" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName13 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName13} value="feeHeadPayable13" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName14 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName14} value="feeHeadPayable14" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName15 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName15} value="feeHeadPayable15" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName16 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName16} value="feeHeadPayable16" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName17 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName17} value="feeHeadPayable17" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName18 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName18} value="feeHeadPayable18" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName19 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName19} value="feeHeadPayable19" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName20 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName20} value="feeHeadPayable20" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName21 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName21} value="feeHeadPayable21" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName22 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName22} value="feeHeadPayable22" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName23 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName23} value="feeHeadPayable23" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName24 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName24} value="feeHeadPayable24" />,
                showStudentFeeHeadDue?.feeHeadNames?.feeHeadName25 !== "" &&  <ExcelColumn label={showStudentFeeHeadDue?.feeHeadNames?.feeHeadName25} value="feeHeadPayable25" />,
               
            ]);

        }

    }, [showStudentFeeHeadDue])



    return (
        <>

            <Form
                layout="vertical"
                onFinish={reportFormSubmit}
                id="basic-info"
                form={reportForm}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={1} xl={1}></Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="sessionId"
                            label="Session"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select session" },
                            ]}
                        >

                            <SelectSession />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="classId"
                            label="Class"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select class" },
                            ]}
                        >

                            <SelectClass selected={selectedClass} onChange={(e) => { fetchsessionYearListByClassId(e); fetchdepartmentListByClassId(e); setSelectedClass(e); setselectedDepartment(null); setSelectedSemesterYear(null) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="departmentId"
                            label="Department"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select department" },
                            ]}
                        >
                            <SelectDepartmentByClass selected={selectedDepartment} onChange={(e) => { setselectedDepartment(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="semesterId"
                            label="Semester Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select semester year" },
                            ]}
                        >

                            <SelectSemesterYearByClass selected={selectedSemesterYear} onChange={(e) => { setSelectedSemesterYear(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={0} xl={0}></Col>
                   
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>
            {showStudentFeeHeadDue !== null &&
                <>
                    <div className="datatable-responsive">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: showStudentFeeHeadDue?.studentList,
                                pagination: true,
                                bordered: true,
                                rowKey: "identificationId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>

                        <ExcelFile filename="Student Fee Head Due"  element={<Button type="primary" icon={<FileExcelOutlined />} >Download Excel</Button>}>
                            <ExcelSheet data={showStudentFeeHeadDue?.studentList} name="Data">
                                <ExcelColumn label="Student Id" value="customStudentId" />
                                <ExcelColumn label="Student Name" value="studentName" />
                                <ExcelColumn label="Roll" value="studentRoll" />
                                <ExcelColumn label="Session" value="session" />
                                <ExcelColumn label="Class" value="className" />
                                <ExcelColumn label="Department" value="department" />
                                <ExcelColumn label="Semester" value="semester" />
                                <ExcelColumn label="Total Payable" value="totalPayable" />
                                {excelx.filter(Boolean)}
                            </ExcelSheet>
                        </ExcelFile>

                    </Space>
                </>
            }

        </>
    )
}
