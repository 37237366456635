import { Button, Card, Col, DatePicker, Form, Row, Select, Space } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');

const { Option } = Select;

let fd = '';
let td = '';
var year = (new Date().getFullYear()) * 1;
export default function FundsFlow(props) {

    const fundsFlow = useStoreState((state) => state.ledger.fundsFlow);
    const check = useStoreState((state) => state.ledger.check);
    const fetchfundsFlow = useStoreActions((state) => state.ledger.fetchfundsFlow);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            year: value.year,
            // toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = value.year;
        // td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchfundsFlow(postData);
    };

    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        let datas = fundsFlow;
        if (fundsFlow?.fundFlows?.length > 0) {
            var fundFlows = datas.fundFlows;
            option = option + "<tbody>";
            option = option + "<tr><th rowspan='2'>Particulars</th><th colspan='2'>Working Capital</th><th rowspan='2'>Funds Flow</th></tr>";
            option = option + "<tr><th>Opening</th><th>Closing</th></tr>";

            for (var i = 0; i < fundFlows.length; i++) {

                option = option + "<tr><td>" + fundFlows[i].monthName + "</td><td>" + fundFlows[i].openingBalance + "</td><td>" + fundFlows[i].closingBalance + "</td><td>" + fundFlows[i].fundsflow + "</td></tr>";

            }

            option = option + "<tr><th>Grand Total</th><th>" + datas.totalOpeningBalance + "</th><th>" + datas.totalClosingBalance + "</th><th>" + datas.totalFundsFlow + "</th></tr>";
            option = option + "<tbody>";
            table.append(option);
        }
    }, [check])

    function exportPdf() {
        var doc = new jsPDF("p", "mm", "a4");
        var details = `Funds Flow of ${fd}`;
        pdfDataL(doc, "")

        doc.text(details, 105, 40, 'center');
        let first = doc.autoTable.previous;
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };
        doc.autoTable([
            { title: "Particulars", dataKey: "monthName" },
            { title: "Opening", dataKey: "openingBalance" },
            { title: "Closing", dataKey: "closingBalance" },
            { title: "Funds Flow", dataKey: "fundsflow" },
        ], fundsFlow.fundFlows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            styles: {
                fontSize: 8,
                columnWidth: 45.45
            },


            addPageContent: pageContent,
        });
        if (fundsFlow?.fundFlows.length > 0) {
            doc.autoTable([
                { title: "", dataKey: "b1" },
                { title: "", dataKey: "b2" },
                { title: "", dataKey: "b3" },
                { title: "", dataKey: "b4" },
            ], [
                {
                    b1: "Grand Total",
                    b2: fundsFlow.totalOpeningBalance,
                    b3: fundsFlow.totalClosingBalance,
                    b4: fundsFlow.totalFundsFlow,
                }
            ], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                styles: {
                    fontSize: 8,
                    columnWidth: 45.45,
                    fontStyle: 'bold'
                },
                // addPageContent: pageContent,
            });

        }
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    return (
        <>
            <Card title="Funds Flow" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="year"
                                label="Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select year" },
                                ]}
                            >

                                <Select placeholder="Select Year" allowClear>
                                    <Option value={year - 1}>{year - 1}</Option>
                                    <Option value={year}>{year}</Option>
                                    <Option value={year + 1}>{year + 1}</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {fundsFlow?.fundFlows?.length > 0 &&
                    <>
                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                        <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                    </>
                }
            </Card>
        </>
    )
}
