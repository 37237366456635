import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, notification, Row, Select, Space } from 'antd';
import { DownloadOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";

const SMSHelper = require('smshelper')
const { TextArea } = Input;

export default function SmsSendStudent() {

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot = useStoreActions((state) => state.student.fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot);
    const sendSmsToClassStudent = useStoreActions((state) => state.common.sendSmsToClassStudent);
    const fetchShiftList = useStoreActions((state) => state.student.fetchShiftList);
    const studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot = useStoreState((state) => state.student.studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot);
    const [selectedClassDepartment, setSelectedClassDepartment] = useState<any>();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    useEffect(() => {
        fetchSessionList();
        fetchClassConfigList();
        fetchShiftList();
    }, []);

    const searchStudentList = (value) => {
        let payload = {
            classDeptConfId: value.classDepartment,
            sessionId: value.sessionYearName,
            semesterYear: value.semesterYear,
            hasGone: 'no',
        };

        fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot(payload);
        // setCurrentPayload(payload);
    }

    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        setSelectedSemesterYear(null)
        getSemeterYear(value);
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Mobile',
            dataIndex: 'studentMobile',
            key: 'studentMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Father Name',
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mother Name',
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Religion',
            dataIndex: 'religion',
            key: 'religion',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            showOnResponse: true,
            showOnDesktop: true,
        }

    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [form] = Form.useForm();

    const formSubmit = (value) => {
        let payload = {
            message: value.message,
            identificationIds: selectedValue.map((item) => item.identificationId),
        }
        sendSmsToClassStudent(payload);
        setIsModalVisible(false);
        setMessage('');
        setselectedRowKeys([]);
        setselectedValue([]);
    }


    const openModal = () => {

        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }

        setIsModalVisible(true)
    }
    const [message, setMessage] = useState<any>('');
    return (
        <>
            <Card title="SMS">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 14, offset: 4 }} xl={{ span: 14, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    <Form.Item
                                        name="sessionYearName"
                                        label="Session Year"

                                        rules={[
                                            { required: true, message: "Please write session year" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select session"
                                            className="sessionYearName"
                                        >
                                            {sessionList ? (
                                                sessionList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Session</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    <Form.Item
                                        name="classDepartment"
                                        label="Class Department"

                                        rules={[
                                            { required: true, message: "Select class department" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Class Department"
                                            className="classDepartment"
                                            onChange={(e) => { getSemesterYear(e); }}
                                        >
                                            {classConfigList ? (
                                                classConfigList.map((type, idx) => (
                                                    <Option key={type.configId} value={type.configId}>
                                                        {type.configName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    <Form.Item
                                        name="semesterYear"
                                        label="Semester Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester year" },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select semester year"
                                            optionFilterProp="children"
                                            className="semesterYear"
                                            onChange={(e) => { setSelectedSemesterYear(e); }}
                                        >
                                            {semesterYearByDepartmentId ? (
                                                semesterYearByDepartmentId.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                                    <Space size="small" >
                                        <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <div >
                    <Row className="m-t-mo-30">
                        <Col span="24">
                            {studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot !== null && studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot.length > 0 &&
                                <>
                                    <Table
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "studentId",
                                            rowSelection: rowSelection,
                                        }}
                                        mobileBreakPoint={768}

                                    />
                                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                                        <Button type="primary" onClick={openModal} icon={<SendOutlined />} >Send SMS</Button>
                                    </Space>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
            </Card>
            <Modal
                title="SMS"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Send"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="update"
                    form={form}

                >

                    <Form.Item
                        name="message"
                        label="Message:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please write your message" },
                        ]}
                    >
                        <TextArea placeholder="Enter Message" style={{ width: "100%", height: 100 }} value={message} onChange={e => setMessage(e.target.value)} />

                    </Form.Item>
                </Form>
                <div style={{ marginBottom: -15 }}>
                    {message.length > 0 &&
                        <>
                            SMS Count: {SMSHelper.parts(message)}
                        </>
                    }
                </div>

            </Modal>
        </>
    )
}