import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import { SelectLedgerRegularIncome } from '../../../select/SelectLedgerRegularIncome';



export default function FeeFineLedger(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const feeFineLedgerConfigurationList = useStoreState((state) => state.saccount.feeFineLedgerConfigurationList);
    const fetchfeeFineLedgerConfigurationList = useStoreActions((state) => state.saccount.fetchfeeFineLedgerConfigurationList);
    const savefeeFineLedgerConfiguration = useStoreActions((state) => state.saccount.savefeeFineLedgerConfiguration);
    const deletefeeFineLedgerConfiguration = useStoreActions((state) => state.saccount.deletefeeFineLedgerConfiguration);

    useEffect(() => {
        fetchfeeFineLedgerConfigurationList();
    }, [])

    const formSubmit = (value) => {
        savefeeFineLedgerConfiguration(value);
    }



    const [id, setId] = useState<any>();

    const columns = [

        {
            title: 'Fee Head Name',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Ledger Name',
            dataIndex: 'ledgerName',
            key: 'ledgerName',
            showOnResponse: true,
            showOnDesktop: true
        },

        {
            title: 'Action',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deletefeeFineLedgerConfiguration(record?.configId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];


    return (
        <>
            <Card title="Fee Fine Ledger List" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="feeHeadId"
                                label="Fee Head "
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fee head" },
                                ]}
                            >

                                <SelectFeeHead />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="ledgerId"
                                label="Account Ledger"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select account ledger" },
                                ]}
                            >

                                <SelectLedgerRegularIncome />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>

                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: feeFineLedgerConfigurationList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>

            </Card>
        </>
    )
}
