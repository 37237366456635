import { Button, Card, Col, DatePicker, Form, Row, Space, Table } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectLedgerAll } from "../../../select/SelectLedgerAll";
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf, ldatepdf, pdfGenerate } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');


let fd = '';
let td = '';
export default function AccountsBookLedger(props) {
    const [cashdeb, setcashDeb] = useState<any>();

    const onchangecashdeb = (value) => {
        setcashDeb(value)
    }

    const ledgerBook = useStoreState((state) => state.ledger.ledgerBook);
    const check = useStoreState((state) => state.ledger.check);
    const fetchledgerBook = useStoreActions((state) => state.ledger.fetchledgerBook);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        console.log(value)
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD"),
            ledgerId: cashdeb
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchledgerBook(postData);
    };

    const columns = [

        {
            title: 'Tran Date',
            dataIndex: 'trnDate',
            key: 'trnDate',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Ledger Name',
            dataIndex: 'ledgerName',
            key: 'ledgerName',
        },
        {
            title: 'Voucher Type',
            dataIndex: 'voucherType',
            key: 'voucherType',
        },
        {
            title: 'Voucher Id',
            dataIndex: 'voucherId',
            key: 'voucherId',
        },
        {
            title: 'Debit Amount',
            dataIndex: 'drAmount',
            key: 'drAmount',
        },
        {
            title: 'Credit Amount',
            dataIndex: 'crAmount',
            key: 'crAmount',
        },
    ];

    function exportPdf() {

        var details = `Ledger book from ${fd} to ${td}`;

        var doc = new jsPDF("l", "mm", "a4");


        pdfDataL(doc, details);

        var col = ["Tran Date", "Type", "Ledger", "Voucher Type", "Voucher Id", "Debit Amount", "Credit Amount"];

        var rows:any = [];

        var temp:any = []


        ledgerBook.forEach(element => {
             temp = [element?.trnDate, element.type, element.ledgerName,
            element.voucherType, element.voucherId, element.drAmount, element.crAmount,
            ];
            rows.push(temp);
        });

        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        pdfGenerate(doc, col, rows, pageContent)

        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }

        doc.save(details + ".pdf");

    }

    return (
        <>

            <Form
                layout="vertical"
                onFinish={reportFormSubmit}
                id="basic-info"
                form={reportForm}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="fromDate"
                            label="From Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter from date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="toDate"
                            label="To Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter to date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>

                        <Form.Item
                            name="ledgerId"
                            label="Ledger:"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select ledger" },
                            ]}
                        >
                            <SelectLedgerAll onChange={(val) => onchangecashdeb(val)} selected={cashdeb} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}> </Col>
                </Row>
            </Form>
            {ledgerBook?.length > 0 &&
                <>
                    <Table bordered={true} dataSource={ledgerBook} columns={columns} />
                    <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                </>
            }

        </>
    )
}
