import { Layout, Menu, Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "./store/hooks/easyPeasy";
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { ROUTES } from "./contents/routes";
import TopNavBar from "./topNavbar";
import { Sidebar } from "./layouts/sidebar/Sidebar";
import { Link } from "react-router-dom";

import Dashboard from './container/pages/dashboard/Dashboard.page';
import Profile from './container/pages/signup/Profile.page';
import LedgerList from "./container/pages/GeneralAccounts/ledger/LedgerList";
import Receive from "./container/pages/GeneralAccounts/transaction/Receive";
import Payment from "./container/pages/GeneralAccounts/transaction/Payment";
import Contra from "./container/pages/GeneralAccounts/transaction/Contra";
import Journal from "./container/pages/GeneralAccounts/transaction/Journal";

import Users from "./container/pages/user/Users";
import Voucher from "./container/pages/GeneralAccounts/delete/Voucher";

import TrialBalance from "./container/pages/GeneralAccounts/report/TrialBalance";
import BalanceSheet from "./container/pages/GeneralAccounts/report/BalanceSheet";
import IncomeStatement from "./container/pages/GeneralAccounts/report/IncomeStatement";
import JournalR from "./container/pages/GeneralAccounts/report/Journal";
import FundsFlow from "./container/pages/GeneralAccounts/report/FundsFlow";
import CashSummary from "./container/pages/GeneralAccounts/report/CashSummary";
import AccountsBookTab from "./container/pages/GeneralAccounts/report/AccountsBookTab";

import FeeHead from "./container/pages/StudentAccounts/settings/FeeHead.page";
import FeeWaiver from "./container/pages/StudentAccounts/settings/FeeWaiver.page";
import FeeHeadLedger from "./container/pages/StudentAccounts/configuration/FeeHeadLedger.page";
import FeeFineLedger from "./container/pages/StudentAccounts/configuration/FeeFineLedger.page";
import FeeAmountConfig from "./container/pages/StudentAccounts/configuration/FeeAmountConfig.page";
import FeeWaiverConfig from "./container/pages/StudentAccounts/configuration/FeeWaiverConfig.page";
import FeeHeadDelete from "./container/pages/StudentAccounts/configuration/FeeHeadDelete.page";
import FeesCollection from "./container/pages/StudentAccounts/feescollection/FeesCollection.page";
import HeadCollectionWarpper from "./container/pages/StudentAccounts/report/HeadCollectionWarpper";
import ClassDepartmentPaidReport from "./container/pages/StudentAccounts/report/ClassDepartmentPaidReport";
import SemesterYearPaidReport from "./container/pages/StudentAccounts/report/SemesterYearPaidReport";
import LedgerByPaidReport from "./container/pages/StudentAccounts/report/LedgerByPaidReport";
import VoucherDelete from "./container/pages/StudentAccounts/delete/VoucherDelete";
import OFPS from "./container/pages/StudentAccounts/configuration/OFPS";
import SpgStatement from "./container/pages/StudentAccounts/report/SpgStatement";

import StudentWrapper from "./container/pages/Students/settings/StudentWrapper.page";
import CalssConfiguration from "./container/pages/Students/configuration/CalssConfiguration.page";
import CalssSemesterConfiguration from "./container/pages/Students/configuration/CalssSemesterConfiguration.page";
import MultipleRegistrationWrapper from "./container/pages/Students/registration/MultipleRegistrationWrapper";
import ClassConfigUpdate from "./container/pages/Students/registration/ClassConfigUpdate";
import SessionYearUpdate from "./container/pages/Students/registration/SessionYearUpdate";
import ShiftUpdate from "./container/pages/Students/registration/ShiftUpdate"; //AddressInfoUpdate
import AddressInfoUpdate from "./container/pages/Students/registration/AddressInfoUpdate";
import MultipleRegistrationExeclWrapper from "./container/pages/Students/registration/MultipleRegistrationExeclWrapper";
import ClassDepartmentSession from "./container/pages/Students/report/ClassDepartmentSession";
import ClassDepartmentSemester from "./container/pages/Students/report/ClassDepartmentSemester";
import ClassTypeUpdate from "./container/pages/Students/registration/ClassTypeUpdate";

import StaffSettings from "./container/pages/Staff/settings/StaffSettings.page";
import SingleRegistration from "./container/pages/Staff/registration/SingleRegistration.page";
import MultipleRegistrationStaff from "./container/pages/Staff/registration/MultipleRegistration.page";
import StaffUpdate from "./container/pages/Staff/update/StaffUpdate.page";
import BasicUpdate from "./container/pages/Students/registration/BasicUpdate";
import OtherInfoUpdate from "./container/pages/Students/registration/OtherInfoUpdate";
import StudentMigration from "./container/pages/Students/registration/StudentMigration";
import StaffAssignWrapper from "./container/pages/Staff/assign/StaffAssignWrapper.page";

import CreatePaySlip from "./container/pages/PaySlip/CreatePaySlip.page";
import CollectPaySlip from "./container/pages/PaySlip/CollectPaySlip.page";
import PayslipTemplate from "./container/pages/PaySlip/PayslipTemplate.page";

import SmsSendStudent from "./container/pages/Sms/send/SmsSendStudent";
import SmsSendStaff from "./container/pages/Sms/send/SmsSendStaff";
import PurchaseSms from "./container/pages/Sms/send/PurchaseSms";
import InstituteInfoUpdate from "./container/pages/Common/InstituteInfoUpdate";
import InstituteList from "./container/pages/CustomerSupport/InstituteList";
import GoToInstitute from "./container/pages/CustomerSupport/GoToInstitute";
import OfpsProblemView from "./container/pages/CustomerSupport/OFPSProblem/OfpsProblemView";
import { Footer } from "antd/lib/layout/layout";
import logo from "../assets/images/educampuslogo.png";
import InstiuteStudentCount from "./container/pages/CustomerSupport/InstiuteStudentCount";
import PayableBill from "./container/pages/Bill/PayableBill";
import PaidBill from "./container/pages/Bill/PaidBill";
import StudentFeeheadDue from "./container/pages/StudentAccounts/report/StudentFeeheadDue";
import PayslipDateUpdate from "./container/pages/PaySlip/PayslipDateUpdate";

const { Header, Content, Sider } = Layout;
// Application
export default function Container() {
  const user = useStoreState((state) => state.auth.user);
  const fetchInstituteInfo = useStoreActions((state) => state.auth.fetchInstituteInfo);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed)
  };

  var isMobile = false; //initiate as false
  if (
    /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }

  const onCollapse = (collapsed) => {
    //console.log("hello")
    //console.log(collapsed);
    setCollapsed(collapsed);
  };

  useEffect(() => {
    fetchInstituteInfo();
  }, []);

  const d = new Date();
  let year = d.getFullYear();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="mySider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          //console.log(broken);
        }}
        trigger={null}
        collapsedWidth={isMobile ? 0 : 80}
        width="230"
        style={{
          height: "100vh",
          overflow: "auto",
          position: isMobile ? "fixed" : "sticky",
          left: 0,
          top: 0,
          zIndex: 1000,
        }}
      >
        <div className="logo">
          {!collapsed && (
            <>
              <Link to="/">
                <img src={logo} />
              </Link>
            </>
          )}
          {collapsed && (
            <div style={{ marginLeft: 15 }}>
              <AppstoreOutlined />
            </div>
          )}
          {/* {!collapsed && <span style={{color:"white", fontSize:12}}> {user?.instituteName}</span>} */}
        </div>
        {user && (
          <div className="sidebar-wrapper" id="style-4">
            {" "}
            <Sidebar />
          </div>
        )}
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 1}} id="topFixedBar">
          <TopNavBar value={{ collapsed: collapsed, toggle: toggle }} />
        </Header>
        <Content className="p-15" style={{ marginTop: "64px" }}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/profile" component={Profile} />
            //////////***************** Students *************//////////////////////
            <Route exact path={'/' + ROUTES.STUDENT_WRAPPER} component={StudentWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_CLASS_CONFIG} component={CalssConfiguration} />
            <Route exact path={'/' + ROUTES.CLASS_SEMESTER_CONFIG} component={CalssSemesterConfiguration} />
            <Route exact path={'/' + ROUTES.STUDENT_MULTIPLE_REGISTRATION} component={MultipleRegistrationWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_BASIC_UPDATE} component={BasicUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_CLASS_CONFIG_UPDATE} component={ClassConfigUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_CLASS_SESSION_YEAR} component={SessionYearUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_SHIFT_UPDATE} component={ShiftUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_ADDRESS_UPDATE} component={AddressInfoUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_OTHER_INFO_UPDATE} component={OtherInfoUpdate} />
            <Route exact path={'/' + ROUTES.STUDENT_MIGRATION} component={StudentMigration} />
            <Route exact path={'/' + ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL} component={MultipleRegistrationExeclWrapper} />
            <Route exact path={'/' + ROUTES.STUDENT_REPORT_CLASS_DEPARTMENT_SESSION} component={ClassDepartmentSession} />
            <Route exact path={'/' + ROUTES.STUDENT_REPORT_CLASS_DEPARTMENT_SEMESTER} component={ClassDepartmentSemester} />
            <Route exact path={'/' + ROUTES.STUDENT_CLASS_TYPE_UPDATE} component={ClassTypeUpdate} />
            //////////***************** Students *************//////////////////////


            //////////***************** Staff *************//////////////////////
            <Route exact path={'/' + ROUTES.STAFF_SETTINGS} component={StaffSettings} />
            <Route exact path={'/' + ROUTES.STAFF_SETTINGS} component={StaffSettings} />
            <Route exact path={'/' + ROUTES.STAFF_SINGLE_REGISTRATION} component={SingleRegistration} />
            <Route exact path={'/' + ROUTES.STAFF_MULTIPLE_REGISTRATION} component={MultipleRegistrationStaff} />
            <Route exact path={'/' + ROUTES.STAFF_UPDATE} component={StaffUpdate} />
            <Route exact path={'/' + ROUTES.STAFF_ASSIGN} component={StaffAssignWrapper} />
            //////////***************** Staff *************//////////////////////

            //////////***************** General Accounts *************//////////////////////
            <Route exact path={'/' + ROUTES.LEDGER_LIST} component={LedgerList} />
            <Route exact path={'/' + ROUTES.RECEIVE_TRN_TRANSACTION} component={Receive} />
            <Route exact path={'/' + ROUTES.PAYMENT_TRN_TRANSACTION} component={Payment} />
            <Route exact path={'/' + ROUTES.CONTRA_TRN_TRANSACTION} component={Contra} />
            <Route exact path={'/' + ROUTES.JOURNAL_TRN_TRANSACTION} component={Journal} />
            <Route exact path={'/' + ROUTES.DELETE_VOUCHER_GAVOUCHER} component={Voucher} />
            <Route exact path={'/' + ROUTES.TRIAL_BALANCE_GAREPORT} component={TrialBalance} />
            <Route exact path={'/' + ROUTES.BALANCE_SHEET_GAREPORT} component={BalanceSheet} />
            <Route exact path={'/' + ROUTES.INCOME_STATEMENT_GAREPORT} component={IncomeStatement} />
            <Route exact path={'/' + ROUTES.JOURNAL_GAREPORT} component={JournalR} />
            <Route exact path={'/' + ROUTES.FUNDS_FLOW_GAREPORT} component={FundsFlow} />
            <Route exact path={'/' + ROUTES.CASH_SUMMARY_GAREPORT} component={CashSummary} />
            <Route exact path={'/' + ROUTES.ACCOUNT_BOOK} component={AccountsBookTab} />
            //////////***************** General Accounts *************//////////////////////

            //////////***************** Student Accounts *************//////////////////////
            <Route exact path={'/' + ROUTES.FEE_HEAD} component={FeeHead} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_WAIVER} component={FeeWaiver} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION} component={FeeHeadLedger} />
            <Route exact path={'/' + ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} component={FeeFineLedger} />
            <Route exact path={'/' + ROUTES.FEE_AMOUNT_CONFIGUARTION} component={FeeAmountConfig} />
            <Route exact path={'/' + ROUTES.FEE_WAIVE_CONFIGUARTION} component={FeeWaiverConfig} />
            <Route exact path={'/' + ROUTES.FEE_HEAD_DELETE_CONFIGUARTION} component={FeeHeadDelete} />
            <Route exact path={'/' + ROUTES.FEE_COLLECTION_MANUAL} component={FeesCollection} />
            <Route exact path={'/' + ROUTES.HEAD_COLLECTION_REPORT} component={HeadCollectionWarpper} />
            <Route exact path={'/' + ROUTES.CLASS_DEPARTMENT_PAID_REPORT} component={ClassDepartmentPaidReport} />
            <Route exact path={'/' + ROUTES.SEMESTER_YEAR_PAID_REPORT} component={SemesterYearPaidReport} />
            <Route exact path={'/' + ROUTES.LEDGER_BY_PAID_REPORT} component={LedgerByPaidReport} />
            <Route exact path={'/' + ROUTES.VOUCHER_DELETE} component={VoucherDelete} />
            <Route exact path={'/' + ROUTES.OFPS_ACCOUNT_CONFIG} component={OFPS} />
            <Route exact path={'/' + ROUTES.SPG_STATEMENT} component={SpgStatement} />
           //////////***************** Student Accounts *************//////////////////////

            <Route exact path={'/' + ROUTES.PAY_SLIP_CREATE} component={CreatePaySlip} />
            <Route exact path={'/' + ROUTES.PAY_SLIP_COLLECT} component={CollectPaySlip} />
            <Route exact path={'/' + ROUTES.PAY_SLIP_TEMPLATE} component={PayslipTemplate} />
            <Route exaxt path={'/'+ROUTES.PAYSLIP_DATE_UPDATE} component={PayslipDateUpdate}></Route>

            <Route exact path={'/' + ROUTES.SMS_SEND_STUDENT} component={SmsSendStudent} />
            <Route exact path={'/' + ROUTES.SMS_SEND_STAFF} component={SmsSendStaff} />
            <Route exact path={'/' + ROUTES.SMS_PURCHASE} component={PurchaseSms} />

            <Route exact path={'/' + ROUTES.USER_LIST} component={Users} />

            <Route exact path={'/' + ROUTES.INSTITUTE_SETTINGS} component={InstituteInfoUpdate} />
            <Route exact path={'/' + ROUTES.INSTITUTE_GO} component={GoToInstitute} />
            <Route exact path={'/' + ROUTES.INSTITUTE_LIST} component={InstituteList} />
            <Route exact path={'/' + ROUTES.OFPS_PROBLEM} component={OfpsProblemView} />
            <Route exaxt path={'/'+ROUTES.INSTITUTE_STUDENT_COUNT} component={InstiuteStudentCount}></Route>
            <Route exaxt path={'/'+ROUTES.PAYABLE_BILL_LIST} component={PayableBill}></Route>
           <Route exaxt path={'/'+ROUTES.PAID_BILL_LIST} component={PaidBill}></Route>
           <Route exaxt path={'/'+ROUTES.STUDENT_FEE_DUE_REPORT} component={StudentFeeheadDue}></Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', padding:"15px", background: "transparent" }}>
          &copy; Copyright {year} <Link to="https://shebadigital.com/" style={{ color: "#00CA88" }}>Sheba Digital Limited</Link> | Part of Sheba Group.
        </Footer>
      </Layout>
    </Layout>
  );
}
