import React, { useEffect, useState } from 'react';
import { Table, Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';

import { DeleteOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';

import xlsxParser from 'xlsx-parse-json';

export default function MultipleRegistrationWithCustomIdExcel() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [tableRowStore, setTableRowStore] = useState([]);
    const createRegistrationdatawithCustomId = useStoreActions((state) => state.student.createRegistrationdatawithCustomId)
    const fetchshiftList = useStoreActions((state) => state.student.fetchShiftList);
    const shiftList = useStoreState((state) => state.student.shiftList);
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const [selectedSessionYear, setselectedSessionYear] = useState<any>();
    const [selectedClassDepartment, setSelectedClassDepartment] = useState();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const [selectedSemesterShift, setSelectedSemesterShift] = useState();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const checkReg = useStoreState((state) => state.student.checkReg);
    const setcheckReg = useStoreActions((state) => state.student.setcheckReg);

    const getshiftData = (val) => {
        setSelectedSemesterShift(val);
    }
    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        getSemeterYear(value);
    }
    useEffect(() => {
        fetchClassConfigList();
        fetchshiftList();
        fetchSessionList();
    }, []);

    const [checked, setChecked] = useState<boolean>(false);
    const [academicYear, setAcademicYear] = useState<any>();
    const [section, setSection] = useState<any>();
    const [group, setGroup] = useState<any>();
    const [studentCategory, setStudentCategory] = useState<any>();
    const [fileListx, setFileList] = useState<any>([]);

    const onChangeFile = ({ fileList: newFileList }) => {
        newFileList[0].status = "done"
        xlsxParser
            .onFileSelection(newFileList[0]?.originFileObj)
            .then(data => {
                console.log(data?.['Sheet1']);
                if (data?.['Sheet1'] === undefined) {
                    notification.error({ message: "Please use sample file " })
                    window.location.reload();
                    setTableRowStore([]);
                    return;
                } else if (data?.['Sheet1'].length === 0) {
                    message.error("No student found in the file")
                    window.location.reload();
                    setTableRowStore([]);
                    return;
                }
                let temp = data?.['Sheet1']?.map(function (item, index) {
                    return {
                        // hrId: item['HR ID'],
                        key: index,
                        studentRoll: item['Roll No.'],
                        registrationNo: item['Registration No.'],
                        studentName: item['Name'],
                        fatherName: item["Father's Name"],
                        motherName: item["Mother's Name"],
                        studentMobile: item['Mobile No.'],
                        gender: item['Gender'],
                        religion: item['Religion'],
                        customStudentId: item['Student ID'],
                    }
                })
                console.log(temp)
                setTableRowStore(temp)
                data = ''
                setFileList([])
                newFileList = ''
            });

    };


    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        };
    const onchangeValueDropDown: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData: any = [...tableRowStore];
            newData[index][key] = e;
            setTableRowStore(newData);
        };


    const [validation, setValidation] = useState<boolean>(false);
    /****Registration Table Start*****/
    const columns: any = [
        {
            title: 'Student Id',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Student id" value={record.customStudentId} name="customStudentId" className="stdName" style={{ width: 200 }} onChange={onchangeValue("customStudentId", record, index)} />
                    {validation && (record.customStudentId == undefined || record.customStudentId =='') && <span className="error">Student id is required</span>}
                </>
            ),
        },        
        {
            title: 'Student Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Student name" value={record.studentName} name="studentName" className="stdName" style={{ width: 200 }} onChange={onchangeValue("studentName", record, index)} />
                    {validation && (record.studentName == undefined || record.studentName =='') && <span className="error">Student name is required</span>}
                </>
            ),
        },
        {
            title: 'Student Mobile',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Mobile" className="stdMobile" value={record.studentMobile} name="studentMobile" style={{ width: 130 }} onChange={onchangeValue("studentMobile", record, index)} />
                    {validation && (record.studentMobile?.length > 11 ? <span className="error">Mobile number should be 11 digit</span> : record.studentMobile?.length < 11 ? <span className="error">Mobile number should be 11 digit</span> : null)}
                </>
            ),
        },
        {
            title: 'Student Roll',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Roll" className="stdRoll" value={record.studentRoll} name="studentRoll" style={{ width: 80 }} onChange={onchangeValue("studentRoll", record, index)} />
                    {validation && (record.studentRoll == undefined || record.studentRoll =='') && <span className="error">Student roll is required</span>}
                </>
            ),
        },
        {
            title: 'Registration No',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Registration No" value={record.registrationNo} className="regRoll" name="registrationNo" style={{ width: 100 }} onChange={onchangeValue("registrationNo", record, index)} />

                </>
            ),
        },
        {
            title: 'Father Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Father name" value={record.fatherName} className="fatherName" name="fatherName" style={{ width: 200 }} onChange={onchangeValue("fatherName", record, index)} />
                    {validation && (record.fatherName == undefined || record.fatherName =='') && <span className="error">Father name is required</span>}
                </>
            ),
        },
        {
            title: 'Mother Name',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Input placeholder="Mother name" value={record.motherName} className="motherName" name="motherName" style={{ width: 200 }} onChange={onchangeValue("motherName", record, index)} />
                    {validation && (record.motherName == undefined || record.motherName =='') && <span className="error">Mother name is required</span>}
                </>
            ),
        },
        {
            title: 'Religion',
            dataIndex: '',
            key: '',
            render: (text, record, index) => (
                <>
                    <Select
                        placeholder="Select religion"
                        className="religion"
                        value={record.religion}
                        style={{ width: 150 }}
                        onChange={onchangeValueDropDown("religion", record, index)}
                    >
                        <Option value="Islam">Islam</Option>
                        <Option value="Hinduism">Hinduism</Option>
                        <Option value="Christian">Christian</Option>
                        <Option value="Buddist">Buddist</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                    {validation && (record.religion == undefined || record.religion =='') && <span className="error">Religion is required</span>}
                </>
            ),
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: '',
            render: (text, record, index) => (
                <>
                    <Select
                        placeholder="Select Gender"
                        className="gender"
                        value={record.gender}
                        style={{ width: 150 }}
                        onChange={onchangeValueDropDown("gender", record, index)}
                    >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                    </Select>
                    {validation && (record.gender == undefined || record.gender =='') && <span className="error">Gender is required</span>}
                </>
            ),
        },

    ];


const finalSubmit =(e)=>{
    submitRegForm();
}
    const submitRegForm = () => {
        setTimeout(() => {
            let error = document.getElementsByClassName("error");
            if (error.length > 0) {
                notification.error({ message: "Please fill all the required fields", description: "" });
                return;
            } else {
                let postData = {
                    "classDepartmentConfigId": selectedClassDepartment,
                    "sessionId": selectedSessionYear,
                    "sessionYearId": selectedSemesterYear,
                    "shiftId": selectedSemesterShift,
                    "studentList": tableRowStore
                }
                createRegistrationdatawithCustomId(postData);
 

            }

        }, 500);


    }

    useEffect(() => {
        if (checkReg===true) {
            setValidation(false);
            setTableRowStore([]);
            form.resetFields();
            setcheckReg(false);
        }
    }, [checkReg]);
    /****Registration Table End******/
    return (
        <>
                            <Form
                        layout="vertical"
                        id="classConfigInfo"
                        form={form}
                        onFinish={finalSubmit}
                    >
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>

                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="classId"
                                    label="Session"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select session" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select session"
                                        onChange={(e) => { setselectedSessionYear(e); }}
                                    >
                                        {sessionList ? (
                                            sessionList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Session</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="departmentId"
                                    label="Class Department"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select Class Department" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Class Department"
                                        onChange={(e) => { getSemesterYear(e); }}
                                    >
                                        {classConfigList ? (
                                            classConfigList.map((type, idx) => (
                                                <Option key={type.configId} value={type.configId}>
                                                    {type.configName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="semesterYear"
                                    label="Semester Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select semester year" },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select semester year"
                                        optionFilterProp="children"
                                        onChange={(e) => { setSelectedSemesterYear(e); }}
                                    >
                                        {semesterYearByDepartmentId ? (
                                            semesterYearByDepartmentId.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Form.Item
                                    name="shift"
                                    label="Shift"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select shift" },
                                    ]}
                                >
                                    <Select placeholder="Select shift" onChange={(e) => getshiftData(e)}>
                                        {shiftList ? (
                                            shiftList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching shift</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Upload
                                    listType="text"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    fileList={fileListx}
                                    onChange={onChangeFile}
                                    style={{ maxWidth: 220 }}

                                >
                                    {fileListx.length < 1 && <><Button icon={<UploadOutlined />}>Upload Excel File</Button></>}
                                </Upload>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Button type="primary" onClick={() => window.open('https://res.cloudinary.com/dnyoqhb7g/raw/upload/v1616406742/Student-excel/student_admission_custom_id.xlsx', '_blank')}>Sample File</Button>
                            </Col>
                        </Row>
                    
                </Col>
            </Row>
            <Row className="m-t-mo-30" style={{ display: tableRowStore?.length > 0 ? 'block' : 'none' }}>
                <Col span="24">
                    <Table
                        columns={columns}
                        dataSource={tableRowStore}
                        bordered={true}
                        pagination={false}
                        className="p-datatable-responsive-demo"
                    />
                    <div style={{ float: 'right' }} >

                       
                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} onClick={e=>setValidation(true)}  className="m-t-mo-30 mt-30">
                            Save
                        </Button>

                    </div>

                </Col>
            </Row>
            </Form>
        </>
    )
}