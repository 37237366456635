import { post, get, del} from "../http";


export const fetchDistrictList = () => get("/location/info/district-list");
export const fetchSessions = () => get("/initial-setup/session/list");
export const sessionCreate = (payload) => post("/initial-setup/session/create", payload);
export const sessionUpdate = (payload) => post("/initial-setup/session/update", payload);
export const sessionDelete = (payload) => del("/initial-setup/session/delete?id="+payload);

//////////////

export const fetchClass = () => get("/initial-setup/class/list");
export const classCreate = (payload) => post("/initial-setup/class/create", payload);
export const classUpdate = (payload) => post("/initial-setup/class/update", payload);
export const classDelete = (payload) => del("/initial-setup/class/delete?id="+payload);


//////////////

export const fetchDepartment = () => get("/initial-setup/department/list");
export const departmentCreate = (payload) => post("/initial-setup/department/create", payload);
export const departmentUpdate = (payload) => post("/initial-setup/department/update", payload);
export const departmentDelete = (payload) => del("/initial-setup/department/delete?id="+payload);

////////////

export const fetchSessionYear = () => get("/initial-setup/sessionyear/list");
export const sessionYearCreate = (payload) => post("/initial-setup/sessionyear/create", payload);
export const sessionYearUpdate = (payload) => post("/initial-setup/sessionyear/update", payload);
export const sessionYearDelete = (payload) => del("/initial-setup/sessionyear/delete?id="+payload);

////////////

export const fetchShift = () => get("/initial-setup/shift/list");
export const shiftCreate = (payload) => post("/initial-setup/shift/create", payload);
export const shiftUpdate = (payload) => post("/initial-setup/shift/update", payload);
export const shiftDelete = (payload) => del("/initial-setup/shift/delete?id="+payload);

////////////

export const fetchClassDepartmentConfigrationlist = () => get("/class-department-configuration/list");
export const classConfigrationCreate = (payload) => post("/class-department-configuration/save", payload);
export const semesterYearById = (payload) => get("/class-semester-year/configuration/list/by/config-id?configId="+payload);
export const ClassConfigDelete = (payload) => del("/class-department-configuration/delete?configId="+payload);


////////////

export const fetchClassSemesterConfigrationlist = () => get("/class-semester-year/configuration/list");
export const classSemesterConfigrationCreate = (payload) => post("/class-semester-year/configuration/save", payload);
export const classSemesterYearById = (payload) => get("/initial-setup/sessionyear/list/by/class-dept-configid?classDeptConfigId="+payload);
export const classSemesterConfigDelete = (payload) => del("/class-semester-year/configuration/delete?configId="+payload);

/////////// 

export const createRegistrationdatawithCustomIdUrl = (payload) => post('/student/registration/save/custom-id',payload);
export const createRegistrationdatawithAutoId = (payload) => post('/student/registration/save/auto-id',payload);
export const getStudentListBySessionAndClassDepartment = (payload) => get('/student/report/basic/info/list/by/session/class-department?classDeptConfId='+payload.classDeptConfId+'&sessionId='+payload.sessionId);

////////
export const updateStudentBasicInfoList = (payload) => post('/student/update/basic/info',payload);
export const updateClassDepartmentList = (payload) => post('/student/update/class-department/info',payload);
export const updateSessionYearList = (payload) => post('/student/update/session/info',payload); 
export const updateShiftList = (payload) => post('/student/update/shift/info',payload);
export const updateStudentAddress = (payload) => post('/student/update/address/info',payload);
export const studentWholeInfoUpdateList = (payload) => get('/student/report/whole/info/list/by/session/class-department/semester-year?classDeptConfId='+payload?.classDeptConfId+'&semesterYear='+payload?.semesterYear+'&sessionId='+payload?.sessionId);
export const studentUpdateOtherInfo = (payload) => post('/student/update/about/semester-year/info', payload);

///////
export const studentListForMigration = (payload) => get('/student/migration/ablable/student/list?classDeptConfId='+payload.classDeptConfId+'&semesterYear='+payload.semesterYear+'&sessionId='+payload.sessionId);
export const studentMigration = (payload) => post('/student/migration/save', payload);

export const fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot = (payload) => get('/student/report/basic/info/list/by/session/class-department/has-gone-or-not?classDeptConfId='+payload.classDeptConfId+'&hasGone='+payload.hasGone+'&sessionId='+payload.sessionId);
export const fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot = (payload) => get('/student/report/whole/info/list/by/session/class-department/semester-year/has-gone-or-not?classDeptConfId='+payload.classDeptConfId+'&hasGone='+payload.hasGone+'&sessionId='+payload.sessionId+'&semesterYear='+payload.semesterYear);
export const deleteStudentRegistration = (payload) => del("/student/registration/delete?identificationIds="+payload);
export const updateStudentClassTypeBatch = (payload) => post(`/student/update/class-type/batch?classType=${payload.classType}&identificationIds=${payload.identificationIds}`);;