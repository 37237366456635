import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { ClassConfigDelete, classConfigrationCreate, classCreate, classDelete, classSemesterConfigDelete, classSemesterConfigrationCreate, classSemesterYearById, classUpdate, createRegistrationdatawithAutoId, createRegistrationdatawithCustomIdUrl, deleteStudentRegistration, departmentCreate, departmentDelete, departmentUpdate, fetchClass, fetchClassDepartmentConfigrationlist, fetchClassSemesterConfigrationlist, fetchDepartment, fetchDistrictList, fetchSessions, fetchSessionYear, fetchShift, fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot, fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot, getStudentListBySessionAndClassDepartment, semesterYearById, sessionCreate, sessionDelete, sessionUpdate, sessionYearCreate, sessionYearDelete, sessionYearUpdate, shiftCreate, shiftDelete, shiftUpdate, studentListForMigration, studentMigration, studentUpdateOtherInfo, studentWholeInfoUpdateList, updateClassDepartmentList, updateSessionYearList, updateShiftList, updateStudentAddress, updateStudentBasicInfoList, updateStudentClassTypeBatch } from '../../../http/student/student';

export interface Student {
    fetchSessionList: Thunk<Student>;
    createSession: Thunk<Student, any>;
    updateSession: Thunk<Student, any>;
    deleteSession: Thunk<Student, any>;
    setSessionList: Action<Student, any>;
    sessionList: any;

    //////

    fetchClassList: Thunk<Student>;
    createClass: Thunk<Student, any>;
    updateClass: Thunk<Student, any>;
    deleteClass: Thunk<Student, any>;
    setClassList: Action<Student, any>;
    classList: any;

    //////

    fetchDepartmentList: Thunk<Student>;
    createDepartment: Thunk<Student, any>;
    updateDepartment: Thunk<Student, any>;
    deleteDepartment: Thunk<Student, any>;
    setDepartmentList: Action<Student, any>;
    departmentList: any;

    //////

    fetchSessionYearList: Thunk<Student>;
    createSessionYear: Thunk<Student, any>;
    updateSessionYear: Thunk<Student, any>;
    deleteSessionYear: Thunk<Student, any>;
    setSessionYearList: Action<Student, any>;
    sessionYearList: any;

    //////
    fetchClassConfigList: Thunk<Student>;
    createClassConfiguration: Thunk<Student, any>
    setClassConfigList: Action<Student, any>;
    classConfigList: any;
    deleteClassConfig: Thunk<Student, any>;
    //////

    fetchclassSemesterYearConfigList: Thunk<Student>;
    createclassSemesterYearConfig: Thunk<Student, any>
    setclassSemesterYearConfigList: Action<Student, any>;
    classSemesterYearConfigList: any;
    deleteclassSemesterYearConfig: Thunk<Student, any>;

    //////

    fetchShiftList: Thunk<Student>;
    createShift: Thunk<Student, any>;
    updateShift: Thunk<Student, any>;
    deleteShift: Thunk<Student, any>;
    setShiftList: Action<Student, any>;
    shiftList: any;

    //////
    fetchSemesterYearByDepartment: Thunk<Student>;
    semesterYearByDepartmentId: any;
    setSemesterYearByDepartmentId: Action<Student, any>;

    /////

    registrationTableRow: any;
    setRegistrationTableRow: Action<Student, any>;
    createRegistrationdatawithAutoId: Thunk<Student, any>;
    checkReg: boolean;
    setcheckReg: Action<Student, any>;
    createRegistrationdatawithCustomId: Thunk<Student, any>;


    /////
    fetchStudentListByClassConfigId: Thunk<Student, any>;
    setStudentListByClassConfigId: Action<Student, any>,
    studentListByClassConfigId: any,
    updateStudentBasicInfo: Thunk<Student, any>;

    /////
    fetchStudentListByClassDepartmentInfo: Thunk<Student>;
    setStudentListByClassDepartmentInfo: Action<Student, any>,
    studentListByClassDepartmentInfo: any,
    updateStudentClassDepartmentInfo: Thunk<Student, any>;


    /////
    fetchStudentListForSessionUpdate: Thunk<Student>;
    setStudentListForSessionUpdate: Action<Student, any>,
    studentListForSessionUpdate: any,
    updateStudentSessionyearInfo: Thunk<Student, any>;

    /////
    updateStudentShiftInfo: Thunk<Student, any>;
    fetchStudentListForShiftUpdate: Thunk<Student>;
    setStudentListForShiftUpdate: Action<Student, any>,
    studentListForShiftUpdate: any,
    /////
    updateStudentAddressInfo: Thunk<Student, any>;
    studentListForAddressUpdate: any,
    fetchStudentListForAddressUpdate: Thunk<Student, any>;
    setStudentListForAddressUpdate: Action<Student, any>,

    /////
    studentBasicInfosBySesssionAndClassDepartHasGoneOrNot: any,
    setstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot: Action<Student, any>,
    fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot: Thunk<Student, any>;
    setReport: Action<Student>;


    studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot: any,
    setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot: Action<Student, any>,
    fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot: Thunk<Student, any>;

    /////

    fetchStudentListByClassConfigIdSessionYearSemesterYear: Thunk<Student>;
    studentListByClassConfigIdSessionYearSemesterYear: any;
    setStudentListByClassConfigIdSessionYearSemesterYear: Action<Student, any>,    
    fetchStudentListByClassConfigIdSessionYearSemesterYear2: Thunk<Student>;
    studentListByClassConfigIdSessionYearSemesterYear2: any;
    setStudentListByClassConfigIdSessionYearSemesterYear2: Action<Student, any>,
    updateStudentOtherInfo: Thunk<Student, any>;
    deleteStudentRegistration: Thunk<Student, any>;
    updateStudentClassTypeBatch: Thunk<Student, any>;

    /////
    fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration: Thunk<Student>;
    studentListByClassConfigIdSessionYearSemesterYearForMIgration: any;
    setStudentListByClassConfigIdSessionYearSemesterYearForMIgration: Action<Student, any>,
    studentMigration: Thunk<Student, any>;
}

export const studentStore: Student = {
    sessionList: [],
    classList: [],
    departmentList: [],
    sessionYearList: [],
    classConfigList: [],
    classSemesterYearConfigList: [],
    shiftList: [],
    semesterYearByDepartmentId: [],
    registrationTableRow: [],
    studentListByClassConfigId: [],
    studentListForAddressUpdate: [],
    studentListForSessionUpdate: [],
    studentListByClassDepartmentInfo: [],
    studentListForShiftUpdate: [],

    studentListByClassConfigIdSessionYearSemesterYear: [],
    studentListByClassConfigIdSessionYearSemesterYear2: [],
    studentListByClassConfigIdSessionYearSemesterYearForMIgration: [],

    fetchSessionList: thunk(async (actions) => {
        const response = await fetchSessions();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            console.log('body', body);

            actions.setSessionList(body.item);
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setSessionList: action((state, payload) => {
        state.sessionList = payload;
    }),
    /////////////Session Config//////////////////
    createSession: thunk(async (actions, payload) => {
        const response = await sessionCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchSessionList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateSession: thunk(async (actions, payload) => {
        const response = await sessionUpdate(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchSessionList();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    deleteSession: thunk(async (actions, payload) => {
        const response = await sessionDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchSessionList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    /////////////Class Config//////////////////
    fetchClassList: thunk(async (actions) => {
        const response = await fetchClass();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setClassList(body.item);
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setClassList: action((state, payload) => {
        state.classList = payload;
    }),

    createClass: thunk(async (actions, payload) => {
        const response = await classCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchClassList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateClass: thunk(async (actions, payload) => {
        const response = await classUpdate(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchClassList();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    deleteClass: thunk(async (actions, payload) => {
        const response = await classDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchClassList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    ////////////Department Config///////////////////
    fetchDepartmentList: thunk(async (actions) => {
        const response = await fetchDepartment();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            console.log('body', body);

            actions.setDepartmentList(body.item);
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setDepartmentList: action((state, payload) => {
        state.departmentList = payload;
    }),

    createDepartment: thunk(async (actions, payload) => {
        const response = await departmentCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchDepartmentList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateDepartment: thunk(async (actions, payload) => {
        const response = await departmentUpdate(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchDepartmentList();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    deleteDepartment: thunk(async (actions, payload) => {
        const response = await departmentDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchDepartmentList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    ////////////Session Year Config///////////////////
    fetchSessionYearList: thunk(async (actions) => {
        const response = await fetchSessionYear();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setSessionYearList(body.item);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setSessionYearList: action((state, payload) => {
        state.sessionYearList = payload;
    }),

    createSessionYear: thunk(async (actions, payload) => {
        const response = await sessionYearCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchSessionYearList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateSessionYear: thunk(async (actions, payload) => {
        const response = await sessionYearUpdate(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchSessionYearList();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    deleteSessionYear: thunk(async (actions, payload) => {
        const response = await sessionYearDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchSessionYearList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    ////////////Class Department Config///////////////////

    fetchClassConfigList: thunk(async (actions) => {
        const response = await fetchClassDepartmentConfigrationlist();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setClassConfigList(body.item);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setClassConfigList: action((state, payload) => {
        state.classConfigList = payload;
    }),

    createClassConfiguration: thunk(async (actions, payload) => {
        const response = await classConfigrationCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchClassConfigList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),

    deleteClassConfig: thunk(async (actions, payload) => {
        const response = await ClassConfigDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchClassConfigList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),


    ////////////Class Semester Config///////////////////


    fetchclassSemesterYearConfigList: thunk(async (actions) => {
        const response = await fetchClassSemesterConfigrationlist();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setclassSemesterYearConfigList(body.item);
            console.log('body.item', body.item);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),

    setclassSemesterYearConfigList: action((state, payload) => {
        state.classSemesterYearConfigList = payload;
    }),

    createclassSemesterYearConfig: thunk(async (actions, payload) => {

        const response = await classSemesterConfigrationCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                console.log('body', body);

                notification.success({ message: body.message })
                actions.fetchclassSemesterYearConfigList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),

    deleteclassSemesterYearConfig: thunk(async (actions, payload) => {
        const response = await classSemesterConfigDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchclassSemesterYearConfigList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    ////////////Shift Year Config///////////////////
    fetchShiftList: thunk(async (actions) => {
        const response = await fetchShift();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setShiftList(body.item);
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setShiftList: action((state, payload) => {
        state.shiftList = payload;
    }),

    createShift: thunk(async (actions, payload) => {
        const response = await shiftCreate(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchShiftList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    updateShift: thunk(async (actions, payload) => {
        const response = await shiftUpdate(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchShiftList();
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    deleteShift: thunk(async (actions, payload) => {
        const response = await shiftDelete(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchShiftList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),

    /////////////////Student Registration////////////////////////
    fetchSemesterYearByDepartment: thunk(async (actions, payload) => {
        const response = await classSemesterYearById(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setSemesterYearByDepartmentId(body.item);
            console.log('body', body);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setSemesterYearByDepartmentId: action((state, payload) => {
        state.semesterYearByDepartmentId = payload;
    }),

    setRegistrationTableRow: action((state, payload) => {
        var tableData = [
            {
                key: '',
                select: '',
                name: '',
                age: '',
                address: '',
                description: '',
            }
        ];
        state.registrationTableRow = tableData;
    }),

    createRegistrationdatawithAutoId: thunk(async (actions, payload) => {
        const response = await createRegistrationdatawithAutoId(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                console.log('body', body);
                notification.success({ message: body.message })
                // actions.fetchclassSemesterYearConfigList();
            } else {
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),
    checkReg: false,
    setcheckReg: action((state, payload) => {
        state.checkReg = payload
    }),
    createRegistrationdatawithCustomId: thunk(async (actions, payload) => {
        const response = await createRegistrationdatawithCustomIdUrl(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                console.log('body', body);
                actions.setcheckReg(true);
                notification.success({ message: body.message })
                // actions.fetchclassSemesterYearConfigList();
            } else {
                actions.setcheckReg(false);
                notification.error({ message: body.message })
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),
    ////////////////////////////
    fetchStudentListByClassConfigId: thunk(async (actions, payload) => {
        const response = await getStudentListBySessionAndClassDepartment(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
                let data = body?.item?.map(function (item, key) {
                    item.key = key;
                    item.index = key;
                    return item;
                })
                actions.setStudentListByClassConfigId(data);
            } else {
                actions.setStudentListByClassConfigId([]);
                notification.error({ message: 'No Data Found' })
            }


        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setStudentListByClassConfigId: action((state, payload) => {
        state.studentListByClassConfigId = payload
    }),
    fetchStudentListForAddressUpdate: thunk(async (actions, payload) => {
        const response = await getStudentListBySessionAndClassDepartment(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();


            let data = body?.item?.map(function (item, key) {
                item.key = key;
                return item;
            })

            actions.setStudentListForAddressUpdate(data);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setStudentListForAddressUpdate: action((state, payload) => {
        state.studentListForAddressUpdate = payload
    }),

    fetchStudentListForSessionUpdate: thunk(async (actions, payload) => {
        const response = await getStudentListBySessionAndClassDepartment(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();


            let data = body?.item?.map(function (item, key) {
                item.key = key;
                return item;
            })

            actions.setStudentListForSessionUpdate(data);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),

    setStudentListForSessionUpdate: action((state, payload) => {
        state.studentListForSessionUpdate = payload
    }),
    fetchStudentListByClassDepartmentInfo: thunk(async (actions, payload) => {
        const response = await getStudentListBySessionAndClassDepartment(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();

            let data = body?.item?.map(function (item, key) {
                item.key = key;
                return item;
            })

            actions.setStudentListByClassDepartmentInfo(data);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setStudentListByClassDepartmentInfo: action((state, payload) => {
        state.studentListByClassDepartmentInfo = payload
    }),//
    fetchStudentListForShiftUpdate: thunk(async (actions, payload) => {
        const response = await getStudentListBySessionAndClassDepartment(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();

            let data = body?.item?.map(function (item, key) {
                item.key = key;
                return item;
            })

            actions.setStudentListForShiftUpdate(data);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),//
    setStudentListForShiftUpdate: action((state, payload) => {
        state.studentListForShiftUpdate = payload
    }),
    updateStudentBasicInfo: thunk(async (actions, payload) => {
        const response = await updateStudentBasicInfoList(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: "Something went wrong" })
            }

        } else {
            const body = await response.json();
            notification.error({ message: "Something went wrong" })
        }

    }),

    updateStudentClassDepartmentInfo: thunk(async (actions, payload) => {
        console.log('working', payload);
        const response = await updateClassDepartmentList(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }

    }),
    updateStudentSessionyearInfo: thunk(async (actions, payload) => {
        const response = await updateSessionYearList(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }

    }),
    updateStudentShiftInfo: thunk(async (actions, payload) => {
        const response = await updateShiftList(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    updateStudentAddressInfo: thunk(async (actions, payload) => {
        const response = await updateStudentAddress(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    fetchStudentListByClassConfigIdSessionYearSemesterYear: thunk(async (actions, payload) => {
        const response = await studentWholeInfoUpdateList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length>0){
                actions.setStudentListByClassConfigIdSessionYearSemesterYear(body?.item);
            } else {
                notification.error({message:"No Data Found"});
                actions.setStudentListByClassConfigIdSessionYearSemesterYear([])
            }
           

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),    
    fetchStudentListByClassConfigIdSessionYearSemesterYear2: thunk(async (actions, payload) => {
        console.log('payload', payload);
        const response = await studentWholeInfoUpdateList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length>0){
                actions.setStudentListByClassConfigIdSessionYearSemesterYear2(body?.item);
            } else {
                notification.error({message:"No Data Found"});
                actions.setStudentListByClassConfigIdSessionYearSemesterYear2([])
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),
    deleteStudentRegistration: thunk(async (actions, payload:any) => {
        const response = await deleteStudentRegistration(payload?.ids);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchStudentListByClassConfigIdSessionYearSemesterYear(payload?.infos);
            }  else {
                notification.warn({ message: body.message }) 
            }
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),    
    
    updateStudentClassTypeBatch: thunk(async (actions, payload:any) => {
        const response = await updateStudentClassTypeBatch(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.fetchStudentListByClassConfigIdSessionYearSemesterYear2(payload?.fe);
            } 
        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }

    }),
    setStudentListByClassConfigIdSessionYearSemesterYear: action((state, payload) => {
        state.studentListByClassConfigIdSessionYearSemesterYear = payload
    }),    
    setStudentListByClassConfigIdSessionYearSemesterYear2: action((state, payload) => {
        state.studentListByClassConfigIdSessionYearSemesterYear2 = payload
    }),
    updateStudentOtherInfo: thunk(async (actions, payload) => {
        const response = await studentUpdateOtherInfo(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),
    fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration: thunk(async (actions, payload) => {
        const response = await studentListForMigration(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            let data = body?.item?.map(function (item, key) {
                item.key = key;
                return item;
            })
            actions.setStudentListByClassConfigIdSessionYearSemesterYearForMIgration(data);

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
        }
    }),
    setStudentListByClassConfigIdSessionYearSemesterYearForMIgration: action((state, payload) => {
        state.studentListByClassConfigIdSessionYearSemesterYearForMIgration = payload;


    }),
    studentMigration: thunk(async (actions, payload) => {
        const response = await studentMigration(payload);
        if (response.status === 201) {
            const body = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
            } else {
                notification.error({ message: body.message })
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.statusText })
        }
    }),


    studentBasicInfosBySesssionAndClassDepartHasGoneOrNot: null,
    fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot: thunk(async (actions, payload) => {
        const response = await fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
                actions.setstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot(body?.item);
                } else {
                    notification.error({ message: "No data found" })
                    actions.setstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot(null)
                }

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot(null)
        }

    }),
    setstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot: action((state, payload) => {
        state.studentBasicInfosBySesssionAndClassDepartHasGoneOrNot = payload
    }),

    studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot: null,
    fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot: thunk(async (actions, payload) => {
        const response = await fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
            actions.setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot(body?.item);
            } else {
                notification.error({ message: "No data found" })
                actions.setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot(null)
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot(null)
        }

    }),
    setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot: action((state, payload) => {
        state.studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot = payload
    }),    
    
    setReport: action((state) => {
        state.studentBasicDetailsInfosBySesssionAndClassDepartSemesterYearHasGoneOrNot = null;
        state.studentBasicInfosBySesssionAndClassDepartHasGoneOrNot = null;
    }),

}
