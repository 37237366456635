import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectDepartment } from '../../../select/SelectDepartment';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';


export default function CalssConfiguration() {
    const { Option } = Select;
    const fetchclassSemesterYearConfigList = useStoreActions((state) => state.student.fetchclassSemesterYearConfigList);
    const fetchSessionYearList = useStoreActions((state) => state.student.fetchSessionYearList);
    const sessionYearList = useStoreState((state) => state.student.sessionYearList);
    const classSemesterYearConfigList = useStoreState((state) => state.student.classSemesterYearConfigList);
    const createclassSemesterYearConfig = useStoreActions((state) => state.student.createclassSemesterYearConfig);
    const deleteclassSemesterYearConfig = useStoreActions((state) => state.student.deleteclassSemesterYearConfig);
    const [semesterYearConfigForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    useEffect(() => {
        fetchSessionYearList();
        fetchclassSemesterYearConfigList();
    },[])

    const classConfigFormStore =(value) => {  
        let postData = {
            classId: value.classId,
            semesterYearId: value.semesterYear,
            serial: value.serialNumber
        }
        createclassSemesterYearConfig(postData);
        semesterYearConfigForm.resetFields();
    }

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Semester Year Name',
            dataIndex: 'semesterYearName',
            key: 'semesterYearName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteclassSemesterYearConfig(record?.configId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        }
      ];

    return <>
        <Card title="Class Semester Year Configuration">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={classConfigFormStore}
                        form={semesterYearConfigForm}
                    >
                        <Row>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                        name="classId"
                                        label="Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                    <SelectClass />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                        name="semesterYear"
                                        label="Semester Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester" },
                                        ]}
                                    >
                                    <Select placeholder="Select semester year">
                                        {sessionYearList ? (
                                            sessionYearList.map((type, idx) => (
                                            <Option key={type.id} value={type.id}>
                                                {type.name}
                                            </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Semester Year</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                        name="serialNumber"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <Table bordered={true} dataSource={classSemesterYearConfigList} columns={columns} className="p-datatable-responsive-demo" />
                    </div>
                </Col>
            </Row>
        </Card>
    </>
}