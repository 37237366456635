import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import Table from "../../../../contents/AntTableResponsive";
import { tuple } from 'antd/lib/_util/type';
import { SelectDistrict } from '../../../select/SelectDistrict';
import { SelectThana } from '../../../select/SelectThana';

export default function AddressInfoUpdate(){

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListForAddressUpdate);
    const fetchShiftList = useStoreActions((state) => state.student.fetchShiftList);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListForAddressUpdate);
    const updateStudentAddressInfo = useStoreActions((state) => state.student.updateStudentAddressInfo);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [studentId, setSelectedStudentId] = useState<any>();
    const [disttrict,setDistrict]= useState();

    const [thana, setThana]= useState<any>();
    const [updateForm] = Form.useForm();
    const thanaListFetch = useStoreActions((state) => state.common.thanaListFetch);
    useEffect(() => {
        fetchSessionList();
        fetchClassConfigList();
        fetchShiftList();
    },[]);

    const  searchStudentList = (value) => {
        let payload = {
            classDeptConfId: value.classDepartment,
            sessionId: value.sessionYearName
        };
        
        fetchStudentListByClassConfigId(payload);
        // setCurrentPayload(payload);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true, 
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Registration No',
            dataIndex: 'registrationNo',
            key: 'registrationNo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Villege',
            dataIndex: 'villege',
            key: 'villege',
            showOnResponse: true,
            showOnDesktop: true, 
        },
        {
            title: 'PostOffice',
            dataIndex: 'postOffice',
            key: 'postOffice',
            showOnResponse: true,
            showOnDesktop: true, 
        },   
        {
            title: 'Mailing Address',
            dataIndex: 'mailingAddress',
            key: 'mailingAddress',
            showOnResponse: true,
            showOnDesktop: true, 
        },
        {
            title: 'District',
            dataIndex: 'districtName',
            key: 'districtName',
            showOnResponse: true,
            showOnDesktop: true, 
        },  
        {
            title: 'Thana Name',
            dataIndex: 'thanaName',
            key: 'thanaName',
            showOnResponse: true,
            showOnDesktop: true, 
        }, 
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            showOnResponse: true,
            showOnDesktop: true, 
            render: (text, record, index) => (
                <Button type='primary' icon={<EditOutlined />}  
                    onClick={() => {
                        setIsModalVisible(true);
                        setSelectedStudentId(record.studentId);
                        setDistrict(record.districtId);
                        thanaListFetch(record.districtId)
                        setTimeout(() => {
                            setThana(record.thanaId);
                        }, 1000);
                        updateForm.setFieldsValue({
                            villege: record.villege,
                            postOffice: record.postOffice,
                            mailingAddress: record.mailingAddress,
                            districtId: record.districtId,
                            thanaId: record.thanaId,
                        });
                    }}
                />
            ),
        }, 
        
        
    ];

    const districsIdStore = (val) => {
        setDistrict(val);
    }

    const changeDistrict = () => {
        updateForm.setFieldsValue({
            thanaId:null   
        })
    }

    const updateAddress = (value) => {
        console.log(value);
        let postData =  [{
            "mailingAddress": value.mailingAddress,
            "postOffice": value.postOffice,
            "studentId": studentId,
            "thanaId": thana,
            "villege": value.villege
          }]
          updateStudentAddressInfo(postData);
          let searchButton= document.getElementById('searchButton')

          if (searchButton!=null){
            searchButton.click();
          }
          setIsModalVisible(false);
          
    }

    return (
        <>
            <Card title="Student Address Info Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                            name="sessionYearName"
                                            label="Session Year"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write session year" },
                                            ]}
                                        >
                                        <Select 
                                                placeholder="Select session"
                                            >
                                                {sessionList ? (
                                                    sessionList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Session</Option>
                                                )}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                            name="classDepartment"
                                            label="Class Department"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Select class department" },
                                            ]}
                                        >
                                            <Select 
                                                placeholder="Select Class Department"
                                            >
                                                {classConfigList ? (
                                                    classConfigList.map((type, idx) => (
                                                    <Option key={type.configId} value={type.configId}>
                                                        {type.configName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Class</Option>
                                                )}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <div style={{ display:  studentListByClassConfigId.length > 0 ? 'block' : 'none' }}>
                    <Row className="m-t-mo-30">
                        <Col span="24">
                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: studentListByClassConfigId,
                                    pagination: true,
                                    bordered: true,
                                    rowKey:"key",
                                }}
                                mobileBreakPoint={768}
                                
                            />
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="Address Info Update"
                    visible={isModalVisible}
                    //  onOk={handleOk}
                    okButtonProps={{ form: 'update', htmlType: 'submit' }}
                    onCancel={() => setIsModalVisible(false)}
                    cancelText="Close"
                    okText="Update"
                    centered
                >
                    <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateAddress}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="villege"
                                    label="Village Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Write village name" },
                                    ]}                                       
                                >
                                     <Input 
                                        placeholder="Village name"
                                     />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="postOffice"
                                    label="Post Office"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Write post office" },
                                    ]}                                       
                                >
                                     <Input 
                                        placeholder="Write post office"
                                     />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="mailingAddress"
                                    label="Mailing Address"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Write your address" },
                                    ]}                                       
                                >
                                     <Input 
                                        placeholder="Tour address"
                                     />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="districtId"
                                    label="District Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select districs name" },
                                    ]}                                       
                                >
                                    <SelectDistrict selected={disttrict} onChange={e=>{districsIdStore(e); setThana(null);changeDistrict()}} />

                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="thanaId"
                                    label="Thana"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Select thana" },
                                    ]}                                       
                                >
                                    <SelectThana selected={thana} onChange={e=>setThana(e)} />
                                    
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                 </Modal>    
            </Card>
        </>
    )
}