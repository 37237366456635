import { DeleteOutlined, EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';


export default function InstituteInfoUpdate(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)

    const updateInstituteInfo = useStoreActions((state) => state.auth.updateInstituteInfo);


    const formSubmit = (value) => {
        value.imageChange = imageChanged;
        value.imageFullName = imageFullName;
        value.instituteImage = instituteImage;
        console.log(value);
        updateInstituteInfo(value)

    }

    const [imageChanged, setImageChanged] = useState<boolean>(false);
    const [imageFullName, setImageFullName] = useState<string>('');
    const [instituteImage, setInstituteImage] = useState<any>('');

    async function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    useEffect(() => {
        form.setFieldsValue({
            ...instituteInfo
        })
    }, [instituteInfo])

    const uploadImage = (file) => {
        console.log(file)
        if (file.target.files[0]?.size > 150000) {
            notification.error({ message: 'Image size should be less than 150kb' })
            file.target.value = '';
            setImageChanged(false);
            setImageFullName('');
            return;
        };
        setImageFullName(file.target.files[0].name);
        getBase64(file.target.files[0], (imageUrl) => {
            setInstituteImage(imageUrl.split(',')[1]);
            setImageChanged(true);
        })

    }


    return (
        <>
            <Card title="Institute Info Update" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteName"
                                label="Institue Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institue name" },
                                ]}
                            >
                                <Input placeholder="Institue Name" />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="address"
                                label="Institute Address"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input institue address" },
                                ]}
                            >
                                <Input placeholder="Institute Address" />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="adminName"
                                label="Admin Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input admin name" },
                                ]}
                            >
                                <Input placeholder="Admin Name" />

                            </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="contactNumber"
                                label="Contact Number"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input contact number" },
                                ]}
                            >

                                <Input placeholder="Contact Number" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteEmail"
                                label="Email"
                                className="title-Text"

                            >
                                <Input placeholder="Email" />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="instituteImage"
                                label="Logo"
                                className="title-Text"

                            >
                                <div style={{ display: 'flex', justifyContent: 'center,', alignContent: "center", alignItems: "center" }}>
                                    <img src={"data:image/png;base64," + instituteInfo?.instituteImage} height="50" width="50" id="imagename" />
                                    <input type="file" name="my_file" id="my_file" accept="image/jpeg, image/png" style={{ marginLeft: 20 }} onChange={e => uploadImage(e)} />
                                </div>

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={18}>

                            <Space size="small" style={{ float: "right" }} >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>

                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                    </Row>


                </Form>



            </Card>


        </>
    )
}
