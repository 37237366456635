import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";


export default function FeeHead(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const feeHeadList = useStoreState((state) => state.saccount.feeHeadList);
    const fetchfeeHeadList = useStoreActions((state) => state.saccount.fetchfeeHeadList);
    const createFeeHead = useStoreActions((state) => state.saccount.createFeeHead);
    const updateFeeHead = useStoreActions((state) => state.saccount.updateFeeHead);
    const deleteFeeHead = useStoreActions((state) => state.saccount.deleteFeeHead);

    useEffect(() => {
        fetchfeeHeadList();
    }, [])

    const formSubmit = (value) => {
        value.status=1;
        createFeeHead(value);
        form.resetFields();
    }    
    
    const updateFomrSubmit = (value) => {
        let postdata ={
            id: id,
            name: value.name,
            serial: value.serial,
            status: value.status===true?1:0
        }
        updateFeeHead(postdata);
        setIsModalVisible(false);
    }

    const [id, setId] = useState<any>();

    const columns = [

        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Status',
            key: 'status',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                        <span>{record?.status===1?"Enabled":"Disabled"}</span>
            ),
        },
        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setId(record.id);
                            updateForm.setFieldsValue({
                                name: record.name,
                                status: record.status===1?true:false,
                                serial: record.serial,
                            });
                        }}
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteFeeHead(record?.id)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="Fee Head" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="name"
                                label="Fee Head Name"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input Fee Head name" },
                                ]}
                            >

                                <Input placeholder="Fee Head" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="serial"
                                label="Serial"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >

                                <Input placeholder="Serial" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>

                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: feeHeadList,
                            pagination: false,
                            bordered: true
                        }}
                        mobileBreakPoint={768}
                    />
                </div>

            </Card>

            <Modal
                title="Update Fee Head"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="name"
                        label="Fee Head Name:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input Fee Head name" },
                        ]}
                    >
                        <Input placeholder='Fee Head Name' />
                    </Form.Item>


                    <Form.Item
                        name="serial"
                        label="Serial:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input serial" },
                        ]}
                    >
                        <Input placeholder='Note' />
                    </Form.Item>
                    
                    <Form.Item
                        name="status"
                        label="Status:"
                        valuePropName="checked"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input serial" },
                        ]}
                    >
                       <Checkbox >Status</Checkbox>
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
