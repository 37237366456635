import { createStore } from 'easy-peasy';
import { Auth, authStore } from './states/auth/auth';
import { Common, commonStore } from './states/common/common';
import { Ledger, ledgerStore } from './states/ledger/ledger';
import { User, userStore } from './states/user/user';
import { Student, studentStore } from './states/student/student';
import { Saccount, saccountStore } from './states/saccount/saccount';
import { Staff, staffStore } from './states/staff/staff';
import { Payslip, payslipStore } from './states/payslip/payslip';
import { Dashboard, dashboardStore } from './states/dashboard/dashboard';

export interface StoreModel {
	auth: Auth;
	dashboard: Dashboard;
	common: Common;
	ledger: Ledger;
	user: User;
	student: Student;
	saccount: Saccount;
	staff: Staff;
	payslip: Payslip;

}

const storeModel: StoreModel = {
	auth: authStore,
	dashboard: dashboardStore,
	common: commonStore,
	ledger: ledgerStore,
	user: userStore,
	student: studentStore,
	saccount: saccountStore,
	staff: staffStore,
	payslip: payslipStore,
}

export const store = createStore(storeModel);
