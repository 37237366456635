import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Badge } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectDepartment } from '../../../select/SelectDepartment';
import Table from "../../../../contents/AntTableResponsive";
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';



export default function ClassTypeUpdate() {
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const shiftList = useStoreState((state) => state.student.shiftList);
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const [selectedSessionYear, setselectedSessionYear] = useState();
    const [selectedClassDepartment, setSelectedClassDepartment] = useState();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const updateStudentClassTypeBatch = useStoreActions((state) => state.student.updateStudentClassTypeBatch);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchStudentListByClassConfigIdSessionYearSemesterYear2 = useStoreActions((state) => state.student.fetchStudentListByClassConfigIdSessionYearSemesterYear2);
    const studentListByClassConfigIdSessionYearSemesterYear2 = useStoreState((state) => state.student.studentListByClassConfigIdSessionYearSemesterYear2);
    const [updateForm] = Form.useForm();
    useEffect(() => {
        fetchClassConfigList();
        fetchSessionList();
    }, []);

    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        getSemeterYear(value);
    }

    const getStudentReportList = (value) => {
        setSelectedSemesterYear(value);
    }

    const [fe, setFe] = useState();
    const onFinish = (value) => {
        setFe(value);
        fetchStudentListByClassConfigIdSessionYearSemesterYear2(value);
    }


    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Mobile',
            dataIndex: 'studentMobile',
            key: 'studentMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Type',
            dataIndex: 'studentType',
            key: 'studentType',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div>
                    {record.studentType === 1 ? 'Regular' : record.studentType === 2 ? 'Irregular' : 'Improved'}
                </div>
            ),
        },

        {
            title: 'Class Type',
            dataIndex: 'classType',
            key: 'classType',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div>
                    {record.classType == 1 ? 'New' : 'Old'}
                </div>
            ),
        },

    ];
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };




    const onProcess = () => {
        if (selectedValue.length === 0) {
            message.error('Please select atleast one student');
            return;
        }
        setIsModalVisible(true);

    }

    function onUpdate(value) {
        let data = selectedValue.map(item => item.identificationId).join(',');
        updateStudentClassTypeBatch({ "classType": value?.classType, "identificationIds": data, "fe":fe })
        setselectedRowKeys([]);
        setselectedValue([]);
        setIsModalVisible(false);
        updateForm.resetFields()
    }

    return (
        <>
            <Card title="Class Type Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sessionId"
                                        label="Session"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select session" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select session"
                                            onChange={(e) => setselectedSessionYear(e)}
                                        >
                                            {sessionList ? (
                                                sessionList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Session</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classDeptConfId"
                                        label="Class Department"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class-department" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select class-department"
                                            onChange={(e) => getSemesterYear(e)}
                                        >
                                            {classConfigList ? (
                                                classConfigList.map((type, idx) => (
                                                    <Option key={type.configId} value={type.configId}>
                                                        {type.configName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="semesterYear"
                                        label="Semester Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester year" },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select semester year"
                                            optionFilterProp="children"
                                            onChange={(e) => getStudentReportList(e)}
                                        >
                                            {semesterYearByDepartmentId ? (
                                                semesterYearByDepartmentId.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="m-t-mo-30" style={{ display: studentListByClassConfigIdSessionYearSemesterYear2.length > 0 ? 'block' : 'none' }}>
                    <Col span="24">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentListByClassConfigIdSessionYearSemesterYear2,
                                pagination: true,
                                bordered: true,
                                rowKey: "studentId",
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <Space size={"small"} style={{ float: "right", marginTop: 10 }} >

                        <Button type="primary" icon={<SettingOutlined />} onClick={() => onProcess()} > Process</Button>

                    </Space>
                </Row>
                <Modal
                    title="Other Info Update"
                    visible={isModalVisible}
                    //  onOk={handleOk}
                    okButtonProps={{ form: 'update', htmlType: 'submit' }}
                    onCancel={() => { setIsModalVisible(false) }}
                    cancelText="Close"
                    okText="Update"
                    centered
                >
                    <Form
                        layout="vertical"
                        id="update"
                        onFinish={onUpdate}
                        form={updateForm}
                    >
                        <Row>

                            <Col span={24}>
                                <Form.Item
                                    name="classType"
                                    label="Class Type"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "please select class type" },
                                    ]}

                                >
                                    <Select placeholder="Class Type">
                                        <Option value="1"> New</Option>
                                        <Option value="2"> Old</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Modal>
            </Card>
        </>
    )
}