import React, { useEffect } from 'react';
import { useStoreActions, Actions } from "easy-peasy";
import { StoreModel } from "./store/store";
import { Badge, Button, Card, Col, List, Popover, Row, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { AntDesignOutlined, BellOutlined, KeyOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { useStoreState } from './store/hooks/easyPeasy';
import { Link } from "react-router-dom";
import userp from "../assets/images/user.png";
const { Text } = Typography;

export default function TopNavBar(props: any) {
  const fetchMasterSettingPayabledList = useStoreActions<any>((state) => state.common.fetchMasterSettingPayabledList);
  const masterSettingPayabledList = useStoreState((state) => state.common.masterSettingPayabledList);
  var isMobile = false; //initiate as false
  if (
    /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
  ) {
    isMobile = true;
  }
  const logout = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logout
  );
  const logoutclear = useStoreActions(
    (actions: Actions<StoreModel>) => actions.auth.logoutclear
  );

  const user = useStoreState(state => state.auth.user)
  const instituteInfo = useStoreState(state => state.auth.instituteInfo)

  const logoutfunc = () => {
    logoutclear(user?.access_token);
    logout("");
  }

  const toggleValueChange = (val) => {
    return val;
  }

  useEffect(function () {
    let topHeaderWidth: any = document.getElementById('topFixedBar');
    const div = document.querySelector('#mySider');
    setTimeout(function () {
      let check = div?.classList.contains('ant-layout-sider-collapsed');
      if (!isMobile) {
        if (check == true) {
          topHeaderWidth?.classList.remove("menu-open");
          topHeaderWidth?.classList.add("menu-close");
          console.log('true check', check);

        } else {
          topHeaderWidth?.classList.remove("menu-close");
          topHeaderWidth?.classList.add("menu-open");
          //console.log('false check', check);
        }
      } else {
        topHeaderWidth?.classList.add("menu-mobile");
        topHeaderWidth?.classList.remove("menu-close");
        topHeaderWidth?.classList.remove("menu-open");
      }
    }, 1);
  }, [toggleValueChange]);

  useEffect(() => {
    fetchMasterSettingPayabledList(instituteInfo?.instituteId);
  }, []);
  
  const [dueAmount, setDueAmount] = React.useState<any>(0);
  const [count, setCount] = React.useState<any>(0);
  
  useEffect(() => {
    if (masterSettingPayabledList?.length > 0) {
      setCount(masterSettingPayabledList?.length);
      let due = masterSettingPayabledList.reduce(function (acc, obj) {
        return acc + obj.payableAmount;
      }, 0);
      setDueAmount(due);
    }
  }, [masterSettingPayabledList]);

  const profilePopover = (onLogout, userData) => (
    <div
      style={{ width: 300 }}
      className="topbarDropDown"
    >
      <div className="profile-sort-details-wrapper">
        <div className="profile-image">
          {instituteInfo?.userPhoto === '' ? <img src={userp} alt="User Photo" /> : <img src={"data:image/png;base64," + instituteInfo?.userPhoto} alt="User Photo" />}
        </div>
        <div className="user-name text-center">
          <h4>{instituteInfo?.nickName}</h4>
          <span>{instituteInfo?.instituteEmail}</span>
        </div>
        <ul>
          <li className="headerDrop"><Link to="/profile" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} ><KeyOutlined /> <div>Change Password</div></Link></li>
          <li className="headerDrop" onClick={onLogout}><div><LogoutOutlined /> <span>Logout</span></div></li>
        </ul>
      </div>
      {/* <ul style={{ marginBottom: -5 }}>
        <li className="headerDrop"><strong>Logged in as: {instituteInfo?.userName}</strong></li>
        <li className="headerDrop"><Link to="/profile" ><div style={{display:"flex", justifyContent:"space-between"}}>Change Password<span><KeyOutlined  /></span></div></Link></li>
        <li style={{cursor:"pointer", }} className="headerDrop" onClick={onLogout}><div style={{display:"flex", justifyContent:"space-between"}}><span  style={{cursor:"pointer"}} >Logout</span><span><LogoutOutlined  /></span></div></li>
      </ul> */}
    </div>
  );

  const duepopHover = () => (
    <div
      style={{ width: 320, textAlign: 'center' }}
      className="topbarDropDown"

    >
      {dueAmount > 0 ?
        <>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>The total amount due as of the Bill date is <br /> <span style={{ color: "red" }}>৳{dueAmount}</span>  </p>

          <div style={{ textAlign: 'center' }} > <Link to="/payable-bill"> <div>Payable Bill </div></Link></div>
        </> :
        <>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>You don't have any unpaid bill at the moment. </p>
        </>
      }

    </div>

  );


  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="insInfo" style={{ width: window.screen.width - 70, display: 'flex', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", alignItems: "center" }}>
          {React.createElement(props.value.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggleValueChange(props.value.toggle),
            style: { marginLeft: 2, marginRight: 2, fontSize: 20, color: "#fff" },
          })}
          {isMobile && props.value.collapsed &&
            <>
              <Avatar
                size={{ xs: 35, sm: 35, md: 35, lg: 35, xl: 40, xxl: 45 }}
                src={"data:image/png;base64," + instituteInfo?.instituteImage}
                style={{ marginLeft: 10 }}
              />
              <span className='instiute-name' style={{ fontSize: isMobile ? 14 : 18, fontWeight: "bold", color: '#2243CE', textTransform: "uppercase", letterSpacing: isMobile ? 0 : 1, marginLeft: 2 }}>{instituteInfo?.instituteName}</span>
            </>
          }
          {isMobile && !props.value.collapsed &&
            <>
              {React.createElement(props.value.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: toggleValueChange(props.value.toggle),
                style: { marginLeft: 2, marginRight: 2, fontSize: 25, fontWeight: 'bold', color: "#fff", right: 70, position: "absolute" },
              })}
            </>
          }
          {!isMobile &&
            <>
              <Avatar
                size={{ xs: 35, sm: 35, md: 35, lg: 35, xl: 40, xxl: 45 }}
                src={"data:image/png;base64," + instituteInfo?.instituteImage}
                style={{ marginLeft: 10 }}
              />
              <span style={{ fontSize: isMobile ? 14 : 18, fontWeight: "bold", color: '#2243CE', textTransform: "uppercase", letterSpacing: isMobile ? 0 : 1, marginLeft: 5 }}>{instituteInfo?.instituteName}</span>
            </>
          }
        </div>
        {isMobile && props.value.collapsed &&   <div style={{marginRight:isMobile?20:50, position:"absolute", right:50, top:10}}>
          <Popover
            content={duepopHover()}
            placement="bottomLeft"
            trigger="click"
     
          >
            <Badge count={count}  style={{ backgroundColor: dueAmount>0?'red':'#52c41a', top:6, right:4 }} >
            <BellOutlined style={{fontSize:30, color:"#fff", }} />
            {/* <Avatar
                icon={<BellOutlined  />}
                size="large"
                style={{ backgroundColor: dueAmount > 0 ? 'transparent' : 'transparent', borderColor: dueAmount > 0 ? 'red' : '#52c41a' }}
                className="pointer topUserImage"
              /> */}
            </Badge>
          </Popover>
        </div>   }  
        {!isMobile  &&  <div style={{marginRight:isMobile?20:50, position:"absolute", right:50, top:10}}>
          <Popover
            content={duepopHover()}
            placement="bottomLeft"
            trigger="click"
     
          >
            <Badge count={count}  style={{ backgroundColor: dueAmount>0?'red':'#52c41a', top:6, right:4 }} >
            <BellOutlined style={{fontSize:30, color:"#2243CE", }} />
            {/* <Avatar
                icon={<BellOutlined  />}
                size="large"
                style={{ backgroundColor: dueAmount > 0 ? 'transparent' : 'transparent', borderColor: dueAmount > 0 ? 'red' : '#52c41a' }}
                className="pointer topUserImage"
              /> */}
            </Badge>
          </Popover>
        </div>   }
        {/* <div style={{ marginRight: isMobile ? 20 : 50, position: "absolute", right: 50 }}>
          <Popover
            content={duepopHover()}
            placement="bottomLeft"
            trigger="click"

          >
            <Badge count={count} style={{ backgroundColor: dueAmount > 0 ? 'red' : '#52c41a' }} >
              <Avatar
                icon={<BellOutlined />}
                size="large"
                style={{ backgroundColor: dueAmount > 0 ? '#CCCCCC' : '#52c41a', borderColor: dueAmount > 0 ? 'red' : '#52c41a' }}
                className="pointer topUserImage"
              />
            </Badge>
          </Popover>
        </div> */}

        <div>
          <Popover
            content={profilePopover(logoutfunc, user)}
            placement="bottomLeft"
            trigger="click"
          >
            {instituteInfo?.userPhoto === '' ?
              <Avatar
                icon={<UserOutlined />}
                size="large"
                style={{ marginRight: 5 }}
                className="pointer topUserImage"
              /> :
              <Avatar
                size="large"
                src={"data:image/png;base64," + instituteInfo?.userPhoto}
                style={{ marginRight: 5 }}
                className="pointer topUserImage"
              />}
          </Popover>
        </div>
      </div>
    </>
  );
}