import { post, get, del,postFile} from "../http";

export const fetchdesignationList = () => get("/initial-setup/designation/list");
export const createDesignation = (payload) => post("/initial-setup/designation/create", payload);
export const updateDesignation = (payload) => post("/initial-setup/designation/update", payload);
export const deleteDesignation = (payload) => del("/initial-setup/designation/delete?id="+payload,);
export const saveSingleStaffRegistration = (payload) => post("/staff/single/registration", payload);
export const doMultipleStaffRegistration = (payload) => post("/staff/multiple/registration", payload);
export const updateSingleStaffBasicInfo = (payload) => post("/staff/basic/update", payload);
export const fetchstaffBasicInfoList = () => get("/staff/basic/info/list/for/update");
export const updateSingleStaffPhoto = (payload, staffId) => postFile(`/staff/photo/update?staffId=${staffId}`, payload);

export const fetchdepartmentTeacherAssignList = () => get("/department/teacher/assign/list");
export const saveDepartmentTeacherAssign = (payload) => post("/department/teacher/assign/save", payload);
export const deleteDepartmentTeacherAssign = (payload) => del("/department/teacher/assign/delete?departmentTeacherAssignId="+payload,);
