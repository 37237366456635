import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectSemesterYear } from '../../../select/SelectSemesterYear';
import { SelectFeeWaiverFee } from '../../../select/SelectFeeWaiverFee';
import moment from 'moment';
import { SelectLedgerCashEqui } from '../../../select/SelectLedgerCashEqui';

export default function FeesCollection(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const studentCollecionView = useStoreState((state) => state.saccount.studentCollecionView);
    const checkmanualFee = useStoreState((state) => state.saccount.checkmanualFee);
    const fetchstudentCollecionView = useStoreActions((state) => state.saccount.fetchstudentCollecionView);
    const savestudentCollecionView = useStoreActions((state) => state.saccount.savestudentCollecionView);
    const waiverList = useStoreState((state) => state.saccount.waiverList);
    const fetchwaiverList = useStoreActions((state) => state.saccount.fetchwaiverList);
    const [tableData, setTableData] = useState<any>()
    const [dtableData, setDtableData] = useState<any>()

    const [formSubmitData, setformSubmitData] = useState<any>();

    const formSubmit = (value) => {
        console.log(value)
        setformSubmitData(value)
        fetchstudentCollecionView(value);

    }

    useEffect(() => {
        fetchwaiverList();
    }, [])

    useEffect(() => {
        let temp;
        if (waiverList?.length > 0) {
            temp = waiverList[0]?.id
        } else {
            temp = null
        };

        let data = studentCollecionView?.feesPayableViews?.map(function (item: any, key) {
            item.key = key;
            if (item.waiverId === null) {
                item.waiverId = temp
            }
            return item;
        });

        let ddata = studentCollecionView?.duePayableViews?.map(function (item: any, key) {
            item.key = key;
            item.due = 0;
            if (item.waiverId === null) {
                item.waiverId = temp
            }
            return item;
        })

        setTableData(data);
        setDtableData(ddata);

    }, [checkmanualFee])

    const onchangeValue: any =
        (key, data, index) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newData = [...tableData];
            newData[index][key] = e;
            setTableData(newData);
        };

    const onchangeValueWaiver: any =
        (key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            if (e >= data.actualFeeAmount) {
                e = 0
            }
            const newData = [...tableData];
            newData[index][key] = e;
            newData[index]['feeAmount'] = data.actualFeeAmount - e;
            newData[index]['payableAmount'] = (data.actualFeeAmount - e) + data.fineAmount;
            newData[index]['paidAmount'] = (data.actualFeeAmount - e) + data.fineAmount;
            newData[index]['dueAmount'] = 0;
            setTableData(newData);
        };

    const onchangeValuePaid: any =
        (key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            if (e > data.payableAmount) {
                e = data.payableAmount
            }
            const newData = [...tableData];
            newData[index][key] = e;
            newData[index]['dueAmount'] = data.payableAmount - e;
            if (e === 0) {
                newData[index]['paidAmount'] = data.payableAmount;
                newData[index]['dueAmount'] = 0;
            }
            setTableData(newData);
        };


    const columns = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fine Amount',
            dataIndex: 'fineAmount',
            key: 'fineAmount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Waiver Amount',
            key: 'waiverAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValueWaiver("waiverAmount", record, index)}
                    value={record.waiverAmount}
                    placeholder="Waiver Amount"
                    disabled={selectedRowKeys.includes(record.key) ? true : false}
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },

        {
            title: 'Waiver Type',
            key: 'waiverId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <SelectFeeWaiverFee disabled={selectedRowKeys.includes(record.key) ? true : false} selected={record.waiverId} onChange={onchangeValue("waiverId", record, index)} style={{ width: "100%" }} />
            ),
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Paid Amount',
            key: 'paidAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    onChange={onchangeValuePaid("paidAmount", record, index)}
                    disabled={selectedRowKeys.includes(record.key) ? true : false}
                    value={record.paidAmount}
                    placeholder="Paid Amount"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Due Amount',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            showOnResponse: true,
            showOnDesktop: true
        }

    ];

    const onchangeValueWaiverDue: any =
        (key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            if (e >= data.actualDuePayable) {
                e = 0
            }
            const newData = [...dtableData];
            newData[index][key] = e;
            newData[index]['duePayable'] = data.actualDuePayable - e;
            newData[index]['duePaid'] = data.actualDuePayable - e;
            newData[index]['due'] = 0;
            setDtableData(newData);
        };

    const onchangeValuePaidDue: any =
        (key, data, index) => (e) => {
            if (e === '') {
                e = 0
            }
            if (e === null) {
                e = 0
            }
            if (e > data.duePayable) {
                e = data.duePayable
            }
            const newData = [...dtableData];
            newData[index][key] = e;
            newData[index]['due'] = data.duePayable - e;
            if (e === 0) {
                newData[index]['duePayable'] = data.payableAmount;
                newData[index]['due'] = 0;
            }
            setDtableData(newData);
        };


    const columns2 = [

        {
            title: 'Fee Head',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Waiver Amount',
            key: 'waiverAmount',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    disabled={selectedRowKeys2.includes(record.key) ? true : false}
                    onChange={onchangeValueWaiverDue("waiverAmount", record, index)}
                    value={record.waiverAmount}
                    placeholder="Waiver Amount"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },

        {
            title: 'Waiver Type',
            key: 'waiverId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <SelectFeeWaiverFee disabled={selectedRowKeys2.includes(record.key) ? true : false} selected={record.waiverId} onChange={onchangeValue("waiverId", record, index)} style={{ width: "100%" }} />
            ),
        },
        {
            title: 'Due Payable',
            dataIndex: 'duePayable',
            key: 'duePayable',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Paid Amount',
            key: 'duePaid',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <InputNumber
                    min={0}
                    disabled={selectedRowKeys2.includes(record.key) ? true : false}
                    onChange={onchangeValuePaidDue("duePaid", record, index)}
                    value={record.duePaid}
                    placeholder="Due Paid"
                // style={{ width: 250 }}
                ></InputNumber>
            ),
        },
        {
            title: 'Due',
            dataIndex: 'due',
            key: 'due',
            showOnResponse: true,
            showOnDesktop: true
        }

    ];


    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);

        let payableAmount = value.reduce(function (acc, obj) {
            return acc + obj.payableAmount;
        }, 0);
        settotalPayable(payableAmount);

        let paidAmount = value.reduce(function (acc, obj) {
            return acc + obj.paidAmount;
        }, 0);
        settotalPaidAmount(paidAmount);

        let dueAmount = value.reduce(function (acc, obj) {
            return acc + obj.dueAmount;
        }, 0);
        settotalDue(dueAmount)

        let waiverAmount = value.reduce(function (acc, obj) {
            return acc + obj.waiverAmount;
        }, 0);
        settotalWaiver(waiverAmount)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [selectedRowKeys2, setselectedRowKeys2] = useState<any>([]);
    const [selectedValue2, setselectedValue2] = useState<any>([]);

    const onSelectChange2 = (selectedRowKeys2, value) => {
        setselectedRowKeys2(selectedRowKeys2);
        setselectedValue2(value);

        let duePayable = value.reduce(function (acc, obj) {
            return acc + obj.duePayable;
        }, 0);
        setdtotalPayable(duePayable);

        let duePaid = value.reduce(function (acc, obj) {
            return acc + obj.duePaid;
        }, 0);
        setdtotalPaidAmount(duePaid);

        let due = value.reduce(function (acc, obj) {
            return acc + obj.due;
        }, 0);
        settotaldDue(due)

        let waiverAmount = value.reduce(function (acc, obj) {
            return acc + obj.waiverAmount;
        }, 0);
        settotaldWaiver(waiverAmount)
    };

    const rowSelection2 = {
        selectedRowKeys2,
        onChange: onSelectChange2,
    };

    const [totalPayable, settotalPayable] = useState<any>(0);
    const [totalPaidAmount, settotalPaidAmount] = useState<any>(0);
    const [totalDue, settotalDue] = useState<any>(0);
    const [totalWaiver, settotalWaiver] = useState<any>(0);

    const [dtotalPayable, setdtotalPayable] = useState<any>(0);
    const [dtotalPaidAmount, setdtotalPaidAmount] = useState<any>(0);
    const [totaldDue, settotaldDue] = useState<any>(0);
    const [totaldWaiver, settotaldWaiver] = useState<any>(0);
    const [paymentDate, setpaymentDate] = useState<any>(moment(new Date()).format("YYYY-MM-DD"));
    const [note, setnote] = useState<any>('');
    const [paymentLedgerId, setpaymentLedgerId] = useState<any>(null);


    return (
        <>
            <Card title="Fees Collection" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>

                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="customStudentId"
                                label="Student Id"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input student id" },
                                ]}
                            >

                                <Input placeholder="Student Id" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="semesterYearId"
                                label="Semester Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select semester year" },
                                ]}
                            >

                                <SelectSemesterYear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                            {checkmanualFee &&
                                <>
                                    <Row gutter={8}>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> <strong>Student Id</strong> </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> : {studentCollecionView?.customStudentId} </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> <strong>Student Name</strong> </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> : {studentCollecionView?.studentName} </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> <strong>Session </strong></Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> : {studentCollecionView?.sesssion} </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}><strong> Class Department </strong></Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}> : {studentCollecionView?.classDepartment} </Col>

                                    </Row>
                                </>
                            }
                        </Col>

                    </Row>
                </Form>

                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                    {checkmanualFee &&
                        <>
                            {tableData?.length > 0 &&
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: tableData,
                                        pagination: false,
                                        bordered: true,
                                        rowKey: "key",
                                        rowSelection: rowSelection,
                                    }}
                                    mobileBreakPoint={768}
                                />
                            }
                            <br />
                            {dtableData?.length > 0 &&
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns: columns2,
                                        dataSource: dtableData,
                                        pagination: false,
                                        bordered: true,
                                        rowKey: "key",
                                        rowSelection: rowSelection2,
                                    }}
                                    mobileBreakPoint={768}
                                />
                            }

                            <br />

                            <Row gutter={8}>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>

                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Row gutter={8}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Total Payable:</span> <br />
                                            <Input value={totalPayable + dtotalPayable} readOnly disabled style={{ marginBottom: 15, height: '40px' }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Total Paid:</span> <br />
                                            <Input value={totalPaidAmount + dtotalPaidAmount} readOnly disabled style={{ marginBottom: 15, height: '40px' }} />
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Total Due:</span> <br />
                                            <Input value={totalDue + totaldDue} readOnly disabled style={{ height: '40px' }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Payment Date:</span> <br />
                                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} defaultValue={moment((new Date()), "DD/MM/YYYY")} onChange={e => setpaymentDate(moment(e).format("YYYY-MM-DD"))} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Paid By:</span> <br />
                                            <SelectLedgerCashEqui selected={paymentLedgerId} onChange={e => setpaymentLedgerId(e)} style={{ width: "100%" }} />
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <span>Note:</span> <br />
                                            <Input style={{ marginBottom: 15, height: '40px' }} onChange={e => setnote(e.target.value)} placeholder='Note' />
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                            <Button type="primary" style={{ float: "right" }} onClick={() => {


                                if (paymentLedgerId === null) {
                                    notification.error({ message: "Please select ledger" });
                                    return
                                }

                                let feeHeadInfos = selectedValue.map(function (item) {
                                    return {
                                        "due": item.dueAmount,
                                        "feeHeadId": item.feeHeadId,
                                        "feeHeadName": item.feeHeadName,
                                        "fineAmount": item.fineAmount,
                                        "paidAmount": item.paidAmount,
                                        "payableAmount": item.payableAmount,
                                        "waiverAmount": item.waiverAmount,
                                        "waiverId": item.waiverId
                                    }
                                })

                                let dueInfos = selectedValue2.map(function (item) {
                                    return {
                                        "feeHeadId": item.feeHeadId,
                                        "dueWaiverId": item.waiverId,
                                        "dueWaiverAmount": item.waiverAmount,
                                        "duePayable": item.duePayable,
                                        "duePaid": item.duePaid,
                                    }
                                })

                                let postdata = {
                                    identificationId: studentCollecionView?.identificationId,
                                    note: note,
                                    paymentDate: paymentDate,
                                    totalPaidAmount: totalPaidAmount + dtotalPaidAmount,
                                    totalPayable: totalPayable + dtotalPayable,
                                    totalWaiver: totalWaiver + totaldWaiver,
                                    paymentLedgerId: paymentLedgerId,
                                    feeHeadInfos: feeHeadInfos,
                                    dueInfos: dueInfos,
                                }


                               if (postdata.totalPaidAmount<1) {
                                    notification.error({ message: "Paid amount cannot be zero" });
                                    return
                                }
                                savestudentCollecionView(postdata);
                                setselectedRowKeys([]);
                                setselectedValue([]);
                                setselectedRowKeys2([]);
                                setselectedValue2([]);
                                settotalPayable(0);
                                settotalPaidAmount(0);
                                settotalDue(0);
                                settotalWaiver(0);
                                setdtotalPayable(0);
                                setdtotalPaidAmount(0);
                                settotaldDue(0);
                                settotaldWaiver(0);
                                setpaymentLedgerId(null);
                                setTimeout(() => {
                                    fetchstudentCollecionView(formSubmitData);
                                }, 500);
                            }}>Collect</Button>
                        </>

                    }
                </div>

            </Card>


        </>
    )
}
