import { Button, Card, Col, DatePicker, Form, notification, Row, Space, Tooltip } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectSession } from '../../../select/SelectSession';
import { SelectClass } from '../../../select/SelectClass';
import { SelectDepartmentByClass } from '../../../select/SelectDepartmentByClass';
import { SelectSemesterYearByClass } from '../../../select/SelectSemesterYearByClass';
import ReactExport from "react-data-export";
import { Excel } from 'antd-table-saveas-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let excelx: any = [];
let tempCol: any = []
let tempExcelCol: any = []

export default function HeadCollectionStudent(props) {

    const dateToDateStudentFeeHeadCollection = useStoreState((state) => state.payslip.dateToDateStudentFeeHeadCollection);
    const feeHeadNames = useStoreState((state) => state.payslip.feeHeadNames);
    const fetchdateToDateStudentFeeHeadCollection = useStoreActions((state) => state.payslip.fetchdateToDateStudentFeeHeadCollection);
    const downloadMoneyReciept = useStoreActions((state) => state.saccount.downloadMoneyReciept);
    const fetchdepartmentListByClassId = useStoreActions((state) => state.common.fetchdepartmentListByClassId);
    const fetchsessionYearListByClassId = useStoreActions((state) => state.common.fetchsessionYearListByClassId);
    const [selectedSessionYear, setselectedSessionYear] = useState<any>();
    const [selectedClass, setSelectedClass] = useState<any>();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const [selectedDepartment, setselectedDepartment] = useState<any>();
    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {

        tempCol=[];
        tempExcelCol=[];

        setexcelHead(['Student Id', 'Student Name', 'Roll'])

        setcolumns([{
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            showOnResponse: true,
            showOnDesktop: true,
        },
        ])
        value.fromDate = moment(value?.fromDate).format("YYYY-MM-DD");
        value.toDate = moment(value?.toDate).format("YYYY-MM-DD");
        fetchdateToDateStudentFeeHeadCollection(value);
    };






    const [columns, setcolumns] = useState<any>([]);
    const [excelHead, setexcelHead] = useState<any>([]);

    useEffect(() => {

        tempCol = []
        tempExcelCol = []

        Object.entries(feeHeadNames).forEach(([key, value], index) => {
            let temp: any = {
                title: value,
                dataIndex: `feeHead${index + 1}`,
                key: `feeHead${index + 1}`,
                showOnResponse: true,
                showOnDesktop: true,
            };
            let temExcel = value
            tempCol.push(temp)
            tempExcelCol.push(temExcel)
        });
        // console.log("tempCol", tempCol)
        let final = [].concat(columns, tempCol);
        setexcelHead(tempExcelCol)
        // console.log("final", finalExcel)
        setcolumns(final)

        tempExcelCol.forEach((element, index) => {
            excelx.push(<ExcelColumn label={element} value={`feeHead${index + 1}`} />)
        })


    }, [feeHeadNames])




    return (
        <>

            <Form
                layout="vertical"
                onFinish={reportFormSubmit}
                id="basic-info"
                form={reportForm}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}></Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="sessionId"
                            label="Session"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select session" },
                            ]}
                        >

                            <SelectSession />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="classId"
                            label="Class"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select class" },
                            ]}
                        >

                            <SelectClass selected={selectedClass} onChange={(e) => { fetchsessionYearListByClassId(e); fetchdepartmentListByClassId(e); setSelectedClass(e); setselectedDepartment(null); setSelectedSemesterYear(null) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="departmentId"
                            label="Department"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select department" },
                            ]}
                        >
                            <SelectDepartmentByClass selected={selectedDepartment} onChange={(e) => { setselectedDepartment(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="semesterId"
                            label="Semester Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select semester year" },
                            ]}
                        >

                            <SelectSemesterYearByClass selected={selectedSemesterYear} onChange={(e) => { setSelectedSemesterYear(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}></Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}></Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="fromDate"
                            label="From Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter from date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="toDate"
                            label="To Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter to date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>
            {dateToDateStudentFeeHeadCollection !== null &&
                <>
                    <div className="datatable-responsive">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: dateToDateStudentFeeHeadCollection,
                                pagination: true,
                                bordered: true,
                                rowKey: "identificationId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>

                        {/* <ExcelFile element={<Button type="primary" icon={<FileExcelOutlined />} >Download Excel</Button>}>
                            <ExcelSheet data={dateToDateStudentFeeHeadCollection} name="Data">
                                <ExcelColumn label="Student Id" value="customStudentId" />
                                <ExcelColumn label="Student Name" value="studentName" />
                                <ExcelColumn label="Roll" value="studentRoll" />
                                {excelx}
                                <ExcelColumn label="Total" value="total" />
                            </ExcelSheet>
                        </ExcelFile> */}

                        < Button
                                type='primary'

                                icon={<FileExcelOutlined />}
                                onClick={() => {
                                    const excel: any = new Excel();
                                    excel
                                        .addSheet(`Sheet 1`)
                                        .addColumns(columns)
                                        .addDataSource(dateToDateStudentFeeHeadCollection)
                                        .saveAs(`Student Head Collection.xlsx`);
                                }}
                            >
                                Download Excel
                            </ Button >

                    </Space>
                </>
            }

        </>
    )
}
