import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import StudentFeeheadDueSummary from './StudentFeeheadDueSummary';
import StudentFeeheadDueDetails from './StudentFeeheadDueDetails';

    export default function HeadCollectionWarpper(){
        const { TabPane } = Tabs;

        return(
            <>
                <Card title="Student Fee Head Due">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Summary" key="1">
                            <StudentFeeheadDueSummary />
                        </TabPane>
                        <TabPane tab="Details" key="2">
                            <StudentFeeheadDueDetails />
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        )
    }