
import { Card, Checkbox, Col, DatePicker, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";


export default function InstituteList(props) {


    const instituteList = useStoreState((state) => state.common.instituteList);
    const fetchinstituteList = useStoreActions((state) => state.common.fetchinstituteList);

    useEffect(() => {
        fetchinstituteList();
    }, [])

    const columns = [

        {
            title: 'Institute Id',
            dataIndex: 'instituteId',
            key: 'instituteId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Institute Name',
            dataIndex: 'instituteName',
            key: 'instituteName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Admin',
            dataIndex: 'adminName',
            key: 'adminName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Email",
            dataIndex: 'instituteEmail',
            key: 'instituteEmail',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Status",
            dataIndex: 'instituteStatus',
            key: 'instituteStatus',
            showOnResponse: true,
            showOnDesktop: true
        },

    ];



    return (
        <>
            <Card title="Institute List" >

                <Table
                    antTableProps={{
                        showHeader: true,
                        columns,
                        dataSource: instituteList,
                        pagination: true,
                        bordered: true,
                        rowKey: 'instituteId',

                    }}
                    mobileBreakPoint={768}
                />


            </Card>
        </>
    )
}
