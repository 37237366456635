import { Button, Card, Col, DatePicker, Form, Row, Space, Table } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import Text from 'antd/lib/typography/Text';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Feehead Name", dataKey: "feeHeadName" },
        { title: "Fee Collection", dataKey: "feeCollection" },
        { title: "Fine Collection", dataKey: "fineCollection" },
        { title: "Total Collection", dataKey: "totalCollection" },
    ];
};

let fd = '';
let td = '';
let totalfeeCollection = 0;
let totalfineCollection = 0;
let totaltotalCollection = 0;

export default function HeadCollection(props) {

    const dateToDateFeeHeadCollection = useStoreState((state) => state.payslip.dateToDateFeeHeadCollection);
    const fetchdateToDateFeeHeadCollection = useStoreActions((state) => state.payslip.fetchdateToDateFeeHeadCollection);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchdateToDateFeeHeadCollection(postData);
    };



    function exportPdf() {



        var doc = new jsPDF("p", "mm", "a4");

        var details = `Head collection from ${fd} to ${td} `;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        let first = doc.autoTable?.previous;
        doc.autoTable(getColumns(), dateToDateFeeHeadCollection, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                feeHeadName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                feeCollection: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 35
                },
                fineCollection: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 35
                },
                totalCollection: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 35
                }
            },

        });

        doc.autoTable([
            { title: "", dataKey: "b1" },
            { title: "", dataKey: "b2" },
            { title: "", dataKey: "b3" },
            { title: "", dataKey: "b4" },
        ], [
            {
                b1: "Total",
                b2: totalfeeCollection,
                b3: totalfineCollection,
                b4: totaltotalCollection,
            }
        ], {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                b1: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 75
                },
                b2: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 35
                },
                b3: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 35
                },
                b4: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 35
                },

            },

            // addPageContent: pageContent,
        });

        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    const columns = [

        {
            title: 'Feehead Name',
            dataIndex: 'feeHeadName',
            key: 'feeHeadName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Collection',
            dataIndex: 'feeCollection',
            key: 'feeCollection',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Fine Collection',
            dataIndex: 'fineCollection',
            key: 'fineCollection',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Total Collection',
            dataIndex: 'totalCollection',
            key: 'totalCollection',
            showOnResponse: true,
            showOnDesktop: true,
        },

    ];




    return (
        <>

            <Form
                layout="vertical"
                onFinish={reportFormSubmit}
                id="basic-info"
                form={reportForm}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="fromDate"
                            label="From Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter from date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="toDate"
                            label="To Date"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please enter to date" },
                            ]}
                        >

                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                </Row>
            </Form>
            {dateToDateFeeHeadCollection !== null &&
                <>
                    <div className="datatable-responsive">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: dateToDateFeeHeadCollection,
                                pagination: true,
                                bordered: true,
                                rowKey: "feeHeadId",
                                summary: function () {
                                    totalfeeCollection=0;
                                    totalfineCollection=0;
                                    totaltotalCollection=0;
                                    dateToDateFeeHeadCollection?.forEach(({ feeCollection, fineCollection, totalCollection }) => {
                                        totalfeeCollection += feeCollection;
                                        totalfineCollection += fineCollection;
                                        totaltotalCollection += totalCollection;
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}><Text type="danger" strong>Total</Text> </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Text strong> {totalfeeCollection}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                    <Text strong> {totalfineCollection}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={3}>
                                                    <Text strong> {totaltotalCollection} </Text>
                                                </Table.Summary.Cell>


                                            </Table.Summary.Row>

                                        </>
                                    );
                                }
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right", marginTop: 15 }}>Download PDF</Button>
                </>
            }

        </>
    )
}
