import { post, get} from "../http";


export const fetchpartnerProfile = () => get("/partner/profile");
export const fetchDistrictList = () => get("/location/info/district-list");
export const fetchclassList = () => get("/initial-setup/class/list");
export const fetchdesignationList = () => get("/initial-setup/designation/list");
export const fetchdepartmentList = () => get("/initial-setup/department/list");
export const fetchfeeHeadList = () => get("/initial-setup/feehead/list");
export const fetchsessionYearList = () => get("/initial-setup/sessionyear/list");
export const fetchThanaList = (id) => get(`/location/info/thana-list/by/district-id?districtId=${id}`);
export const fetchsessionList = () => get("/initial-setup/session/list");
export const fetchsessionYearListByClassId = (id) => get(`/initial-setup/sessionyear/list/by/class-id?classId=${id}`);
export const fetchdepartmentListByClassId = (id) => get(`/initial-setup/department/list/by/class-id?classid=${id}`);
export const fetchsessionYearListByClassDeptConfigId = (id) => get(`/initial-setup/sessionyear/list/by/class-dept-configid?classDeptConfigId=${id}`);
export const fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear = (payload) => get(`/student/report/whole/info/list/by/session/class-department/semester-year?classDeptConfId=${payload.classDeptConfId}&semesterYear=${payload.semesterYear}&sessionId=${payload.sessionId}`);
export const sendSmsToClassStudent = (payload) => post("/sms/send/student",payload);
export const sendSmsToStaff = (payload) => post("/sms/send/staff",payload);
export const updateInstituteInfo = (payload) => post("/institute/info/update",payload);
export const instituteInfo = () => get("/institute/info");
export const fetchinstituteList = () => post("/institute/list");
export const jumpToInstitute = (id) => get(`/institute/jump?instituteId=${id}`);
export const solveOfpsTransactionProblem = (payload) => post('/ofps-transaction/problem/solve', payload);
export const smsBlanceurl = (payload) => get("/sms/report/balance/info"); 
export const fetchofpsTransactionProblemCheck = (payload) => get('/ofps-transaction/problem/check?refTrnNo=' + payload);
export const fetchofpsTransactionProblemView = (payload) => get("/ofps-transaction/problem/view?customStudentId=" + payload.customStudentId + "&instituteId=" + payload.instituteId + "&trnDate=" + payload.trnDate);
export const fetchStudentCount = () => get('/institute/wise/student/count');