import { Select } from "antd";
import * as React from "react";
import { useStoreActions, useStoreState } from "../../store/hooks/easyPeasy";
const { Option } = Select;

export interface SelectClassDepartment {
  onChange?: any;
  selected?: any;
  defaultSelected?: any;
  style?: any
}

export const SelectClassDepartment = ({
  onChange,
  selected,
  defaultSelected,
  style
}: SelectClassDepartment) => {
  const itemList = useStoreState((state) => state.student.classConfigList);
  const itemListFetch = useStoreActions((state) => state.student.fetchClassConfigList);
  const fetchsessionYearListByClassDeptConfigId = useStoreActions((state) => state.common.fetchsessionYearListByClassDeptConfigId);

  React.useEffect(() => {
    itemListFetch();
  },[])

  const onSelect = (id) => {
    if (itemList) {
      const value = itemList.find((item) => item.configId === id);
      onChange(value.configId);
    }
  };

  React.useEffect(() => {
    if (selected) {
      fetchsessionYearListByClassDeptConfigId(selected);
    }
  }, [selected])


  return (
    <Select
      onChange={onSelect}
      // loading={loading}
      showSearch
      className="classDep"
      // allowClear
      defaultValue={defaultSelected}
      value={selected}
      style={style}
      placeholder="Select Class Department"
      filterOption={(input, option:any) =>
        option !== undefined &&
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {itemList ? (
        itemList.map((type, idx) => (
          <Option key={type.configId} value={type.configId}>
            {type.configName}
          </Option>
        ))
      ) : (
        <Option value="fetching">Fetching Class Department</Option>
      )}
    </Select>
  );
};
