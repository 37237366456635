import { DeleteOutlined, EditOutlined, SaveOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../../store/hooks/easyPeasy';
import Table from "../../../../../contents/AntTableResponsive";
import { SelectSession } from '../../../../select/SelectSession';
import { SelectClassDepartment } from '../../../../select/SelectClassDepartment';
import { SelectSemesterYearByClassDepartment } from '../../../../select/SelectSemesterYearByClassDepartment';
import { SelectFeeHead } from '../../../../select/SelectFeeHead';
import { SelectFeeWaiver } from '../../../../select/SelectFeeWaiver';


export default function WaiverConfig(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [updateForm2] = Form.useForm();

    const studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear = useStoreState((state) => state.common.studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear);
    const fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear = useStoreActions((state) => state.common.fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear);
    const saveStudentWaiver = useStoreActions((state) => state.saccount.saveStudentWaiver);
    const saveStudentWaiverBatch = useStoreActions((state) => state.saccount.saveStudentWaiverBatch);


    // useEffect(() => {
    //     fetchfeeHeadList();
    // }, [])

    const formSubmit = (value) => {
        console.log(value)
        fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear(value)
    }

    const updateFomrSubmit = (value) => {
        value.identificationId = identificationId
        saveStudentWaiver(value);
        setIsModalVisible(false);
        updateForm.resetFields()
    }

    const submitBatch = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({ message: "Please select student" });
            return
        };
        setIsModalVisible2(true);
    }
    const updateFomrSubmit2 = (value) => {
        value.identificationIds = selectedValue.map(item => item.identificationId)
        saveStudentWaiverBatch(value);
        setIsModalVisible2(false);
        updateForm2.resetFields();
        setselectedRowKeys([]);
        setselectedValue([])
    }

    const [identificationId, setidentificationId] = useState<any>();

    const columns = [

        {
            title: 'Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mobile',
            dataIndex: 'studentMobile',
            key: 'studentMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Registration No',
            dataIndex: 'registrationNo',
            key: 'registrationNo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Process',
            key: 'studentId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Process">
                        <Button type='primary' icon={<SettingOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setidentificationId(record.identificationId);

                        }}
                        />
                    </Tooltip>


                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const [dep, setDep] = useState<any>();
    const [semy, setsemy] = useState<any>();

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="sessionId"
                            label="Session"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select session " },
                            ]}
                        >

                            <SelectSession />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="classDeptConfId"
                            label="Class Department"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please class department" },
                            ]}
                        >

                            <SelectClassDepartment selected={dep} onChange={e => { setDep(e); setsemy(null) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="semesterYear"
                            label="Semester Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >

                            <SelectSemesterYearByClassDepartment selected={semy} onChange={e => { setsemy(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>

            {studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear?.length > 0 &&
                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear,
                            pagination: false,
                            bordered: true,
                            rowKey: "studentId",
                            rowSelection: rowSelection,
                        }}
                        mobileBreakPoint={768}
                    />
                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                        <Button type="primary" onClick={submitBatch} icon={<SettingOutlined />} >Batch Process</Button>
                    </Space>
                </div>
            }


            <Modal
                title="Student Waiver"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <Form.Item
                        name="feeHeadId"
                        label="Fee Head:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee head" },
                        ]}
                    >
                        <SelectFeeHead />
                    </Form.Item>

                    <Form.Item
                        name="waiverId"
                        label="Fee Waiver:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee waiver" },
                        ]}
                    >
                        <SelectFeeWaiver />
                    </Form.Item>

                    <Form.Item
                        name="amount"
                        label="Amount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please insert fee amount" },
                        ]}
                    >
                        <Input placeholder="Amount" />
                    </Form.Item>



                </Form>

            </Modal>
            <Modal
                title="Student Waiver"
                visible={isModalVisible2}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible2(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit2}
                    id="update"
                    form={updateForm2}
                >

                    <Form.Item
                        name="feeHeadId"
                        label="Fee Head:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee head" },
                        ]}
                    >
                        <SelectFeeHead />
                    </Form.Item>

                    <Form.Item
                        name="waiverId"
                        label="Fee Waiver:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee waiver" },
                        ]}
                    >
                        <SelectFeeWaiver />
                    </Form.Item>

                    <Form.Item
                        name="amount"
                        label="Amount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please insert fee amount" },
                        ]}
                    >
                        <Input placeholder="Amount" />
                    </Form.Item>



                </Form>

            </Modal>
        </>
    )
}
