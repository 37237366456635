
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';


export default function GoToInstitute(props) {


    const jumpToInstitute = useStoreActions((state) => state.auth.jumpToInstitute);

    const handleSubmit = (e) => {
        jumpToInstitute(e.instituteId);
    }

    return (
        <>
            <Card title="Go to Institute" >

                <div style={{ textAlign: 'center', width: "30%" }}>
                    <Form name="login" onFinish={handleSubmit}>
                        <Form.Item name='instituteId' rules={[{ required: true, message: 'Please input institute id' }]}>
                            <InputNumber
                                name="instituteId"
                                placeholder="Institute Id"
                            />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: "100px", float: "right" }}>
                            Go
                        </Button>
                    </Form>
                </div>



            </Card>
        </>
    )
}
