import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";


export default function StaffAssign(props) {


    const departmentTeacherAssignList = useStoreState((state) => state.staff.departmentTeacherAssignList);
    const fetchdepartmentTeacherAssignList = useStoreActions((state) => state.staff.fetchdepartmentTeacherAssignList);
    const deleteDepartmentTeacherAssign = useStoreActions((state) => state.staff.deleteDepartmentTeacherAssign);


    useEffect(() => {
        fetchdepartmentTeacherAssignList();
    }, []);




    const columns = [

        {
            title: 'Staff Id',
            dataIndex: 'staffCustomId',
            key: 'staffCustomId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Department Name',
            dataIndex: 'departmentName',
            key: 'departmentName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'departmentTeacherAssignId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteDepartmentTeacherAssign(record.departmentTeacherAssignId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        }

    ];



    return (
        < >
            <div className="datatable-responsive">
                <Table
                    antTableProps={{
                        showHeader: true,
                        columns,
                        dataSource: departmentTeacherAssignList,
                        pagination: false,
                        bordered: true,
                        rowKey: 'staffId'
                    }}
                    mobileBreakPoint={768}
                />
            </div>
        </>
    )
}
