import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Badge } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectDepartment } from '../../../select/SelectDepartment';
import Table from "../../../../contents/AntTableResponsive";
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';
import { studentListForMigration } from '../../../../http/student/student';

export default function StudentMigration(){
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const shiftList = useStoreState((state) => state.student.shiftList);
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const [selectedSessionYear, setselectedSessionYear] = useState();
    const [selectedClassDepartment, setSelectedClassDepartment] = useState();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    
    const fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration = useStoreActions((state) => state.student.fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration);
    const studentListByClassConfigIdSessionYearSemesterYearForMIgration = useStoreState((state) => state.student.studentListByClassConfigIdSessionYearSemesterYearForMIgration);
    const studentMigration = useStoreActions((state) => state.student.studentMigration);
    const [updateForm] = Form.useForm();
    useEffect(() => {
        fetchClassConfigList();
        fetchSessionList();
    },[]);

    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        getSemeterYear(value);
    } 

    const [val,seetVal] = useState([]);

    const migrateSubmitForm = (value) => {
        // setSelectedSemesterYear(value);
        let getData = {
            classDeptConfId: selectedClassDepartment,
            semesterYear: selectedSemesterYear,
            sessionId: selectedSessionYear,
        }
        seetVal(getData);
        fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration(getData);
        setselectedRowKeys([]);
    
    }

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const columns = [
        {
            title: 'Student ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },  
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },       
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Department',
            dataIndex: 'departmentName',
            key: 'departmentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Semester Year',
            dataIndex: 'sessionYearName',
            key: 'sessionYearName',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];

    const migratteStudent = (value) => {
        let selectedRows = [];
        selectedRowKeys.map((value, index) => {
            selectedRows.push(studentListByClassConfigIdSessionYearSemesterYearForMIgration[value].identificationId);
        });
        

        let postData = {
            "identificationIds": selectedRows,
            "sessionYearId": value.migrateSessionYear
        }

        studentMigration(postData);

        let getData = {
            classDeptConfId: selectedClassDepartment,
            semesterYear: selectedSemesterYear,
            sessionId: selectedSessionYear,
        }
        setIsModalVisible(false);
        setselectedRowKeys([]);
        updateForm.resetFields();
        fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration(getData);
        fetchStudentListByClassConfigIdSessionYearSemesterYearForMigration(val);
    } 
    const showModal = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setIsModalVisible(true)
        }else{
            notification.error({ message: 'Select the table row first' });
        }
    }

    return (
        <>
            <Card title="Student Migration">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 18, offset: 3}} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3}}>
                        <Form
                            layout="vertical"
                            id="migrationStudentListForm"
                            onFinish={migrateSubmitForm}
                        >
                            <Row>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 7}} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    <Form.Item
                                            name="classId"
                                            label="Session"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select session" },
                                            ]}
                                        >
                                        <Select 
                                            placeholder="Select session"
                                            onChange={(e) => setselectedSessionYear(e)}
                                        >
                                            {sessionList ? (
                                                sessionList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Session</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 7}} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    <Form.Item
                                            name="departmentId"
                                            label="Class Department"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select Class Department" },
                                            ]}
                                        >
                                        <Select 
                                            placeholder="Select Class Department"
                                            onChange={(e) => getSemesterYear(e)}
                                        >
                                            {classConfigList ? (
                                                classConfigList.map((type, idx) => (
                                                <Option key={type.configId} value={type.configId}>
                                                    {type.configName}
                                                </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 7}} lg={{ span: 7 }} xl={{ span: 7 }}>
                                    <Form.Item
                                            name="semesterYear"
                                            label="Semester Year"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select semester year" },
                                            ]}
                                        >
                                        <Select
                                            showSearch
                                            placeholder="Select semester year"
                                            optionFilterProp="children"
                                            onChange={(e) => setSelectedSemesterYear(e)}
                                        >
                                            {semesterYearByDepartmentId ? (
                                                semesterYearByDepartmentId.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 3}} lg={{ span: 3 }} xl={{ span: 3 }}>
                                    <Space size="small" >
                                        <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="m-t-mo-30" style={{ display: studentListByClassConfigIdSessionYearSemesterYearForMIgration.length > 0 ? 'block' : 'none' }}>
                    <Col span="24">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                rowSelection:rowSelection,
                                columns,
                                dataSource: studentListByClassConfigIdSessionYearSemesterYearForMIgration,
                                pagination: true,
                                bordered: true,
                                rowKey:"key",
                            }}
                            mobileBreakPoint={768}
                        />
                        <Button type="primary" icon={<SettingFilled />} style={{ float:'right' }} onClick={() => showModal(true)} className="m-t-mo-30">
                            Migration
                        </Button>
                    </Col>
                </Row>
                
                <Modal
                    title="Student Migration"
                    visible={isModalVisible}
                    //  onOk={handleOk}
                    okButtonProps={{ form: 'update', htmlType: 'submit' }}
                    onCancel={() => setIsModalVisible(false)}
                    cancelText="Close"
                    okText="Migrate"
                    centered
                >
                    <Form
                        layout="vertical"
                        id="update"
                        onFinish={migratteStudent}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                            <Form.Item
                                    name="migrateSessionYear"
                                    label="Session"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select session" },
                                    ]}
                                >
                                <Select 
                                    placeholder="Select session"
                                    // defaultValue={selectedClassDepartment}
                                >
                                    {semesterYearByDepartmentId ? (
                                        semesterYearByDepartmentId.map((type, idx) => (
                                        <Option key={type.id} value={type.id}>
                                            {type.name}
                                        </Option>
                                        ))
                                    ) : (
                                        <Option value="fetching">Fetching Class</Option>
                                    )}
                                </Select>
                            </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                 </Modal>
            </Card>
        </>
    )
}