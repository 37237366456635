import { Button, Card, Col, DatePicker, Form, Row, Space } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';

export default function Journal(props) {

    const journalStatement = useStoreState((state) => state.ledger.journalStatement);
    const check = useStoreState((state) => state.ledger.check);
    const fetchjournalStatement = useStoreActions((state) => state.ledger.fetchjournalStatement);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
    
        fetchjournalStatement(postData);
    };

    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        let datas = journalStatement;
        if (journalStatement?.length > 0) {
            for (var i = 0; i < datas.length; i++) {

                option = option + "<thead><tr><th colspan='3'>" + datas[i].voucherType + " Voucher, Vouche ID: " + datas[i].voucherId + ", Trn. Date: " + datas[i].trnDate + " </th></tr></thead>";
                option = option + "<thead><tr><th>Account Ledger</th><th>Debit</th><th>Credit</th></tr></thead>";

                var list = datas[i].list;

                for (var j = 0; j < list.length; j++) {
                    option = option + "<thead><tr><td>&nbsp;&nbsp;&nbsp;" + list[j].ledgerName + "</td><td style='text-align: right'>" + list[j].debit + "</td><td style='text-align: right'>" + list[j].credit + "</td></tr></thead>";

                }

                option = option + "</thead><tr><th style='text-align: right'><button style='background-color: #03D665; border: none;' onclick='downloadVoucher(" + datas[i].trnId + ")'>Download</button> Total</th><th style='text-align: right'>" + datas[i].totalDebit + "</th><th style='text-align: right'>" + datas[i].totalCredit + "</th></tr></thead>";

            }
            table.append(option);
        }
    }, [check])

useEffect(()=>{
    const script = document.createElement("script");
    script.src = "../../utils/download.js";
    script.async = true;
    document.body.appendChild(script);
},[])

    return (
        <>
            <Card title="Journal" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {journalStatement?.length > 0 &&
                    <>
                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                      
                    </>
                }
            </Card>
        </>
    )
}
