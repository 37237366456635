import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker } from 'antd';
import { EditOutlined, SaveOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import Table from "../../../../contents/AntTableResponsive";
import { tuple } from 'antd/lib/_util/type';

export default function ClassConfigUpdate(){

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const fetchStudentListByClassConfigId = useStoreActions((state) => state.student.fetchStudentListByClassDepartmentInfo);
    const studentListByClassConfigId = useStoreState((state) => state.student.studentListByClassDepartmentInfo);
    const updateStudentClassDepartmentInfo =  useStoreActions((state) => state.student.updateStudentClassDepartmentInfo);
    const [selectedClassDepartment, setSelectedClassDepartment] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);
    const [shiftForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    useEffect(() => {
        fetchSessionList();
        fetchClassConfigList();
    },[]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const  searchStudentList = (value) => {
        setSelectedClassDepartment(value.classDepartment);
        let payload = {
            classDeptConfId: value.classDepartment,
            sessionId: value.sessionYearName
        };
        fetchStudentListByClassConfigId(payload);
    }

    

    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true, 
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },       
        {
            title: 'Student Mobile',
            dataIndex: 'studentMobile',
            key: 'studentMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Registration No',
            dataIndex: 'registrationNo',
            key: 'registrationNo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Father Name',
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mother Name',
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Religion',
            dataIndex: 'religion',
            key: 'religion',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            showOnResponse: true,
            showOnDesktop: true,
        }
        
    ];

    const updateConfigurationForm = (value) => {
        console.log('selectedRowKeys', selectedRowKeys);
        let selectedRows = [];
        selectedRowKeys.map((value, index) => {
            selectedRows.push(studentListByClassConfigId[value].studentId);
        });
        
        let postData = {
            "classDeptConfigId": value.classDepartmentUpdate,
            "studentIds": selectedRows
          }
        updateStudentClassDepartmentInfo(postData);
        setIsModalVisible(false);
        setselectedRowKeys([]);
        setselectedValue([]);
        document.getElementById('searchButton').click();
    }

    const showModal = () => {
        let checkSelectedRow = selectedRowKeys.length;
        if (checkSelectedRow > 0) {
            setIsModalVisible(true)
        }else{
            notification.error({ message: 'Select the table row first' });
        }
    }



    return (
        <>
            <Card title="Student Class Department Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="sessionYearInfo"
                            onFinish={searchStudentList}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                            name="sessionYearName"
                                            label="Session Year"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please write session year" },
                                            ]}
                                        >
                                        <Select 
                                                placeholder="Select session"
                                            >
                                                {sessionList ? (
                                                    sessionList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Session</Option>
                                                )}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 10}} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                            name="classDepartment"
                                            label="Class Department"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Select class-department" },
                                            ]}
                                        >
                                            <Select 
                                                placeholder="Select class-department"
                                            >
                                                {classConfigList ? (
                                                    classConfigList.map((type, idx) => (
                                                    <Option key={type.configId} value={type.configId}>
                                                        {type.configName}
                                                    </Option>
                                                    ))
                                                ) : (
                                                    <Option value="fetching">Fetching Class</Option>
                                                )}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <div style={{ display:  studentListByClassConfigId.length > 0 ? 'block' : 'none' }}>
                    <Row className="m-t-mo-30">
                        <Col span="24">
                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    rowSelection:rowSelection,
                                    columns,
                                    dataSource: studentListByClassConfigId,
                                    pagination: true,
                                    bordered: true,
                                    rowKey:"key",
                                }}
                                mobileBreakPoint={768}
                                
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24">
                            <Button type="primary" icon={<SettingFilled />} style={{ float:'right' }} onClick={(e) => showModal(e)}>
                                Process
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="Update"
                    visible={isModalVisible}
                    //  onOk={handleOk}
                    okButtonProps={{ form: 'update', htmlType: 'submit' }}
                    onCancel={() => setIsModalVisible(false)}
                    cancelText="Close"
                    okText="Update"
                    centered
                >
                    <Form
                        layout="vertical"
                        id="update"
                        onFinish={updateConfigurationForm}
                        form={updateForm}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                        name="classDepartmentUpdate"
                                        label="Class Department"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Select Class Department" },
                                        ]}
                                    >
                                        <Select 
                                            placeholder="Select class-department"
                                            defaultValue={selectedClassDepartment}

                                        >
                                            {classConfigList ? (
                                                classConfigList.map((type, idx) => (
                                                <Option key={type.configId} value={type.configId}>
                                                    {type.configName}
                                                </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                 </Modal>    
            </Card>
        </>
    )
}