import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ClassDepartmentSemesterNo from './ClassDepartmentSemesterNo';
import ClassDepartmentSemesterYes from './ClassDepartmentSemesterYes';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';

    export default function ClassDepartmentSemester(){
        const { TabPane } = Tabs;
        const setReport = useStoreActions((state) => state.student.setReport);

        return(
            <>
                <Card title="Class Department Session">
                    <Tabs defaultActiveKey="1" onChange={()=>setReport()}>
                        <TabPane tab="Current" key="1">
                            <ClassDepartmentSemesterNo />
                        </TabPane>
                        <TabPane tab="Previous" key="2">
                            <ClassDepartmentSemesterYes />
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        )
    }