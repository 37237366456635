import { Button, Card, Col, DatePicker, Form, Row, Space } from 'antd'
import moment from 'moment';
import React from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf } from '../../../utils/pdf';
import { FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Ledger Name", dataKey: "ledgerName" },
        { title: "Debit Amount", dataKey: "debit" },
        { title: "Credit Amount", dataKey: "credit" },
    ];
};

var getColumnsTotal = function () {
    return [
        { title: "Total", dataKey: "total" },
        { title: "", dataKey: "totalDebit" },
        { title: "", dataKey: "totalCredit" },
    ];
};

var getColumnsGrand = function () {
    return [
        { title: "Grand Total", dataKey: "grandTotal" },
        { title: "", dataKey: "grandTotalDebit" },
        { title: "", dataKey: "grandTotalCredit" },
    ];
};
let fd = '';
let td = '';
export default function TrialBalance(props) {

    const trialBalance = useStoreState((state) => state.ledger.trialBalance);
    const check = useStoreState((state) => state.ledger.check);
    const fetchtrialBalance = useStoreActions((state) => state.ledger.fetchtrialBalance);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchtrialBalance(postData);
    };

    useEffect(() => {
        var table = $('#tbl')
        let option = "";
        let datas = trialBalance;
        if (trialBalance?.trialBalanceResponses?.length > 0) {
            option = option + "<thead><th>Ledger Name</th><th style='text-align: center'>Debit </th><th style='text-align: center'>Credit</th></tr></thead>";
            option = option + "<tbody>";

            var trialBalanceResponses = datas.trialBalanceResponses;

            for (var i = 0; i < trialBalanceResponses.length; i++) {

                option = option + "<tr><th colspan='3'>" + trialBalanceResponses[i].accountCategoryName + "</th></tr>";

                var details = trialBalanceResponses[i].trialBalanceDetails;

                for (var j = 0; j < details.length; j++) {
                    option = option + "<tr><td>&nbsp;&nbsp;&nbsp;" + details[j].ledgerName + "</td><td style='text-align: right'>" + details[j].debit + "</td><td style='text-align: right'>" + details[j].credit + "</td></tr>";

                }

                option = option + "<tr><th style='text-align: right'>Total</th><th style='text-align: right'>" + trialBalanceResponses[i].totalDebit + "</th><th style='text-align: right'>" + trialBalanceResponses[i].totalCredit + "</th></tr>";

            }

            option = option + "</tbody>";

            option = option + "<tfoot>";
            option = option + "<tr><th style='text-align: right'>Grand Total</th><th style='text-align: right'>" + datas.grandTotalDebit + "</th><th style='text-align: right'>" + datas.grandTotalCredit + "</th></tr>";
            option = option + "</tfoot>";
            table.append(option);
        }
    }, [check])

    function exportPdf() {
        let modifyList = trialBalance?.trialBalanceResponses.map(function (element) {
            let final = element.trialBalanceDetails.map(function (item, indx) {
                return {
                    accountCategoryName: element?.accountCategoryName,
                    totalDebit: element?.totalDebit,
                    totalCredit: element?.totalCredit,
                    trialBalanceDetails: element?.trialBalanceDetails,
                    len: element?.trialBalanceDetails.length,
                    ledgerName: item?.ledgerName,
                    debit: item?.debit,
                    credit: item?.credit,
                    indx: indx,
                    total: "Total",
                }
            })
            return final
        })
        let colList = trialBalance?.trialBalanceResponses.map(function (element) {
            return [{
                accountCategoryName: element?.accountCategoryName,
                len: element?.trialBalanceDetails.length,
                total: "Total",
                totalDebit: element?.totalDebit,
                totalCredit: element?.totalCredit,
            }]
        })
        let grandValue = [
            {
                grandTotalDebit: trialBalance?.grandTotalDebit,
                grandTotalCredit: trialBalance?.grandTotalCredit,
                grandTotal: "Grand Total",
            }
        ]

        var doc = new jsPDF("p", "mm", "a4");

        var details = `Trial balance from ${fd} to ${td} `;
        pdfDataL(doc, "");
        doc.text(details, 105, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = ppowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };
        for (let i = 0; i < modifyList.length; i++) {

            let first = doc.autoTable?.previous;
            doc.autoTable(getColumns(), modifyList[i], {
                startY: i == 0 ? 45 : first.finalY + 0,
                showHeader: i == 0 ? "firstPage" : "never",
                theme: 'grid',
                headerStyles: {
                    // fillColor: [105, 105, 105],
                    // textColor: [255, 255, 255],
                    // fontSize: 10
                    lineWidth: .01,
                    lineColor: [224, 224, 224]
                },
                columnStyles: {
                    ledgerName: {
                        halign: "left",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    debit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    credit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },
                drawRow: function (row, data) {
                    if (row.index === 0) {
                        doc.setFontSize(10);
                        doc.rect(data.settings.margin.left, row.y, data.table.width, 7, "S");
                        doc.autoTableText(" " + modifyList[i][0].accountCategoryName, data.settings.margin.left, (row.y + row.height / 2) - 1,
                            {
                                halign: "left",
                                valign: "top",
                                fontSize: 10
                            }
                        );
                        data.cursor.y += 7;
                    }
                },
                // addPageContent: pageContent,
            });
            doc.autoTable(getColumnsTotal(), colList[i], {
                startY: doc.autoTable.previous.finalY + 0,
                showHeader: "never",
                theme: 'grid',

                columnStyles: {
                    total: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 75
                    },
                    totalDebit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    },
                    totalCredit: {
                        halign: "right",
                        fontSize: 8,
                        columnWidth: 53.4
                    }
                },

                addPageContent: pageContent,
            });
            // doc.text("hi", 20, doc.autoTable.previous.finalY + 5);
        }
        doc.autoTable(getColumnsGrand(), grandValue, {
            startY: doc.autoTable.previous.finalY + 0,
            showHeader: "never",
            theme: 'grid',

            columnStyles: {
                grandTotal: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 75
                },
                grandTotalDebit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                grandTotalCredit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },

            // addPageContent: pageContent,
        });
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    return (
        <>
            <Card title="Trial Balance" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}> </Col>
                    </Row>
                </Form>
                {trialBalance?.trialBalanceResponses?.length > 0 &&
                    <>
                        <div className="table-responsive">
                            <table id="tbl" className="table table-bordered custom-table table-striped text-left">
                            </table>
                        </div>
                        <Button type="primary" onClick={exportPdf} icon={<FilePdfOutlined />} style={{ float: "right" }}>Download PDF</Button>
                    </>
                }
            </Card>
        </>
    )
}
