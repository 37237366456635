import { Divider, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { AuditOutlined, CalculatorOutlined, ContainerOutlined, DashboardTwoTone, DeleteOutlined, DollarCircleOutlined, ExpandOutlined, FileTextOutlined, FundProjectionScreenOutlined, FundViewOutlined, PartitionOutlined, SolutionOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons/lib';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { ROUTES } from "../../contents/routes";


export function Sidebar() {
	// console.log(window.location.pathname)
	const fetchpartnerProfile = useStoreActions(state => state.common.fetchpartnerProfile);
	const instituteInfo = useStoreState(state => state.auth.instituteInfo)
	const [openkeys, setopenkeys] = useState<any>('')
	useEffect(() => {
		//fetchpartnerProfile();
		setpathValue(window.location.pathname.slice(1));
		if (window.location.pathname.slice(1) === '') {
			setpathValue('dashboard')
		}
		let temp = window.location.pathname.substr(window.location.pathname.lastIndexOf("-") + 1).toString();
		// console.log(temp)
		setopenkeys(temp)
	}, []);

	const [pathValue, setpathValue] = useState<any>("1")
	const [mainpathValue, setmainpathValue] = useState<any>(null)

	// console.log(pathValue)

	//const key = window.location.pathname === '/' ? 'home' : window.location.pathname.slice(1, location.pathname.length)
	//console.log(window.location.pathname)

	const sideClick = (value) => {
		setpathValue(value.key)
		setKeys(value.keyPath)
		localStorage.setItem('shebaUni', JSON.stringify(value.keyPath));
		localStorage.setItem('shebaUniKey', (value.key));
	}
	useEffect(() => {
		let val: any = localStorage.getItem('shebaUni');
		let val2: any = localStorage.getItem('shebaUniKey');
		if (val !== null) {
			setRefresh(JSON.parse(val))
			setKeys(JSON.parse(val))
		}
		if (val2 !== null) {
			setpathValue(val2)
		}
		setView(true)
		//	console.log('here')
	}, []);

	const [refreshKeys, setRefresh] = useState<any>([]);
	const [keys, setKeys] = useState<any>([]);
	const [view, setView] = useState<any>(false);



	const onOpenChange = (value) => {
		setKeys(value)
	}

	return <> {view && <Menu theme="light" defaultSelectedKeys={[pathValue]} mode="inline" selectedKeys={[pathValue]} onClick={sideClick} openKeys={keys} defaultOpenKeys={refreshKeys} onOpenChange={onOpenChange} >

		<Menu.Item key="1" icon={<DashboardTwoTone />}>
			<Link to={ROUTES.DEFAULT} className="nav-text">Dashboard</Link>
		</Menu.Item>

		<SubMenu key={"studentsMenu"} icon={<PartitionOutlined />} title="Students" >
			<Menu.Item key={ROUTES.STUDENT_WRAPPER} icon={<FileTextOutlined />}>
				<Link to={ROUTES.STUDENT_WRAPPER} className="nav-text">Settings</Link>
			</Menu.Item>

			<SubMenu key={"studentsConfigMenu"} icon={<PartitionOutlined />} title="Configuration" >
				<Menu.Item key={ROUTES.STUDENT_CLASS_CONFIG} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_CLASS_CONFIG} className="nav-text">Class Department</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.CLASS_SEMESTER_CONFIG} icon={<FileTextOutlined />}>
					<Link to={ROUTES.CLASS_SEMESTER_CONFIG} className="nav-text">Class Semester Year</Link>
				</Menu.Item>
			</SubMenu>
			<SubMenu key={"studentsRegistrationMenu"} icon={<PartitionOutlined />} title="Registration" >
				<Menu.Item key={ROUTES.STUDENT_MULTIPLE_REGISTRATION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_MULTIPLE_REGISTRATION} className="nav-text">General Registration</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_MULTIPLE_REGISTRATION_EXCEL} className="nav-text">Excel Registration</Link>
				</Menu.Item>
			</SubMenu>
			<SubMenu key={"studentsUpdateMenu"} icon={<PartitionOutlined />} title="Update" >
				<Menu.Item key={ROUTES.STUDENT_BASIC_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_BASIC_UPDATE} className="nav-text">Basic Info</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_ADDRESS_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_ADDRESS_UPDATE} className="nav-text">Address Info</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_CLASS_SESSION_YEAR} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_CLASS_SESSION_YEAR} className="nav-text">Session</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_CLASS_CONFIG_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_CLASS_CONFIG_UPDATE} className="nav-text">Class Department</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_SHIFT_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_SHIFT_UPDATE} className="nav-text">Shift</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_OTHER_INFO_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_OTHER_INFO_UPDATE} className="nav-text">Other Info</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_CLASS_TYPE_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_CLASS_TYPE_UPDATE} className="nav-text">Class Type</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_MIGRATION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_MIGRATION} className="nav-text">Migration</Link>
				</Menu.Item>
			</SubMenu>
			<SubMenu key={"studentsReport"} icon={<PartitionOutlined />} title="Report" >
				<Menu.Item key={ROUTES.STUDENT_REPORT_CLASS_DEPARTMENT_SESSION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_REPORT_CLASS_DEPARTMENT_SESSION} className="nav-text">Session</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_REPORT_CLASS_DEPARTMENT_SEMESTER} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_REPORT_CLASS_DEPARTMENT_SEMESTER} className="nav-text">Semester</Link>
				</Menu.Item>
			</SubMenu>
		</SubMenu>

		<SubMenu key={"staff"} icon={<PartitionOutlined />} title="HRM" >

			<Menu.Item key={ROUTES.STAFF_SETTINGS} icon={<FileTextOutlined />}>
				<Link to={ROUTES.STAFF_SETTINGS} className="nav-text">Settings</Link>
			</Menu.Item>
			{/* <Menu.Item key={ROUTES.FEE_HEAD_WAIVER} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_HEAD_WAIVER} className="nav-text">Fee Waiver</Link>
				</Menu.Item> */}

			<SubMenu key={"staff-registration"} icon={<PartitionOutlined />} title="Registration" >
				<Menu.Item key={ROUTES.STAFF_SINGLE_REGISTRATION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STAFF_SINGLE_REGISTRATION} className="nav-text">Single</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STAFF_MULTIPLE_REGISTRATION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STAFF_MULTIPLE_REGISTRATION} className="nav-text">Excel</Link>
				</Menu.Item>
				{/* <Menu.Item key={ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} className="nav-text">Fee Fine Ledger </Link>
				</Menu.Item> */}
			</SubMenu>
			{((instituteInfo?.roleList?.includes('ROLE_ADMIN')) || (instituteInfo?.roleList?.includes('ROLE_OPERATOR'))) &&
				<SubMenu key={"staffAssign"} icon={<PartitionOutlined />} title="Assign" >
					<Menu.Item key={ROUTES.STAFF_ASSIGN} icon={<FileTextOutlined />}>
						<Link to={ROUTES.STAFF_ASSIGN} className="nav-text">Teacher Assign</Link>
					</Menu.Item>
				</SubMenu>
			}
			<SubMenu key={"staff-update"} icon={<PartitionOutlined />} title="Update" >
				<Menu.Item key={ROUTES.STAFF_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STAFF_UPDATE} className="nav-text">Update</Link>
				</Menu.Item>

			</SubMenu>
		</SubMenu>
		<SubMenu key={"generalAccounts"} icon={<PartitionOutlined />} title="General Accounts" >
			<Menu.Item key={ROUTES.LEDGER_LIST} icon={<FileTextOutlined />}>
				<Link to={ROUTES.LEDGER_LIST} className="nav-text">Ledger List</Link>
			</Menu.Item>


			<>
				<SubMenu key={"transaction"} icon={<PartitionOutlined />} title="Transaction" >
					<Menu.Item key={ROUTES.RECEIVE_TRN_TRANSACTION} icon={<FileTextOutlined />}>
						<Link to={ROUTES.RECEIVE_TRN_TRANSACTION} className="nav-text">Receive </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.PAYMENT_TRN_TRANSACTION} icon={<FileTextOutlined />}>
						<Link to={ROUTES.PAYMENT_TRN_TRANSACTION} className="nav-text">Payment </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.CONTRA_TRN_TRANSACTION} icon={<FileTextOutlined />}>
						<Link to={ROUTES.CONTRA_TRN_TRANSACTION} className="nav-text">Contra </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.JOURNAL_TRN_TRANSACTION} icon={<FileTextOutlined />}>
						<Link to={ROUTES.JOURNAL_TRN_TRANSACTION} className="nav-text">Journal </Link>
					</Menu.Item>
				</SubMenu>
			</>
			<>
				<SubMenu key={"gavoucher"} icon={<PartitionOutlined />} title="Voucher" >
					<Menu.Item key={ROUTES.DELETE_VOUCHER_GAVOUCHER} icon={<DeleteOutlined />}>
						<Link to={ROUTES.DELETE_VOUCHER_GAVOUCHER} className="nav-text">Delete </Link>
					</Menu.Item>
				</SubMenu>
			</>
			<>
				<SubMenu key={"gareport"} icon={<AuditOutlined />} title="Report" >
					<Menu.Item key={ROUTES.TRIAL_BALANCE_GAREPORT} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.TRIAL_BALANCE_GAREPORT} className="nav-text">Trial Balance </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.BALANCE_SHEET_GAREPORT} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.BALANCE_SHEET_GAREPORT} className="nav-text">Balance Sheet </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.INCOME_STATEMENT_GAREPORT} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.INCOME_STATEMENT_GAREPORT} className="nav-text">Income Statement </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.JOURNAL_GAREPORT} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.JOURNAL_GAREPORT} className="nav-text">Journal </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.FUNDS_FLOW_GAREPORT} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.FUNDS_FLOW_GAREPORT} className="nav-text">Funds Flow </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.CASH_SUMMARY_GAREPORT} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.CASH_SUMMARY_GAREPORT} className="nav-text">Cash Summary </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.ACCOUNT_BOOK} icon={< CalculatorOutlined />}>
						<Link to={ROUTES.ACCOUNT_BOOK} className="nav-text">Accounts Book </Link>
					</Menu.Item>
				</SubMenu>
			</>
		</SubMenu>

		<SubMenu key={"paySlip"} icon={<PartitionOutlined />} title="Payslip" >

			<Menu.Item key={ROUTES.PAY_SLIP_CREATE} icon={<FileTextOutlined />}>
				<Link to={ROUTES.PAY_SLIP_CREATE} className="nav-text">Create</Link>
			</Menu.Item>
			<SubMenu key={"payslipupdate"} icon={<PartitionOutlined />} title="Update" >
				<Menu.Item key={ROUTES.PAYSLIP_DATE_UPDATE} icon={<FileTextOutlined />}>
					<Link to={ROUTES.PAYSLIP_DATE_UPDATE} className="nav-text">Date</Link>
				</Menu.Item>
			</SubMenu>
			<Menu.Item key={ROUTES.PAY_SLIP_COLLECT} icon={<FileTextOutlined />}>
				<Link to={ROUTES.PAY_SLIP_COLLECT} className="nav-text">Collect</Link>
			</Menu.Item>
			<Menu.Item key={ROUTES.PAY_SLIP_TEMPLATE} icon={<FileTextOutlined />}>
				<Link to={ROUTES.PAY_SLIP_TEMPLATE} className="nav-text">Template</Link>
			</Menu.Item>
		</SubMenu>

		<SubMenu key={"studentAccountts"} icon={<PartitionOutlined />} title="Student Accounts" >
			<SubMenu key={"settings"} icon={<PartitionOutlined />} title="Settings" >
				<Menu.Item key={ROUTES.FEE_HEAD} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_HEAD} className="nav-text">Fee Head</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.FEE_HEAD_WAIVER} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_HEAD_WAIVER} className="nav-text">Fee Waiver</Link>
				</Menu.Item>
			</SubMenu>
			<SubMenu key={"ledger-configuration"} icon={<PartitionOutlined />} title="Configuration" >
				<Menu.Item key={ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_HEAD_LEDGER_CONFIGUARTION} className="nav-text">Fee Head Ledger </Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_FINE_LEDGER_CONFIGUARTION} className="nav-text">Fee Fine Ledger </Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.FEE_AMOUNT_CONFIGUARTION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_AMOUNT_CONFIGUARTION} className="nav-text">Fee Amount </Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.OFPS_ACCOUNT_CONFIG} icon={<FileTextOutlined />}>
					<Link to={ROUTES.OFPS_ACCOUNT_CONFIG} className="nav-text">OFPS</Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.FEE_WAIVE_CONFIGUARTION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_WAIVE_CONFIGUARTION} className="nav-text">Fee Waiver </Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.FEE_HEAD_DELETE_CONFIGUARTION} icon={<FileTextOutlined />}>
					<Link to={ROUTES.FEE_HEAD_DELETE_CONFIGUARTION} className="nav-text">Fee Head Delete </Link>
				</Menu.Item>
			</SubMenu>
			<Menu.Item key={ROUTES.VOUCHER_DELETE} icon={<PartitionOutlined />}>
				<Link to={ROUTES.VOUCHER_DELETE} className="nav-text">Feeinvoice Delete </Link>
			</Menu.Item>
			<Menu.Item key={ROUTES.FEE_COLLECTION_MANUAL} icon={<PartitionOutlined />}>
				<Link to={ROUTES.FEE_COLLECTION_MANUAL} className="nav-text">Fees Collection </Link>
			</Menu.Item>
			<SubMenu key={"saReport"} icon={<PartitionOutlined />} title="Report" >
				<Menu.Item key={ROUTES.HEAD_COLLECTION_REPORT} icon={<FileTextOutlined />}>
					<Link to={ROUTES.HEAD_COLLECTION_REPORT} className="nav-text">Head Collection </Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.STUDENT_FEE_DUE_REPORT} icon={<FileTextOutlined />}>
					<Link to={ROUTES.STUDENT_FEE_DUE_REPORT} className="nav-text">Student Due </Link>
				</Menu.Item>
				<SubMenu key={"paidReport"} icon={<PartitionOutlined />} title="Paid" >
					<Menu.Item key={ROUTES.CLASS_DEPARTMENT_PAID_REPORT} icon={<FileTextOutlined />}>
						<Link to={ROUTES.CLASS_DEPARTMENT_PAID_REPORT} className="nav-text">Class Deparment </Link>
					</Menu.Item>					
					<Menu.Item key={ROUTES.SEMESTER_YEAR_PAID_REPORT} icon={<FileTextOutlined />}>
						<Link to={ROUTES.SEMESTER_YEAR_PAID_REPORT} className="nav-text">Semester Year</Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.LEDGER_BY_PAID_REPORT} icon={<FileTextOutlined />}>
						<Link to={ROUTES.LEDGER_BY_PAID_REPORT} className="nav-text">Ledger By </Link>
					</Menu.Item>
				</SubMenu>
				{instituteInfo?.roleList?.includes('ROLE_ADMIN') &&
					<Menu.Item key={ROUTES.SPG_STATEMENT} icon={<FileTextOutlined />}>
						<Link to={ROUTES.SPG_STATEMENT} className="nav-text">SPG Statement</Link>
					</Menu.Item>
				}
			</SubMenu>
		</SubMenu>

		<SubMenu key={"smsSendMenu"} icon={<PartitionOutlined />} title="SMS" >

			<SubMenu key={"smsSend"} icon={<PartitionOutlined />} title="Send" >
				<Menu.Item key={ROUTES.SMS_SEND_STUDENT} icon={<FileTextOutlined />}>
					<Link to={ROUTES.SMS_SEND_STUDENT} className="nav-text">Student </Link>
				</Menu.Item>
				<Menu.Item key={ROUTES.SMS_SEND_STAFF} icon={<FileTextOutlined />}>
					<Link to={ROUTES.SMS_SEND_STAFF} className="nav-text">Staff </Link>
				</Menu.Item>
			</SubMenu>
			<Menu.Item key={ROUTES.SMS_PURCHASE} icon={<FileTextOutlined />}>
				<Link to={ROUTES.SMS_PURCHASE} className="nav-text">SMS Purchase </Link>
			</Menu.Item>
		</SubMenu>

		<Menu.Item key={ROUTES.INSTITUTE_SETTINGS} icon={<PartitionOutlined />}>
			<Link to={ROUTES.INSTITUTE_SETTINGS} className="nav-text">Institute</Link>
		</Menu.Item>
		{instituteInfo?.roleList?.includes('ROLE_ADMIN') &&
			<Menu.Item key={ROUTES.USER_LIST} icon={<UserOutlined />}>
				<Link to={ROUTES.USER_LIST} className="nav-text">Users</Link>
			</Menu.Item>
		}

		{instituteInfo?.roleList?.includes('ROLE_ADMIN') &&
			<>
				<SubMenu key={"mastersettings"} icon={<PartitionOutlined />} title="Bill" >
					<Menu.Item key={ROUTES.PAYABLE_BILL_LIST} icon={<UserOutlined />}>
						<Link to={ROUTES.PAYABLE_BILL_LIST} className="nav-text">Payable Bill</Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.PAID_BILL_LIST} icon={<UserOutlined />}>
						<Link to={ROUTES.PAID_BILL_LIST} className="nav-text">Paid Bill</Link>
					</Menu.Item>
				</SubMenu>
			</>
		}

		{instituteInfo?.roleList?.includes('ROLE_CUSTOMER_SUPPORT') &&
			<>
				<hr style={{ width: "80%", marginTop: 30 }} />
				{/* <Divider style={{color:'white'}}> <hr  /></Divider> */}
				<SubMenu key={"customerSupport"} icon={<ExpandOutlined />} title="Customer Support" >
					<Menu.Item key={ROUTES.INSTITUTE_GO} icon={<FileTextOutlined />}>
						<Link to={ROUTES.INSTITUTE_GO} className="nav-text">Go to Institute </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.INSTITUTE_LIST} icon={<FileTextOutlined />}>
						<Link to={ROUTES.INSTITUTE_LIST} className="nav-text">Institute List </Link>
					</Menu.Item>
					<Menu.Item key={ROUTES.INSTITUTE_STUDENT_COUNT} icon={<UserOutlined />}>
						<Link to={ROUTES.INSTITUTE_STUDENT_COUNT} className="nav-text">Student Count</Link>
					</Menu.Item>
					<SubMenu key={"customerSupportOFPS"} icon={<PartitionOutlined />} title="OFPS Probelm" >
						<Menu.Item key={ROUTES.OFPS_PROBLEM} icon={<UserOutlined />}>
							<Link to={ROUTES.OFPS_PROBLEM} className="nav-text">View</Link>
						</Menu.Item>
					</SubMenu>
				</SubMenu>

			</>
		}
	</Menu>
	}
	</>
}
