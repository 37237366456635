import { DeleteOutlined, EditOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
const { TextArea } = Input;
const SMSHelper = require('smshelper')

export default function SmsSendStaff(props) {

    const staffBasicInfoList = useStoreState((state) => state.staff.staffBasicInfoList);
    const fetchstaffBasicInfoList = useStoreActions((state) => state.staff.fetchstaffBasicInfoList);
    const sendSmsToStaff = useStoreActions((state) => state.common.sendSmsToStaff);

    useEffect(() => {
        fetchstaffBasicInfoList();
    }, [])


    const columns = [

        {
            title: 'Serial',
            dataIndex: 'staffSerial',
            key: 'staffSerial',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designationName',
            key: 'designationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Father's Name ",
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Mother's Name",
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true
        },


    ];

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [form] = Form.useForm();

    const formSubmit = (value) => {
        let payload = {
            message: value.message,
            staffIds: selectedValue.map((item) => item.staffId),
        }
        sendSmsToStaff(payload);
        setIsModalVisible(false);
        setMessage('');
        setselectedRowKeys([]);
        setselectedValue([]);
    }


    const openModal = () => {

        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }

        setIsModalVisible(true)
    }
    const [message, setMessage] = useState<any>('');


    return (
        <>
            <Card title="SMS" >


                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: staffBasicInfoList,
                            pagination: false,
                            bordered: true,
                            rowKey: 'staffId',
                            rowSelection: rowSelection,

                        }}
                        mobileBreakPoint={768}
                    />
                    <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                        <Button type="primary" onClick={openModal} icon={<SendOutlined />} >Send SMS</Button>

                    </Space>
                </div>

            </Card>

            <Modal
                title="SMS"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Send"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="update"
                    form={form}

                >

                    <Form.Item
                        name="message"
                        label="Message:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please write your message" },
                        ]}
                    >
                        <TextArea placeholder="Enter Message" style={{ width: "100%", height: 100 }} value={message} onChange={e => setMessage(e.target.value)} />

                    </Form.Item>
                </Form>
                <div style={{ marginBottom: -15 }}>
                    {message.length > 0 &&
                        <>
                            SMS Count: {SMSHelper.parts(message)}
                        </>
                    }
                </div>

            </Modal>


        </>
    )
}
