import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { fetchDistrictList, fetchThanaList, fetchpartnerProfile, fetchclassList, fetchdepartmentList, fetchfeeHeadList, fetchsessionYearList, fetchdesignationList, fetchsessionList, fetchsessionYearListByClassId, fetchdepartmentListByClassId, fetchsessionYearListByClassDeptConfigId, fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear, sendSmsToClassStudent, sendSmsToStaff, fetchinstituteList, solveOfpsTransactionProblem, fetchofpsTransactionProblemCheck, fetchofpsTransactionProblemView, smsBlanceurl, fetchStudentCount } from '../../../http/common/common';

export interface Common {
	districtList: any,
	thanaList: any,
	setdistrictList: Action<Common, any>;
	setthanaList: Action<Common, any>;
	districtListFetch: Thunk<Common>;
	thanaListFetch: Thunk<Common>;

	partnerProfile: any;
	setpartnerProfile: Action<Common, any>;
	fetchpartnerProfile: Thunk<Common>

	classList: any;
	setclassList: Action<Common, any>;
	fetchclassList: Thunk<Common>

	designationList: any;
	setdesignationList: Action<Common, any>;
	fetchdesignationList: Thunk<Common>

	departmentList: any;
	setdepartmentList: Action<Common, any>;
	fetchdepartmentList: Thunk<Common>

	feeHeadList: any;
	setfeeHeadList: Action<Common, any>;
	fetchfeeHeadList: Thunk<Common>

	sessionYearList: any;
	setsessionYearList: Action<Common, any>;
	fetchsessionYearList: Thunk<Common>

	sessionList: any;
	setsessionList: Action<Common, any>;
	fetchsessionList: Thunk<Common>

	instituteList: any;
	setinstituteList: Action<Common, any>;
	fetchinstituteList: Thunk<Common>

	sessionYearListByClassId: any;
	setsessionYearListByClassId: Action<Common, any>;
	fetchsessionYearListByClassId: Thunk<Common, any>

	departmentListByClassId: any;
	setdepartmentListByClassId: Action<Common, any>;
	fetchdepartmentListByClassId: Thunk<Common, any>

	sessionYearListByClassDeptConfigId: any;
	setsessionYearListByClassDeptConfigId: Action<Common, any>;
	fetchsessionYearListByClassDeptConfigId: Thunk<Common, any>

	studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear: any;
	setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear: Action<Common, any>;
	fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear: Thunk<Common, any>
	sendSmsToClassStudent: Thunk<Common, any>
	sendSmsToStaff: Thunk<Common, any>

	ofpsTransactionProblemView: any;
	setofpsTransactionProblemView: Action<Common, any>;
	fetchofpsTransactionProblemView: Thunk<Common, any>;

	ofpsTransactionProblemCheck: any;
	setofpsTransactionProblemCheck: Action<Common, any>;
	fetchofpsTransactionProblemCheck: Thunk<Common, any>;

	solveOfpsTransactionProblem: Thunk<Common, any>;
	isofps: boolean;
	setisofps: Action<Common, any>;

	/******/
	fetchSmsBlance: Thunk<Common>;
	setSmsBlance: Action<Common, any>;
	smsBlance: any;
	/*******/

	fetchStudentCount: Thunk<Common>;
    setstudentCount: Action<Common, any>;
    studentCount: any;

	
    fetchMasterSettingPayabledList: Thunk<Common>;
    setMasterSettingPayabledList: Action<Common, any>;
    masterSettingPayabledList: any;

    fetchMasterSettingPaidList: Thunk<Common>;
    setMasterSettingPaidList: Action<Common, any>;
    masterSettingPaidList: any;

	loading: boolean;
    setLoading: Action<Common, boolean>;
}

export const commonStore: Common = {
    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),
	districtList: null,
	thanaList: null,
	partnerProfile: null,
	districtListFetch: thunk(async (actions) => {
		const response = await fetchDistrictList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdistrictList(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	thanaListFetch: thunk(async (actions, id) => {
		const response = await fetchThanaList(id);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log('hello',body)
			actions.setthanaList(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	setdistrictList: action((state, payload) => {
		state.districtList = payload;
	}),
	setthanaList: action((state, payload) => {
		state.thanaList = payload;
	}),

	fetchpartnerProfile: thunk(async (actions) => {
		const response = await fetchpartnerProfile();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setpartnerProfile(body?.item);
		} else {
			//const body = await response.json();
			//actions.loginFailed("Invalid Username/Password");
		}
	}),
	setpartnerProfile: action((state, payload) => {
		state.partnerProfile = payload;
	}),

	classList: null,
	fetchclassList: thunk(async (actions) => {
		const response = await fetchclassList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setclassList(body?.item);
		} else {

		}
	}),

	setclassList: action((state, payload) => {
		state.classList = payload;
	}),

	designationList: null,
	fetchdesignationList: thunk(async (actions) => {
		const response = await fetchdesignationList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdesignationList(body?.item);
		} else {

		}
	}),

	setdesignationList: action((state, payload) => {
		state.designationList = payload;
	}),


	departmentList: null,
	fetchdepartmentList: thunk(async (actions) => {
		const response = await fetchdepartmentList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdepartmentList(body?.item);
		} else {

		}
	}),
	setdepartmentList: action((state, payload) => {
		state.departmentList = payload;
	}),


	feeHeadList: null,
	fetchfeeHeadList: thunk(async (actions) => {
		const response = await fetchfeeHeadList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setfeeHeadList(body?.item);
		} else {

		}
	}),

	setfeeHeadList: action((state, payload) => {
		state.feeHeadList = payload;
	}),

	sessionYearList: null,
	fetchsessionYearList: thunk(async (actions) => {
		const response = await fetchsessionYearList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setsessionYearList(body?.item);
		} else {

		}
	}),

	setsessionYearList: action((state, payload) => {
		state.sessionYearList = payload;
	}),

	///
	sessionList: null,
	fetchsessionList: thunk(async (actions) => {
		const response = await fetchsessionList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setsessionList(body?.item);
		} else {

		}
	}),
	setsessionList: action((state, payload) => {
		state.sessionList = payload;
	}),

	instituteList: null,
	fetchinstituteList: thunk(async (actions) => {
		const response = await fetchinstituteList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setinstituteList(body?.item);
		} else {

		}
	}),
	setinstituteList: action((state, payload) => {
		state.instituteList = payload;
	}),

	//
	sessionYearListByClassId: null,
	fetchsessionYearListByClassId: thunk(async (actions, payload) => {
		const response = await fetchsessionYearListByClassId(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setsessionYearListByClassId(body?.item);
		} else {

		}
	}),
	setsessionYearListByClassId: action((state, payload) => {
		state.sessionYearListByClassId = payload;
	}),

	//
	departmentListByClassId: null,
	fetchdepartmentListByClassId: thunk(async (actions, payload) => {
		const response = await fetchdepartmentListByClassId(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setdepartmentListByClassId(body?.item);
		} else {

		}
	}),
	setdepartmentListByClassId: action((state, payload) => {
		state.departmentListByClassId = payload;
	}),

	//
	sessionYearListByClassDeptConfigId: null,
	fetchsessionYearListByClassDeptConfigId: thunk(async (actions, payload) => {
		const response = await fetchsessionYearListByClassDeptConfigId(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setsessionYearListByClassDeptConfigId(body?.item);
		} else {

		}
	}),
	setsessionYearListByClassDeptConfigId: action((state, payload) => {
		state.sessionYearListByClassDeptConfigId = payload;
	}),

	//
	studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear: null,
	fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear: thunk(async (actions, payload) => {
		const response = await fetchstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear(body?.item);
		} else {

		}
	}),
	setstudentBasicDetailsInfosBySesssionAndClassDepartSemesterYear: action((state, payload) => {
		state.studentBasicDetailsInfosBySesssionAndClassDepartSemesterYear = payload;
	}),

	sendSmsToClassStudent: thunk(async (actions, payload) => {
		const response = await sendSmsToClassStudent(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification['success']({
					message: 'SMS',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'SMS',
					description: body?.message,
				});
			}
		} else {
			notification['error']({
				message: 'SMS',
				description: "Something went wrong",
			});
		}
	}),

	sendSmsToStaff: thunk(async (actions, payload) => {
		const response = await sendSmsToStaff(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification['success']({
					message: 'SMS',
					description: body?.message,
				});
			} else {
				notification['error']({
					message: 'SMS',
					description: body?.message,
				});
			}
		} else {
			notification['error']({
				message: 'SMS',
				description: "Something went wrong",
			});
		}
	}),

	ofpsTransactionProblemView: [],
	fetchofpsTransactionProblemView: thunk(async (actions, payload) => {
		const response = await fetchofpsTransactionProblemView(payload);
		if (response.status === 200) {
			const body: any = await response.json();
			if (body.item?.length > 0) {
				actions.setofpsTransactionProblemView(body.item);
			} else {
				actions.setofpsTransactionProblemView([]);
				notification.warn({ message: "No data found" })

			}
		} else {
			//const body = await response.json();
			actions.setofpsTransactionProblemView([]);
		}
	}),
	setofpsTransactionProblemView: action((state, payload) => {
		state.ofpsTransactionProblemView = payload;
	}),
	isofps: false,

	setisofps: action((state, payload) => {
		state.isofps = payload;
	}),

	ofpsTransactionProblemCheck: {},
	fetchofpsTransactionProblemCheck: thunk(async (actions, payload) => {
		const response = await fetchofpsTransactionProblemCheck(payload);
		if (response.status === 200) {
			const body: any = await response.json();
			if (body.messageType == 1) {
				actions.setofpsTransactionProblemCheck(body.item);
				actions.setisofps(true);
				setTimeout(() => {
					actions.setisofps(false);
				}, 1000);
			} else {
				actions.setisofps(false);
				notification.warn({ message: body.message })
				actions.setofpsTransactionProblemCheck({});
			}
		} else {
			//const body = await response.json();
			actions.setisofps(false);
			actions.setofpsTransactionProblemCheck({});
		}
	}),
	setofpsTransactionProblemCheck: action((state, payload) => {
		state.ofpsTransactionProblemCheck = payload;
	}),
	solveOfpsTransactionProblem: thunk(async (actions, payload) => {
		const response = await solveOfpsTransactionProblem(payload);
		if (response.status === 201 || response.status === 200) {
			const body: any = await response.json();
			if (body.messageType == 1) {
				notification.success({ message: body.message })
			} else {
				notification.error({ message: body.message })
			}
		} else {
			//const body = await response.json();
			notification.error({ message: "Something went wrong" })
		}
	}),

	smsBlance: null,
	fetchSmsBlance: thunk(async (actions, payload) => {
		const response = await smsBlanceurl(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setSmsBlance(body.item);

		} else {
			const body = await response.json();
			actions.setSmsBlance(null);
		}
	}),
	setSmsBlance: action((state, payload) => {
		state.smsBlance = payload;
	}),
    studentCount: [],
	setstudentCount: action((state, payload) => {
        state.studentCount = payload;
    }),
	fetchStudentCount: thunk(async (actions) => {
        const response = await fetchStudentCount();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setstudentCount(body.item);
        } else {
            const body = await response.json();
            actions.setstudentCount([]);
            notification.error({ message: body.message })
        }
    }),

	masterSettingPayabledList: [],
	fetchMasterSettingPayabledList: thunk(async (actions, payload) => {
        const BASE_URL_SUITE = process.env.REACT_APP_COMMUNITY_BASE_URL
        fetch(BASE_URL_SUITE + '/public/product/bill-generator/unpaid/list?clientProvideId=' + payload + '&productCustomId=102')
            .then(response => response.json())
            .then(body =>
                actions.setMasterSettingPayabledList(body?.item)
            );
    }),

    setMasterSettingPayabledList: action((state, payload) => {
        state.masterSettingPayabledList = payload;
    }),

	masterSettingPaidList: [],
    fetchMasterSettingPaidList: thunk(async (actions, payload: any) => {
        const BASE_URL_SUITE = process.env.REACT_APP_COMMUNITY_BASE_URL
        fetch(BASE_URL_SUITE + '/public/product/bill-generator/paid/list?clientProvideId=' + payload.clientProvideId + '&productCustomId=102&year=' + payload.year)
            .then(response => response.json())
            .then(body => {
                if (body?.item?.length > 0) {
                    actions.setMasterSettingPaidList(body?.item)
                } else {
                    actions.setMasterSettingPaidList(body?.item);
                    notification.error({ message: "No data found" })
                }

            }
            );
    }),

    setMasterSettingPaidList: action((state, payload) => {
        state.masterSettingPaidList = payload;
    }),

}
