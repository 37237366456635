import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, notification, Row, Select, Space, Table, Tooltip } from 'antd';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import TableView from "../../../../contents/AntTableResponsive";
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { Excel } from 'antd-table-saveas-excel';
import $ from 'jquery';
import Text from 'antd/lib/typography/Text';

require('jspdf-autotable');
const getColumns = function () {
    return [
        { title: "Serial", dataKey: "serial" },
        { title: "Fee Inovice Id", dataKey: "feeInvoiceId" },
        { title: "Student Id", dataKey: "customStudentId" },
        { title: "Student Name", dataKey: "studentName" },
        { title: "Mobile No", dataKey: "studentMobileNo" },
        { title: "Ledger", dataKey: "ledgerBy" },
        { title: "Payment Date", dataKey: "paymentDate" },
        { title: "Semester Year", dataKey: "semesterYear" },
        { title: "Paid Amount", dataKey: "amount" },
    ];
};


let totalamount = 0;
export default function SemesterYearPaidReport() {

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const fetchclassDepartmentSessionsemesterPaidInvoiceList = useStoreActions((state) => state.saccount.fetchclassDepartmentSessionsemesterPaidInvoiceList);
    const fetchShiftList = useStoreActions((state) => state.student.fetchShiftList);
    const classDepartmentSessionsemesterPaidInvoiceList = useStoreState((state) => state.saccount.classDepartmentSessionsemesterPaidInvoiceList);
    const [selectedClassDepartment, setSelectedClassDepartment] = useState<any>();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    const downloadMoneyReciept = useStoreActions((state) => state.saccount.downloadMoneyReciept);
    useEffect(() => {
        fetchSessionList();
        fetchClassConfigList();
        fetchShiftList();
    }, []);

    const searchStudentList = (value) => {
        let payload = {
            classDepartmentConfigId: value.classDepartment,
            sessionId: value.sessionYearName,
            semesterYearId: value.semesterYear,
        };

        fetchclassDepartmentSessionsemesterPaidInvoiceList(payload);
        // setCurrentPayload(payload);
    }

    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        setSelectedSemesterYear(null)
        getSemeterYear(value);
    };

    let sessionYearName = $(".sessionYearName").text();
    let classDepartment = $(".classDepartment").text();
    let semesterYear = $(".semesterYear").text();


    function exportPdf() {
        var doc = new jsPDF("l", "mm", "a4");

        var details = `Paid collection of Session Year: ${$(".sessionYearName").text()}, Class Department: ${$(".classDepartment").text()}, Semester Year: ${$(".semesterYear").text()} `;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        let first = doc.autoTable?.previous;
        doc.autoTable(getColumns(), classDepartmentSessionsemesterPaidInvoiceList, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },

        });
        doc.setFontSize(8);
        doc.text(`Total Paid Amount: ${totalamount}`, 15, doc.autoTable.previous.finalY + 5);
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }
    const columns = [

        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Fee Inovice Id',
            dataIndex: 'feeInvoiceId',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mobile No',
            dataIndex: 'studentMobileNo',
            key: 'studentMobileNo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Ledger',
            dataIndex: 'ledgerBy',
            key: 'ledgerBy',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Semester Year',
            dataIndex: 'semesterYear',
            key: 'semesterYear',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Download">
                        <Button type="primary" onClick={() => downloadMoneyReciept(record?.feeInvoiceId)} icon={<DownloadOutlined />} />
                    </Tooltip>

                </Space>
            ),
        },

    ];


    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const download = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }
        downloadMoneyReciept(selectedValue.map(item => item.feeInvoiceId).join(","));
    }

    return (
        <Card title="Semester Year Paid Report">

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 14, offset: 5 }} xl={{ span: 14, offset: 5 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={searchStudentList}
                        form={searchStudentForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                <Form.Item
                                    name="sessionYearName"
                                    label="Session Year"

                                    rules={[
                                        { required: true, message: "Please write session year" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select session"
                                        className="sessionYearName"
                                    >
                                        {sessionList ? (
                                            sessionList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Session</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                <Form.Item
                                    name="classDepartment"
                                    label="Class Department"

                                    rules={[
                                        { required: true, message: "Select class department" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Class Department"
                                        className="classDepartment"
                                        onChange={(e) => { getSemesterYear(e); }}
                                    >
                                        {classConfigList ? (
                                            classConfigList.map((type, idx) => (
                                                <Option key={type.configId} value={type.configId}>
                                                    {type.configName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                                <Form.Item
                                    name="semesterYear"
                                    label="Semester Year"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select semester year" },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select semester year"
                                        optionFilterProp="children"
                                        className="semesterYear"

                                        onChange={(e) => { setSelectedSemesterYear(e); }}
                                    >
                                        {semesterYearByDepartmentId ? (
                                            semesterYearByDepartmentId.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                                <Space size="small" >
                                    <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <div >
                <Row className="m-t-mo-30">
                    <Col span="24">
                        {classDepartmentSessionsemesterPaidInvoiceList !== null && classDepartmentSessionsemesterPaidInvoiceList.length > 0 &&
                            <>

                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: classDepartmentSessionsemesterPaidInvoiceList,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "masterId",
                                        rowSelection: rowSelection,
                                        summary: function () {
                                            totalamount = 0;
                                            classDepartmentSessionsemesterPaidInvoiceList?.forEach(({ amount }) => {
                                                totalamount += amount;

                                            });

                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={1}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={2}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={3}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={4}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={5}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={6}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={7}>

                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={8}>
                                                            <Text type="danger" strong>Total</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={9}>
                                                            <Text strong>{totalamount}</Text>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={10}>

                                                        </Table.Summary.Cell>

                                                    </Table.Summary.Row>

                                                </>
                                            );
                                        }
                                    }}
                                    mobileBreakPoint={768}
                                />
                                <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                                    <Button type="primary" onClick={exportPdf} icon={<DownloadOutlined />} >Download List</Button>
                                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => download()}> Download PDF</Button>
                                </Space>
                            </>
                        }
                    </Col>
                </Row>
            </div>

        </Card>
    )
}