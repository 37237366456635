import { Button, Card, Col, DatePicker, Form, notification, Row, Space, Table, Tooltip } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, lpowerdbypdf } from '../../../utils/pdf';
import { DownloadOutlined, FilePdfOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassDepartment } from '../../../select/SelectClassDepartment';
import Text from 'antd/lib/typography/Text';
require('jspdf-autotable');

var getColumns = function () {
    return [
        { title: "Fee Inovice Id", dataKey: "feeInvoiceId" },
        { title: "Student Id", dataKey: "customStudentId" },
        { title: "Student Name", dataKey: "studentName" },
        { title: "Ledger By", dataKey: "ledgerBy" },
        { title: "Payment Date", dataKey: "paymentDate" },
        { title: "Session", dataKey: "session" },
        { title: "Department", dataKey: "department" },
        { title: "Semester Year", dataKey: "semesterYear" },
        { title: "Paid Amount", dataKey: "amount" },
    ];
};

let fd = '';
let td = '';
let totalamount = 0;
export default function ClassDepartmentPaidReport(props) {

    const classDepartmentDateToDateInvoiceList = useStoreState((state) => state.payslip.classDepartmentDateToDateInvoiceList);
    const fetchclassDepartmentDateToDateInvoiceList = useStoreActions((state) => state.payslip.fetchclassDepartmentDateToDateInvoiceList);
    const downloadMoneyReciept = useStoreActions((state) => state.saccount.downloadMoneyReciept);

    const [reportForm] = Form.useForm();

    const reportFormSubmit = (value) => {
        let postData = {
            classDepartmentConfigId: value.classDepartmentConfigId,
            fromDate: moment(value?.fromDate).format("YYYY-MM-DD"),
            toDate: moment(value?.toDate).format("YYYY-MM-DD")
        }
        fd = moment(value?.fromDate).format("YYYY-MM-DD");
        td = moment(value?.toDate).format("YYYY-MM-DD");
        fetchclassDepartmentDateToDateInvoiceList(postData);
        selectedRowKeys([]);
        selectedValue([]);
    };



    function exportPdf() {



        var doc = new jsPDF("l", "mm", "a4");

        var details = `Paid collection of ${$(".classDep").text()} from ${fd} to ${td} `;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + pdatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        let first = doc.autoTable?.previous;
        doc.autoTable(getColumns(), classDepartmentDateToDateInvoiceList, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },

        });
        doc.setFontSize(8);
        doc.text(`Total Paid Amount: ${totalamount}`, 15, doc.autoTable.previous.finalY + 5);
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const columns = [

        {
            title: 'Fee Inovice Id',
            dataIndex: 'feeInvoiceId',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Ledger By',
            dataIndex: 'ledgerBy',
            key: 'ledgerBy',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payment Date',
            dataIndex: 'paymentDate',
            key: 'paymentDate',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Session',
            dataIndex: 'session',
            key: 'session',
            showOnResponse: true,
            showOnDesktop: true,
        },        
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Semester Year',
            dataIndex: 'semesterYear',
            key: 'semesterYear',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Paid Amount',
            dataIndex: 'amount',
            key: 'amount',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'feeInvoiceId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Download">
                        <Button type="primary" onClick={() => downloadMoneyReciept(record?.feeInvoiceId)} icon={<DownloadOutlined />} />
                    </Tooltip>

                </Space>
            ),
        },

    ];

    const download = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }
        downloadMoneyReciept(selectedValue.map(item => item.feeInvoiceId).join(","));
    }



    return (
        <>
            <Card title="Class Department" >
                <Form
                    layout="vertical"
                    onFinish={reportFormSubmit}
                    id="basic-info"
                    form={reportForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="classDepartmentConfigId"
                                label="Class Department"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please class department" },
                                ]}
                            >

                                <SelectClassDepartment />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="fromDate"
                                label="From Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter from date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                            <Form.Item
                                name="toDate"
                                label="To Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please enter to date" },
                                ]}
                            >

                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                            <Space size="small" >
                                <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                {classDepartmentDateToDateInvoiceList !== null &&
                    <>
                        <div className="datatable-responsive">
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: classDepartmentDateToDateInvoiceList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "masterId",
                                    rowSelection: rowSelection,
                                    summary: function () {
                                        totalamount = 0;
                                        classDepartmentDateToDateInvoiceList?.forEach(({ amount }) => {
                                            totalamount += amount;

                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}> </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={6}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7}>
                                                       
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={8}>
                                                    <Text type="danger" strong>Total</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9}>
                                                    <Text strong>{totalamount}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={10}>

                                                    </Table.Summary.Cell>                                               
                                                    <Table.Summary.Cell index={11}>

                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                        <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                            <Button type="primary" onClick={exportPdf} icon={<DownloadOutlined />} >Download List</Button>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => download()}> Download PDF</Button>
                        </Space>
                    </>
                }
            </Card>
        </>
    )
}
