import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Space, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import NoImage from './no.png'
import { SelectReligion } from '../../../select/SelectReligion';
import { SelectDesignation } from '../../../select/SelectDesignation';
import { SelectBloodGroup } from '../../../select/SelectBloodGroup';
import { SelectGender } from '../../../select/SelectGender';
import moment from 'moment';

export default function StaffUpdate(props) {

    const [updateForm] = Form.useForm();

    const staffBasicInfoList = useStoreState((state) => state.staff.staffBasicInfoList);
    const fetchstaffBasicInfoList = useStoreActions((state) => state.staff.fetchstaffBasicInfoList);
    const updateSingleStaffBasicInfo = useStoreActions((state) => state.staff.updateSingleStaffBasicInfo);
    const updateSingleStaffPhoto = useStoreActions((state) => state.staff.updateSingleStaffPhoto);

    useEffect(() => {
        fetchstaffBasicInfoList();
    }, [])



    const updateFomrSubmit = (value) => {
        value.staffId = staffId;
        value.birthDate = value.birthDate === null ? null : moment(value?.birthDate).format("YYYY-MM-DD");
        value.employmentDate = value.employmentDate === null ? null : moment(value?.employmentDate).format("YYYY-MM-DD");
        value.resignDate = value.resignDate === null ? null : moment(value?.resignDate).format("YYYY-MM-DD");
        value.staffStatus = value.staffStatus === true ? 1 : 0
        // console.log(value)
        updateSingleStaffBasicInfo(value)
        //updateDesignation(postdata);
        setIsModalVisible(false);
    }

    const [staffId, setstaffId] = useState<any>();
    const [gender, setgender] = useState<any>();
    const [religion, setreligion] = useState<any>();
    const [designationId, setdesignationId] = useState<any>();
    const [bloodGroup, setbloodGroup] = useState<any>();

    const uploadImage = (val, id) => {
        let image_as_files = val.target.files[0];
        let data = {
            file: image_as_files,
            staffId: id
        }
        updateSingleStaffPhoto(data)
    }

    const columns = [

        {
            title: 'Serial',
            dataIndex: 'staffSerial',
            key: 'staffSerial',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Name',
            dataIndex: 'staffName',
            key: 'staffName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'ID',
            dataIndex: 'customStaffId',
            key: 'customStaffId',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Designation',
            dataIndex: 'designationName',
            key: 'designationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Mobile No.',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Father's Name ",
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: "Mother's Name",
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true
        },
        // {
        //     title: 'Gender',
        //     dataIndex: 'gender',
        //     key: 'gender',
        //     showOnResponse: true,
        //     showOnDesktop: true
        // },
        // {
        //     title: 'Religion',
        //     dataIndex: 'religion',
        //     key: 'religion',
        //     showOnResponse: true,
        //     showOnDesktop: true
        // },
        {
            title: 'Blood Group',
            dataIndex: 'bloodGroup',
            key: 'bloodGroup',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Edit',
            key: 'staffId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setstaffId(record.staffId);
                            setgender(record.gender);
                            setreligion(record.religion);
                            setdesignationId(record.designationId);
                            setbloodGroup(record.bloodGroup);
                            updateForm.setFieldsValue({
                                staffName: record.staffName,
                                staffStatus: record.staffStatus === 1 ? true : false,
                                customStaffId: record.customStaffId,
                                fatherName: record.fatherName,
                                motherName: record.motherName,
                                mobileNumber: record.mobileNumber,
                                email: record.email,
                                religion: record.religion,
                                gender: record.gender,
                                bloodGroup: record.bloodGroup,
                                designationId: record.designationId,
                                staffSerial: record.staffSerial,
                                birthDate: record.birthDate == null ? null : moment(record.birthDate, 'YYYY-MM-DD'),
                                employmentDate: record.employmentDate == null ? null : moment(record.employmentDate, 'YYYY-MM-DD'),
                                resignDate: record.resignDate == null ? null : moment(record.resignDate, 'YYYY-MM-DD'),
                            });
                        }}
                        />
                    </Tooltip>

                </Space>
            ),
        },
        {
            title: 'Image',
            key: 'imageName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <div style={{ textAlign: "center" }}>
                    <img src={record.imageName == '' ? NoImage : ("data:image/png;base64," + record.imageName)} alt="Staff Image" style={{ height: 50, width: 50 }} />
                    <br />
                    <input type="file" name="my_file" id="my_file" accept="image/*" onChange={(e) => uploadImage(e, record.staffId)} />
                </div>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <Card title="Teacher/Staff Update" >


                <div className="datatable-responsive">
                    {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                    <Table
                        antTableProps={{
                            showHeader: true,
                            columns,
                            dataSource: staffBasicInfoList,
                            pagination: false,
                            bordered: true,
                            rowKey: 'staffId'
                        }}
                        mobileBreakPoint={768}
                    />
                </div>

            </Card>

            <Modal
                title="Update Staff Info"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); updateForm.resetFields() }}
                cancelText="Close"
                okText="Update"
                centered
                width={"60%"}
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="customStaffId"
                                label="ID:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff id" },
                                ]}
                            >
                                <Input placeholder='Staff Id' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffName"
                                label="Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input staff name" },
                                ]}
                            >
                                <Input placeholder='Staff Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="designationId"
                                label="Designation:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select designation" },
                                ]}
                            >
                                <SelectDesignation selected={designationId} onChange={(e) => setdesignationId(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="fatherName"
                                label="Father's Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input father name" },
                                ]}
                            >
                                <Input placeholder='Father Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="motherName"
                                label="Mother's Name:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input mother name" },
                                ]}
                            >
                                <Input placeholder='Mother name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="religion"
                                label="Religion:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select religion" },
                                ]}
                            >
                                <SelectReligion selected={religion} onChange={(e) => setreligion(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="bloodGroup"
                                label="Blood Group:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select blood group" },
                                ]}
                            >
                                <SelectBloodGroup selected={bloodGroup} onChange={(e) => setbloodGroup(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="gender"
                                label="Gender:"
                                className="title-Text"

                                rules={[
                                    { required: true, message: "Please select gender" },
                                ]}
                            >
                                <SelectGender selected={gender} onChange={(e) => setgender(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="birthDate"
                                label="Date of Birth:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select birth date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="employmentDate"
                                label="Employment Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="resignDate"
                                label="Resign Date:"
                                className="title-Text"

                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffSerial"
                                label="Serial:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input serial" },
                                ]}
                            >
                                <Input placeholder='Staff Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="mobileNumber"
                                label="Mobile No:"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input mobile no." },
                                ]}
                            >
                                <Input placeholder='Staff Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="email"
                                label="Email:"
                                className="title-Text"

                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item
                                name="staffStatus"
                                label="Status:"
                                valuePropName="checked"
                                className="title-Text"

                            >
                                <Checkbox >Status</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}
