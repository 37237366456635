import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { createFeeHead, createSinglePayslip, createWaiver, deletefeeFineLedgerConfiguration, deleteFeeHead, deleteFeeHeadLedgerConfiguration, deleteFeeHeadLedgerConfigurationAmount, deleteFeeInvoice, deletestudentFeeHeadLedgerConfiguration, deleteStudentWaiver, deleteWaiver, feeheadDeleteSave, fetchclassDepartmentSessionsemesterPaidInvoiceList, fetchfeeAmountConfigurationList, fetchfeeAmountConfigurationListBySessionClassDepartment, fetchfeeFineLedgerConfigurationList, fetchfeeheadDeletedList, fetchfeeHeadLedgerConfigurationList, fetchfeeHeadList, fetchofpsconfigurationView, fetchspgStatement, fetchstudentCollecionView, fetchstudentWaiverList, fetchviewFeeInvoice, fetchwaiverList, moneyReceiptDownload, saveFeeAmountConfiguration, savefeeFineLedgerConfiguration, saveFeeHeadLedgerConfiguration, saveofpsconfigurationView, savestudentCollecionView, saveStudentWaiver, saveStudentWaiverBatch, updateFeeAmountConfiguration, updateFeeHead, updateStudentWaiver, updateWaiver } from '../../../http/saccount/saccount';
import FileSaver from 'file-saver';
import { downloadPayslip } from '../../../http/payslip/payslip';
export interface Saccount {

	createFeeHead: Thunk<Saccount, any>
	updateFeeHead: Thunk<Saccount, any>
	deleteFeeHead: Thunk<Saccount, any>
	feeHeadList: any;
	setfeeHeadList: Action<Saccount, any>;
	fetchfeeHeadList: Thunk<Saccount>

	createWaiver: Thunk<Saccount, any>
	updateWaiver: Thunk<Saccount, any>
	deleteWaiver: Thunk<Saccount, any>
	waiverList: any;
	setwaiverList: Action<Saccount, any>;
	fetchwaiverList: Thunk<Saccount>
	downloadMoneyReciept: Thunk<Saccount, any>


	feeHeadLedgerConfigurationList: any;
	setfeeHeadLedgerConfigurationList: Action<Saccount, any>;
	fetchfeeHeadLedgerConfigurationList: Thunk<Saccount>
	saveFeeHeadLedgerConfiguration: Thunk<Saccount, any>
	deleteFeeHeadLedgerConfiguration: Thunk<Saccount, any>

	feeAmountConfigurationListBySessionClassDepartment: any;
	setfeeAmountConfigurationListBySessionClassDepartment: Action<Saccount, any>;
	fetchfeeAmountConfigurationListBySessionClassDepartment: Thunk<Saccount>


	feeFineLedgerConfigurationList: any;
	setfeeFineLedgerConfigurationList: Action<Saccount, any>;
	fetchfeeFineLedgerConfigurationList: Thunk<Saccount>
	savefeeFineLedgerConfiguration: Thunk<Saccount, any>
	deletefeeFineLedgerConfiguration: Thunk<Saccount, any>


	feeAmountConfigurationList: any;
	setfeeAmountConfigurationList: Action<Saccount, any>;
	fetchfeeAmountConfigurationList: Thunk<Saccount>
	saveFeeAmountConfiguration: Thunk<Saccount, any>
	updateFeeAmountConfiguration: Thunk<Saccount, any>
	deleteFeeHeadLedgerConfigurationAmount: Thunk<Saccount, any>


	studentWaiverList: any;
	setstudentWaiverList: Action<Saccount, any>;
	fetchstudentWaiverList: Thunk<Saccount>
	saveStudentWaiver: Thunk<Saccount, any>
	saveStudentWaiverBatch: Thunk<Saccount, any>
	updateStudentWaiver: Thunk<Saccount, any>
	deleteStudentWaiver: Thunk<Saccount, any>

	feeheadDeleteSave: Thunk<Saccount, any>

	deletestudentFeeHeadLedgerConfiguration: Thunk<Saccount, any>

	feeheadDeletedList: any;
	setfeeheadDeletedList: Action<Saccount, any>;
	fetchfeeheadDeletedList: Thunk<Saccount>

	studentCollecionView: any;
	setstudentCollecionView: Action<Saccount, any>;
	fetchstudentCollecionView: Thunk<Saccount, any>

	studentCollecionView2: any;
	setstudentCollecionView2: Action<Saccount, any>;
	fetchstudentCollecionView2: Thunk<Saccount, any>

	checkmanualFee: boolean;
	setcheckmanualFee: Action<Saccount, any>;

	checkmanualFee2: boolean;
	setcheckmanualFee2: Action<Saccount, any>;

	savestudentCollecionView: Thunk<Saccount, any>
	createSinglePayslip: Thunk<Saccount, any>

	viewFeeInvoice: any;
	setviewFeeInvoice: Action<Saccount, any>;
	fetchviewFeeInvoice: Thunk<Saccount, any>

	deleteFeeInvoice: Thunk<Saccount, any>

	ofpsconfigurationView: any;
	setofpsconfigurationView: Action<Saccount, any>;
	fetchofpsconfigurationView: Thunk<Saccount>
	saveofpsconfigurationView: Thunk<Saccount, any>
	
	spgStatement: any;
	setspgStatement: Action<Saccount, any>;
	fetchspgStatement: Thunk<Saccount, any>	;
	
	classDepartmentSessionsemesterPaidInvoiceList: any,
    setclassDepartmentSessionsemesterPaidInvoiceList: Action<Saccount, any>,
    fetchclassDepartmentSessionsemesterPaidInvoiceList: Thunk<Saccount, any>;

	loading: boolean;
    setLoading: Action<Saccount, boolean>;

}

export const saccountStore: Saccount = {

	feeHeadList: null,
	fetchfeeHeadList: thunk(async (actions) => {
		const response = await fetchfeeHeadList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setfeeHeadList(body?.item);
		} else {

		}
	}),

	setfeeHeadList: action((state, payload) => {
		state.feeHeadList = payload;
	}),

	loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
	}),
	
	createFeeHead: thunk(async (actions, payload) => {
		const response = await createFeeHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateFeeHead: thunk(async (actions, payload) => {
		const response = await updateFeeHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteFeeHead: thunk(async (actions, payload) => {
		const response = await deleteFeeHead(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	waiverList: null,
	fetchwaiverList: thunk(async (actions) => {
		const response = await fetchwaiverList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setwaiverList(body?.item);
		} else {

		}
	}),

	setwaiverList: action((state, payload) => {
		state.waiverList = payload;
	}),

	createWaiver: thunk(async (actions, payload) => {
		const response = await createWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchwaiverList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateWaiver: thunk(async (actions, payload) => {
		const response = await updateWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchwaiverList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteWaiver: thunk(async (actions, payload) => {
		const response = await deleteWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchwaiverList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	feeHeadLedgerConfigurationList: null,
	fetchfeeHeadLedgerConfigurationList: thunk(async (actions) => {
		const response = await fetchfeeHeadLedgerConfigurationList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setfeeHeadLedgerConfigurationList(body?.item);
		} else {

		}
	}),

	setfeeHeadLedgerConfigurationList: action((state, payload) => {
		state.feeHeadLedgerConfigurationList = payload;
	}),


	saveFeeHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await saveFeeHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	deleteFeeHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deleteFeeHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeHeadLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	feeAmountConfigurationListBySessionClassDepartment: [],
	fetchfeeAmountConfigurationListBySessionClassDepartment: thunk(async (actions, payload) => {
		const response = await fetchfeeAmountConfigurationListBySessionClassDepartment(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length > 0) {
				actions.setfeeAmountConfigurationListBySessionClassDepartment(body?.item);
			} else {
				actions.setfeeAmountConfigurationListBySessionClassDepartment([]);
				notification.warn({ message: "No Data Found" })
			}
		} else {
			actions.setfeeAmountConfigurationListBySessionClassDepartment([]);
		}
	}),

	setfeeAmountConfigurationListBySessionClassDepartment: action((state, payload) => {
		state.feeAmountConfigurationListBySessionClassDepartment = payload;
	}),

	feeFineLedgerConfigurationList: null,
	fetchfeeFineLedgerConfigurationList: thunk(async (actions) => {
		const response = await fetchfeeFineLedgerConfigurationList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			//console.log(body)
			actions.setfeeFineLedgerConfigurationList(body?.item);
		} else {

		}
	}),

	setfeeFineLedgerConfigurationList: action((state, payload) => {
		state.feeFineLedgerConfigurationList = payload;
	}),


	savefeeFineLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await savefeeFineLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeFineLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	deletefeeFineLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deletefeeFineLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeFineLedgerConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	///Fee amount
	feeAmountConfigurationList: null,
	fetchfeeAmountConfigurationList: thunk(async (actions) => {
		const response = await fetchfeeAmountConfigurationList();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setfeeAmountConfigurationList(body?.item);
		} else {

		}
	}),

	setfeeAmountConfigurationList: action((state, payload) => {
		state.feeAmountConfigurationList = payload;
	}),

	saveFeeAmountConfiguration: thunk(async (actions, payload) => {
		const response = await saveFeeAmountConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	updateFeeAmountConfiguration: thunk(async (actions, payload) => {
		const response = await updateFeeAmountConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteFeeHeadLedgerConfigurationAmount: thunk(async (actions, payload) => {
		const response = await deleteFeeHeadLedgerConfigurationAmount(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	///Fee Waiver


	saveStudentWaiver: thunk(async (actions, payload) => {
		const response = await saveStudentWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),	
	saveStudentWaiverBatch: thunk(async (actions, payload) => {
		const response = await saveStudentWaiverBatch(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	///Fee amount
	studentWaiverList: null,
	fetchstudentWaiverList: thunk(async (actions, payload) => {
		const response = await fetchstudentWaiverList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setstudentWaiverList(body?.item);
		} else {

		}
	}),

	setstudentWaiverList: action((state, payload) => {
		state.studentWaiverList = payload;
	}),



	updateStudentWaiver: thunk(async (actions, payload) => {
		const response = await updateStudentWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstudentWaiverList(payload);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	deleteStudentWaiver: thunk(async (actions, payload) => {
		const response = await deleteStudentWaiver(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchstudentWaiverList(payload);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),


	feeheadDeleteSave: thunk(async (actions, payload) => {
		const response = await feeheadDeleteSave(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	feeheadDeletedList: null,
	fetchfeeheadDeletedList: thunk(async (actions, payload) => {
		const response = await fetchfeeheadDeletedList(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.item?.length===0){
				notification.warn({message:"No data found"})
				actions.setfeeheadDeletedList(body?.item);
			} else actions.setfeeheadDeletedList(body?.item);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setfeeheadDeletedList: action((state, payload) => {
		state.feeheadDeletedList = payload;
	}),



	deletestudentFeeHeadLedgerConfiguration: thunk(async (actions, payload) => {
		const response = await deletestudentFeeHeadLedgerConfiguration(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			actions.fetchfeeheadDeletedList(payload);
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	checkmanualFee: false,

	setcheckmanualFee: action((state, payload) => {
		state.checkmanualFee = payload;
	}),

	checkmanualFee2: false,

	setcheckmanualFee2: action((state, payload) => {
		state.checkmanualFee2 = payload;
	}),

	studentCollecionView: null,
	fetchstudentCollecionView: thunk(async (actions, payload) => {
		actions.setcheckmanualFee(false);
		const response = await fetchstudentCollecionView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setstudentCollecionView(body?.item);
			actions.setcheckmanualFee(true);
		} else {
			actions.setcheckmanualFee(false);
		}
	}),

	setstudentCollecionView: action((state, payload) => {
		state.studentCollecionView = payload;
	}),

	studentCollecionView2: null,
	fetchstudentCollecionView2: thunk(async (actions, payload) => {
		actions.setcheckmanualFee2(false);
		const response = await fetchstudentCollecionView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setstudentCollecionView2(body?.item);
			actions.setcheckmanualFee2(true);
		} else {
			actions.setcheckmanualFee2(false);
		}
	}),

	setstudentCollecionView2: action((state, payload) => {
		state.studentCollecionView2 = payload;
	}),

	savestudentCollecionView: thunk(async (actions, payload) => {
		const response = await savestudentCollecionView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body)
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
				const downloadFile = await moneyReceiptDownload(body.item);
				if (downloadFile.status === 201 || downloadFile.status === 200) {
					const bodyx = await downloadFile.blob();
					var blobx = new Blob([bodyx], { type: "application/octet-stream" });
					var fileName = "Money Receipt.pdf";
					FileSaver.saveAs(blobx, fileName);
					return;


				}
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	createSinglePayslip: thunk(async (actions, payload) => {
		const response = await createSinglePayslip(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message });
				const downloadFile = await downloadPayslip(body.item);
				const file = await downloadFile.blob();
				var blob = new Blob([file], { type: "application/octet-stream" });
				var fileName = "Payslip.pdf";
				FileSaver.saveAs(blob, fileName);
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	downloadMoneyReciept: thunk(async (actions, payload) => {
		const downloadFile = await moneyReceiptDownload(payload);
		if (downloadFile.status === 201 || downloadFile.status === 200) {
			const bodyx = await downloadFile.blob();
			var blobx = new Blob([bodyx], { type: "application/octet-stream" });
			var fileName = "Money Receipt.pdf";
			FileSaver.saveAs(blobx, fileName);
			return;
		}
		else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	viewFeeInvoice: null,
	fetchviewFeeInvoice: thunk(async (actions, payload) => {
		const response = await fetchviewFeeInvoice(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				actions.setviewFeeInvoice(body?.item);
			} else {
				notification.error({ message: body.message })
				actions.setviewFeeInvoice(null);
			}
		} else {

		}
	}),

	setviewFeeInvoice: action((state, payload) => {
		state.viewFeeInvoice = payload;
	}),

	deleteFeeInvoice: thunk(async (actions, payload) => {
		console.log(payload)
		const response = await deleteFeeInvoice(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			console.log(body)
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
				actions.setviewFeeInvoice(null);
			} else {
				notification.error({ message: body.message })

			}
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	///Fee amount
	ofpsconfigurationView: null,
	fetchofpsconfigurationView: thunk(async (actions, payload) => {
		const response = await fetchofpsconfigurationView();
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			actions.setofpsconfigurationView(body?.item);
		} else {

		}
	}),

	setofpsconfigurationView: action((state, payload) => {
		state.ofpsconfigurationView = payload;
	}),

	saveofpsconfigurationView: thunk(async (actions, payload) => {
		const response = await saveofpsconfigurationView(payload);
		if (response.status === 201 || response.status === 200) {
			const body = await response.json();
			if (body?.messageType === 1) {
				notification.success({ message: body.message })
				actions.fetchofpsconfigurationView();
			}
			if (body?.messageType === 0) {
				notification.warn({ message: body.message })
			}
			//actions.fetchfeeAmountConfigurationList();
		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),
	spgStatement: null,
	fetchspgStatement: thunk(async (actions, payload) => {
		actions.setLoading(true);
		const response = await fetchspgStatement(payload);
		if (response.status === 201 || response.status === 200) {
			actions.setLoading(false);
			const body = await response.json();
			if (body?.messageType===0){
				notification.warn({ message: body.message});
				actions.setspgStatement(null);
				return
			}
			if (body?.item?.length > 0) {
				actions.setspgStatement(body?.item);
			} else {
				actions.setspgStatement(null);
				notification.warn({ message: "No data found" })
				actions.setLoading(false);
			}

		} else {
			notification.error({ message: "Something went wrong" })
		}
	}),

	setspgStatement: action((state, payload) => {
		state.spgStatement = payload;
	}),	

	classDepartmentSessionsemesterPaidInvoiceList: null,
    fetchclassDepartmentSessionsemesterPaidInvoiceList: thunk(async (actions, payload) => {
        const response = await fetchclassDepartmentSessionsemesterPaidInvoiceList(payload);
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            if (body?.item?.length > 0) {
            actions.setclassDepartmentSessionsemesterPaidInvoiceList(body?.item);
            } else {
                notification.error({ message: "No data found" })
                actions.setclassDepartmentSessionsemesterPaidInvoiceList(null)
            }

        } else {
            const body = await response.json();
            notification.error({ message: body.message })
            actions.setclassDepartmentSessionsemesterPaidInvoiceList(null)
        }

    }),
    setclassDepartmentSessionsemesterPaidInvoiceList: action((state, payload) => {
        state.classDepartmentSessionsemesterPaidInvoiceList = payload
    }),   


}
