import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox } from 'antd'
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectClass } from '../../../select/SelectClass';
import { SelectDepartment } from '../../../select/SelectDepartment';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Table, Tag} from 'antd';


export default function CalssConfiguration() {
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const createClassConfiguration = useStoreActions((state) => state.student.createClassConfiguration);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const deleteClassConfig = useStoreActions((state) => state.student.deleteClassConfig);
    const [classConfigForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    useEffect(() => {
        fetchClassConfigList();
    },[])

    const classConfigFormStore =(value) => {  
        let postData = {
            classId: value.classId,
            departmentId: value.departmentId,
            serial: value.serialNumber
        }
        console.log('postData', postData);
        createClassConfiguration(postData);
        classConfigForm.resetFields();
    }

    const columns = [
        {
            title: 'Serial',
            dataIndex: 'serial',
            key: 'serial',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Class Name',
            dataIndex: 'className',
            key: 'className',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Department Name',
            dataIndex: 'departmentName',
            key: 'departmentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Action',
            key: 'configId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteClassConfig(record?.configId)}
                    >
                        <Tooltip title="Delete">
                            <Button danger  icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        }
      ];

    return <>
        <Card title="Class Department Configuration">
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24}} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                    <Form
                        layout="vertical"
                        id="classConfigInfo"
                        onFinish={classConfigFormStore}
                        form={classConfigForm}
                    >
                        <Row>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                        name="classId"
                                        label="Class"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class" },
                                        ]}
                                    >
                                    <SelectClass />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                        name="departmentId"
                                        label="Department"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select department" },
                                        ]}
                                    >
                                    <SelectDepartment />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item
                                        name="serialNumber"
                                        label="Serial Number"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write serial number" },
                                        ]}
                                    >
                                    <InputNumber placeholder="Enter serial number" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24}} sm={{ span: 24}} md={{ span: 4}} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                        Save
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="m-t-mo-30">
                <Col span="24">
                    <div className="datatable-responsive-demo">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}
                        <Table bordered={true} dataSource={classConfigList} columns={columns} className="p-datatable-responsive-demo" />
                    </div>
                </Col>
            </Row>
        </Card>
    </>
}