import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import HeadCollectionStudent from './HeadCollectionStudent';
import HeadCollection from './HeadCollection';
import HeadCollectionStudentDateOnly from './HeadCollectionStudentDateOnly';

    export default function HeadCollectionWarpper(){
        const { TabPane } = Tabs;

        return(
            <>
                <Card title="Head Collection">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Date" key="1">
                            <HeadCollection />
                        </TabPane>
                        <TabPane tab="Student" key="2">
                            <HeadCollectionStudent />
                        </TabPane>                     
                        <TabPane tab="Student (Date Only)" key="3">
                            <HeadCollectionStudentDateOnly />
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        )
    }