import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Table } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import Text from 'antd/lib/typography/Text';

export default function InstiuteStudentCount() {

    const fetchStudentCount = useStoreActions((state) => state.common.fetchStudentCount);
    const studentCount = useStoreState((state) => state.common.studentCount);

    useEffect(() => {
        fetchStudentCount();
    }, []);



    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'instituteStatusString', key: "instituteStatusString", showOnResponse: true, showOnDesktop: true },
        { title: 'Number Of Student', dataIndex: 'totalStudent', key: "totalStudent", showOnResponse: true, showOnDesktop: true }
    ];

    return (
        <>
            <Card title="Institute Student Count">
                <Row className="m-t-mo-30">
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: studentCount,
                                    filterData: studentCount,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "instituteId",
                                    summary: function () {
                                        let finaltotalStudent = 0;

                                        studentCount?.forEach(({ totalStudent }) => {
                                            finaltotalStudent += totalStudent;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}><Text type="danger" strong>Total Student</Text> </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}>

                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4}>
                                                        <Text type="danger" strong>{finaltotalStudent}</Text>
                                                    </Table.Summary.Cell>

                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}