import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import { createLedger, deleteLedger, fetchcategoryList, fetchledgerList, fetchreceiptledgerlist, updateLedger, fetchcashEquivalanceLedgerList, doReceiptVoucher, download, fetchpaymentledgerlist, doPaymentVoucher, fetchcashDFPSLedgerList, doContraVoucher, fetchallLedgerList, doJournalVoucher, fetchtrialBalance, fetchbalanceSheet, fetchincomeStatement, fetchjournalStatement, fetchcashSummary, fetchaccountBookCash, fetchledgerBook, fetchfundsFlow, fetchvoucherInfo, fetchaccountRegularIncomeLedgerList, fetchofpsLedgerList } from '../../../http/ledger/ledger';
import FileSaver from "file-saver";
export interface Ledger {
    check: any;
    actionCheck: Action<Ledger, any>;
    createLedger: Thunk<Ledger, any>;
    updateLedger: Thunk<Ledger, any>;
    deleteLedger: Thunk<Ledger, any>;

    download: Thunk<Ledger, any>;

    doReceiptVoucher: Thunk<Ledger, any>;
    doPaymentVoucher: Thunk<Ledger, any>;
    doContraVoucher: Thunk<Ledger, any>;
    doJournalVoucher: Thunk<Ledger, any>;
    trnid: any;
    actiontrnid: Action<Ledger, any>;


    ledgerList: any;
    setledgerList: Action<Ledger, any>;
    fetchledgerList: Thunk<Ledger>;

    ofpsLedgerList: any;
    setofpsLedgerList: Action<Ledger, any>;
    fetchofpsLedgerList: Thunk<Ledger>;

    categoryList: any;
    setcategoryList: Action<Ledger, any>;
    fetchcategoryList: Thunk<Ledger>;

    receiptledgerlist: any;
    setreceiptledgerlist: Action<Ledger, any>;
    fetchreceiptledgerlist: Thunk<Ledger>;

    paymentledgerlist: any;
    setpaymentledgerlist: Action<Ledger, any>;
    fetchpaymentledgerlist: Thunk<Ledger>;


    cashEquivalanceLedgerList: any;
    setcashEquivalanceLedgerList: Action<Ledger, any>;
    fetchcashEquivalanceLedgerList: Thunk<Ledger>;

    cashDFPSLedgerList: any;
    setcashDFPSLedgerList: Action<Ledger, any>;
    fetchcashDFPSLedgerList: Thunk<Ledger>;

    allLedgerList: any;
    setallLedgerList: Action<Ledger, any>;
    fetchallLedgerList: Thunk<Ledger>;

    //////////report
    trialBalance: any;
    settrialBalance: Action<Ledger, any>;
    fetchtrialBalance: Thunk<Ledger, any>;

    balanceSheet: any;
    setbalanceSheet: Action<Ledger, any>;
    fetchbalanceSheet: Thunk<Ledger, any>;

    incomeStatement: any;
    setincomeStatement: Action<Ledger, any>;
    fetchincomeStatement: Thunk<Ledger, any>;

    journalStatement: any;
    setjournalStatement: Action<Ledger, any>;
    fetchjournalStatement: Thunk<Ledger, any>;

    cashSummary: any;
    setcashSummary: Action<Ledger, any>;
    fetchcashSummary: Thunk<Ledger, any>;

    accountBookCash: any;
    setaccountBookCash: Action<Ledger, any>;
    fetchaccountBookCash: Thunk<Ledger, any>;    
    
    ledgerBook: any;
    setledgerBook: Action<Ledger, any>;
    fetchledgerBook: Thunk<Ledger, any>;    
    
    fundsFlow: any;
    setfundsFlow: Action<Ledger, any>;
    fetchfundsFlow: Thunk<Ledger, any>;
     //////////report
     
     voucherInfo: any;
     setvoucherInfo: Action<Ledger, any>;
     fetchvoucherInfo: Thunk<Ledger, any>;

     accountRegularIncomeLedgerList: any;
     setaccountRegularIncomeLedgerList: Action<Ledger, any>;
     fetchaccountRegularIncomeLedgerList: Thunk<Ledger>;

}

export const ledgerStore: Ledger = {

    voucherInfo: [],
    fetchvoucherInfo: thunk(async (actions, payload) => {
        const response = await fetchvoucherInfo(payload);
        actions.setvoucherInfo([]);
        const body: any = await response.json();
        actions.setvoucherInfo([]);
        if (response.status === 200) {
            if (body.messageType == 1) {
                actions.setvoucherInfo(body.item);
                actions.actionCheck(true)
            } else {
                notification.error({ message: body.message })
                actions.actionCheck(false)
            }
        } else {
            //const body = await response.json();
            actions.actionCheck(false)
            actions.setvoucherInfo([]);
        }
    }),
    setvoucherInfo: action((state, payload) => {
        state.voucherInfo = payload;
    }),

//new branch
    //////////////////report
    fundsFlow: [],
    fetchfundsFlow: thunk(async (actions, payload) => {
        const response = await fetchfundsFlow(payload);
        //console.log(response)
        actions.setfundsFlow([]);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setfundsFlow(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setfundsFlow([]);
        }
    }),
    setfundsFlow: action((state, payload) => {
        state.fundsFlow = payload;
    }),
    //
    ledgerBook: [],
    fetchledgerBook: thunk(async (actions, payload) => {
        const response = await fetchledgerBook(payload);
        //console.log(response)
        actions.setledgerBook([]);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setledgerBook(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setledgerBook([]);
        }
    }),
    setledgerBook: action((state, payload) => {
        state.ledgerBook = payload;
    }),
    //
    accountBookCash: [],
    fetchaccountBookCash: thunk(async (actions, payload) => {
        const response = await fetchaccountBookCash(payload);
        //console.log(response)
        actions.setaccountBookCash([]);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setaccountBookCash(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setaccountBookCash([]);
        }
    }),
    setaccountBookCash: action((state, payload) => {
        state.accountBookCash = payload;
    }),
    // 
    cashSummary: [],
    fetchcashSummary: thunk(async (actions, payload) => {
        const response = await fetchcashSummary(payload);
        //console.log(response)
        actions.setcashSummary([]);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setcashSummary(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setcashSummary([]);
        }
    }),
    setcashSummary: action((state, payload) => {
        state.cashSummary = payload;
    }),
    // 
    journalStatement: [],
    fetchjournalStatement: thunk(async (actions, payload) => {
        const response = await fetchjournalStatement(payload);
        //console.log(response)
        actions.setjournalStatement([]);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setjournalStatement(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setjournalStatement([]);
        }
    }),
    setjournalStatement: action((state, payload) => {
        state.journalStatement = payload;
    }),
    //  
    incomeStatement: [],
    fetchincomeStatement: thunk(async (actions, payload) => {
        const response = await fetchincomeStatement(payload);
        //console.log(response)
        actions.setincomeStatement([]);
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setincomeStatement(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setincomeStatement([]);
        }
    }),
    setincomeStatement: action((state, payload) => {
        state.incomeStatement = payload;
    }),
    //    
    balanceSheet: [],
    fetchbalanceSheet: thunk(async (actions, payload) => {
        const response = await fetchbalanceSheet(payload);
        //console.log(response)
        actions.setbalanceSheet([]);
        if (response.status === 200) {
            const body: any = await response.json();
            //console.log(body)
            if (body.messageType == 1) {
                actions.setbalanceSheet(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.setbalanceSheet([]);
        }
    }),
    setbalanceSheet: action((state, payload) => {
        state.balanceSheet = payload;
    }),
    //    
    trialBalance: [],
    fetchtrialBalance: thunk(async (actions, payload) => {
        const response = await fetchtrialBalance(payload);
        //console.log(response)
        actions.settrialBalance([]);
        if (response.status === 200) {
            const body: any = await response.json();
            //console.log(body)
            if (body.messageType == 1) {
                actions.settrialBalance(body.item);
                actions.actionCheck(Math.random())
            }
        } else {
            //const body = await response.json();
            actions.settrialBalance([]);
        }
    }),
    settrialBalance: action((state, payload) => {
        state.trialBalance = payload;
    }),

    ///////////////////// report
    check: 'test',
    actionCheck: action((state, payload) => {
        state.check = payload;
    }),
    trnid: null,
    actiontrnid: action((state, payload) => {
        state.trnid = payload;
    }),

    download: thunk(async (actions, payload,) => {
        const response: any = await download(payload);
        const body: any = await response.blob();
        var blob = new Blob([body], {
            type: "application/octet-stream"
        });
        FileSaver(blob, "Voucher.pdf");
        // var text = "Hello world!";

        // var blob = new Blob([text], {
        //     type: "text/plain; encoding=UTF-8"
        // });
        // FileSaver(blob, "result.txt");
    }),

    doReceiptVoucher: thunk(async (actions, payload,) => {
        const response = await doReceiptVoucher(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.actiontrnid(body.item)
            } else {
                notification.warn({ message: body.message })
            }

        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),
    doPaymentVoucher: thunk(async (actions, payload,) => {
        const response = await doPaymentVoucher(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.actiontrnid(body.item)
            } else {
                notification.warn({ message: body.message })
            }

        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),
    doContraVoucher: thunk(async (actions, payload,) => {
        const response = await doContraVoucher(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.actiontrnid(body.item)
            } else {
                notification.warn({ message: body.message })
            }

        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),
    doJournalVoucher: thunk(async (actions, payload,) => {
        const response = await doJournalVoucher(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.actiontrnid(body.item)
            } else {
                notification.warn({ message: body.message })
            }

        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),

    createLedger: thunk(async (actions, payload,) => {
        const response = await createLedger(payload);
        if (response.status === 201) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.actionCheck(Math.random())
            } else {
                notification.warn({ message: body.message })
            }

        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),
    updateLedger: thunk(async (actions, payload,) => {

        const response = await updateLedger(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();

            if (body.messageType == 1) {
                notification.success({ message: body.message })
                actions.actionCheck(Math.random())
            } else {
                notification.warn({ message: body.message })
            }

        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),

    deleteLedger: thunk(async (actions, payload,) => {
        const response = await deleteLedger(payload);
        if (response.status === 201 || response.status === 200) {
            const body: any = await response.json();
            notification.success({ message: body.message })
            actions.actionCheck(Math.random())
        } else {
            notification.error({ message: "Something went wrong" })

        }
    }),

    ledgerList: [],
    fetchledgerList: thunk(async (actions, payload) => {
        const response = await fetchledgerList();
        //console.log(response)
        if (response.status === 200) {
            const body: any = await response.json();
            //console.log(body)
            if (body.messageType == 1) {
                actions.setledgerList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setledgerList([]);
        }
    }),

    setledgerList: action((state, payload) => {
        state.ledgerList = payload;
    }),

    categoryList: [],
    fetchcategoryList: thunk(async (actions, payload) => {
        const response = await fetchcategoryList();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setcategoryList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setcategoryList([]);
        }
    }),

    setcategoryList: action((state, payload) => {
        state.categoryList = payload;
    }),

    receiptledgerlist: [],
    fetchreceiptledgerlist: thunk(async (actions, payload) => {
        const response = await fetchreceiptledgerlist();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setreceiptledgerlist(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setreceiptledgerlist([]);
        }
    }),
    setreceiptledgerlist: action((state, payload) => {
        state.receiptledgerlist = payload;
    }),

    paymentledgerlist: [],
    fetchpaymentledgerlist: thunk(async (actions, payload) => {
        const response = await fetchpaymentledgerlist();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setpaymentledgerlist(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setpaymentledgerlist([]);
        }
    }),
    setpaymentledgerlist: action((state, payload) => {
        state.paymentledgerlist = payload;
    }),

    cashEquivalanceLedgerList: [],
    fetchcashEquivalanceLedgerList: thunk(async (actions, payload) => {
        const response = await fetchcashEquivalanceLedgerList();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setcashEquivalanceLedgerList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setcashEquivalanceLedgerList([]);
        }
    }),

    setcashEquivalanceLedgerList: action((state, payload) => {
        state.cashEquivalanceLedgerList = payload;
    }),

    cashDFPSLedgerList: [],
    fetchcashDFPSLedgerList: thunk(async (actions, payload) => {
        const response = await fetchcashDFPSLedgerList();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setcashDFPSLedgerList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setcashDFPSLedgerList([]);
        }
    }),

    setcashDFPSLedgerList: action((state, payload) => {
        state.cashDFPSLedgerList = payload;
    }),

    allLedgerList: [],
    fetchallLedgerList: thunk(async (actions, payload) => {
        const response = await fetchallLedgerList();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setallLedgerList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setallLedgerList([]);
        }
    }),

    setallLedgerList: action((state, payload) => {
        state.allLedgerList = payload;
    }),    
    
    accountRegularIncomeLedgerList: [],
    fetchaccountRegularIncomeLedgerList: thunk(async (actions, payload) => {
        const response = await fetchaccountRegularIncomeLedgerList();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setaccountRegularIncomeLedgerList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setaccountRegularIncomeLedgerList([]);
        }
    }),

    setaccountRegularIncomeLedgerList: action((state, payload) => {
        state.accountRegularIncomeLedgerList = payload;
    }),

    ofpsLedgerList: [],
    fetchofpsLedgerList: thunk(async (actions, payload) => {
        const response = await fetchofpsLedgerList();
        if (response.status === 200) {
            const body: any = await response.json();
            if (body.messageType == 1) {
                actions.setofpsLedgerList(body.item);
            }
        } else {
            //const body = await response.json();
            actions.setofpsLedgerList([]);
        }
    }),

        setofpsLedgerList: action((state, payload) => {
        state.ofpsLedgerList = payload;
    })

}