import { Avatar, Button, Card, Col, Divider, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { Typography } from 'antd';
import { Line } from '@ant-design/charts';
import { Column } from '@ant-design/plots';
import { ROUTES } from '../../../contents/routes';
import { Link } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
const sayhey = require("sayhey");
export default function Dashboard(props) {

    const { Title } = Typography;
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)
    const fetchInstiuteInfoList = useStoreActions((state) => state.dashboard.fetchInstiuteInfoList);
    const instiuteInfoList = useStoreState((state) => state.dashboard.instiuteInfoList);

    const fetchGenderWiseStaffInfoList = useStoreActions((state) => state.dashboard.fetchGenderWiseStaffInfoList);
    const genderWiseStaffInfoList = useStoreState((state) => state.dashboard.genderWiseStaffInfoList);

    const fetchGenderWiseStudentInfoList = useStoreActions((state) => state.dashboard.fetchGenderWiseStudentInfoList);
    const genderWiseStudentInfoList = useStoreState((state) => state.dashboard.genderWiseStudentInfoList);
    const [chartDataList, setChartDataList] = useState<any>();
    const fetchSmsBlance = useStoreActions((state) => state.common.fetchSmsBlance);
    const smsBlance = useStoreState((state) => state.common.smsBlance);


    useEffect(() => {
        fetchInstiuteInfoList();
        fetchGenderWiseStaffInfoList();
        fetchGenderWiseStudentInfoList();
        console.log('instituteInfo', instituteInfo);
        fetchSmsBlance();
    }, []);

    const myData: any = [];
    useEffect(() => {
        chartsDataInsert(instiuteInfoList)
    }, [instiuteInfoList])

    const chartsDataInsert = (instiuteInfoList) => {
        instiuteInfoList.map(item => {
            myData.push({
                type: item.className,
                value: item.totalStudent,
            })
        });
        setChartDataList(myData);
    }

    return (
        <>
            <Row>
                <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <Card>
                        <Row wrap={true}>
                            <Col flex={1}>
                                <img className="mr-3" src={'data:image/png;base64,' + instituteInfo?.instituteImage} style={{ width: "101px" }} alt="Institute image placeholder" />
                            </Col>
                            <Col flex={16} style={{ fontWeight: "bold" }}>
                                <Title level={4}>Institute ID: {instituteInfo?.instituteId}</Title>
                                <p className="mb-0">{instituteInfo?.instituteName}<br />{instituteInfo?.address}</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12 }}>
                    <Card>
                        <Row wrap={true}>
                            <Col flex={1}>
                                {instituteInfo?.userPhoto === '' ? <Avatar shape="square" size={101} icon={<UserOutlined />} /> : <Avatar shape="square" size={101} src={"data:image/png;base64," + instituteInfo?.userPhoto} />}
                            </Col>
                            <Col flex={16}>
                                <Title level={4} style={{ marginBottom: "0" }}>{instituteInfo?.nickName}</Title>
                                <Title level={5} style={{ marginBottom: "0" }}>{instituteInfo?.contactNumber}, {instituteInfo?.instituteEmail}</Title>
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Row>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Row>
                        <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Card title="Total Students">
                                <Row wrap={true}>
                                    <Col flex={1}>
                                        <div className='pr-3 '>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38.537" height="32.43" viewBox="0 0 38.537 32.43">
                                                <g id="multiple-users-silhouette" transform="translate(0 -6.348)">
                                                    <path id="Path_255" data-name="Path 255" d="M23.255,11.914a7,7,0,0,1,3.259,5.2,5.649,5.649,0,1,0-3.259-5.2Zm-3.7,11.572A5.652,5.652,0,1,0,13.9,17.835,5.652,5.652,0,0,0,19.553,23.486Zm2.4.385h-4.8a7.245,7.245,0,0,0-7.237,7.237v5.865l.015.092.4.126a32.94,32.94,0,0,0,9.839,1.587,20.2,20.2,0,0,0,8.591-1.613l.378-.191h.04V31.109A7.243,7.243,0,0,0,21.95,23.872ZM31.3,18.037H26.542a6.965,6.965,0,0,1-2.15,4.852,8.6,8.6,0,0,1,6.142,8.229v1.807a19.474,19.474,0,0,0,7.585-1.593l.378-.191h.04V25.273A7.244,7.244,0,0,0,31.3,18.037ZM9.635,17.652a5.614,5.614,0,0,0,3.006-.872,7,7,0,0,1,2.629-4.461c.006-.106.016-.211.016-.317a5.651,5.651,0,1,0-5.651,5.651Zm5.076,5.236a6.969,6.969,0,0,1-2.148-4.825c-.177-.013-.351-.027-.531-.027h-4.8A7.245,7.245,0,0,0,0,25.273v5.866l.015.09.4.127a33.929,33.929,0,0,0,8.149,1.532V31.117A8.6,8.6,0,0,1,14.711,22.889Z" fill="#f7894f"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </Col>
                                    <Col flex={6}>
                                        <Title level={3} className='mb-0 text-right'>{genderWiseStudentInfoList?.totalStudent}</Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <Row>
                                <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <Card title="Male Students">
                                        <Row wrap={true}>
                                            <Col flex={1}>
                                                <div className='pr-3 '>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.038" height="40.938" viewBox="0 0 38.038 40.938">
                                                        <g id="Group_386" data-name="Group 386" transform="translate(-292.331 -399.721)">
                                                            <path id="Path_252" data-name="Path 252" d="M311.285,541.776a55.765,55.765,0,0,1-13.627-1.43A12.245,12.245,0,0,1,294.294,539a3.654,3.654,0,0,1-1.766-4.489,18,18,0,0,1,1.217-3.1,7.782,7.782,0,0,1,6.989-4.71c.471-.021.942-.067,1.408-.132a2.589,2.589,0,0,1,2.633.859,6.155,6.155,0,0,0,3.851,1.586,15.6,15.6,0,0,0,6.71-.278,5.245,5.245,0,0,0,3.21-1.95.656.656,0,0,1,.687-.291c1.342.105,2.684.173,4.019.385a7.149,7.149,0,0,1,4.907,3.242,14.625,14.625,0,0,1,2.073,4.689A3.561,3.561,0,0,1,328.377,539a17.475,17.475,0,0,1-6.123,1.95A63.34,63.34,0,0,1,311.285,541.776Z" transform="translate(0 -101.117)" fill="#4791ff"></path>
                                                            <path id="Path_253" data-name="Path 253" d="M344.526,399.721c6.047,0,9.77,3.666,9.862,9.722a6.207,6.207,0,0,0,.417,2.005,5.15,5.15,0,0,1-.956,5.116,4.96,4.96,0,0,0-.743,1.17,14.477,14.477,0,0,1-4.189,5.734c-3.08,2.483-6.817,2.314-9.837-.868a15.658,15.658,0,0,1-3.382-5.219,2.354,2.354,0,0,0-.6-.789,5.433,5.433,0,0,1-.8-5.486,2.031,2.031,0,0,0,.225-1.057,13.515,13.515,0,0,1,.424-3.6C336.132,402.075,339.494,399.719,344.526,399.721Zm8.775,14c-.01-.6.086-1.263-.3-1.367-.941-.257-.82-1.08-1.118-1.673-.273-.544-.653-1.494-1-1.393-.871.249-1.3-.436-1.948-.561a4.734,4.734,0,0,0-1.69-.145,12.339,12.339,0,0,1-6.2-.138,2.653,2.653,0,0,0-3.217.987,6.2,6.2,0,0,0-1.027,1.958c-.083.247-.06.613-.352.69-1.071.283-.758,1.132-.748,1.816.011.751.135,1.66.791,1.985a2.584,2.584,0,0,1,1.216,1.712,10.01,10.01,0,0,0,3.56,4.555c2.223,1.6,4.29,1.525,6.528-.061a11.648,11.648,0,0,0,3.688-5.085c.123-.273.2-.712.4-.783C353.2,415.76,353.094,414.588,353.3,413.725Z" transform="translate(-33.137)" fill="#4791ff"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </Col>
                                            <Col flex={6}>
                                                <Title level={3} className='mb-0 text-right'>{genderWiseStudentInfoList?.male}</Title>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <Card title="Female Students">
                                        <Row wrap={true}>
                                            <Col flex={1}>
                                                <div className='pr-3 '>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.465" height="40.945" viewBox="0 0 37.465 40.945">
                                                        <path id="Path_254" data-name="Path 254" d="M543.121,440.671a53.211,53.211,0,0,1-13.338-1.428,11.427,11.427,0,0,1-3.6-1.556,3.63,3.63,0,0,1-1.6-4.059,13.306,13.306,0,0,1,2.57-5.444,6.5,6.5,0,0,1,3.933-2.487,1.751,1.751,0,0,0,1.4-1.146,10.79,10.79,0,0,0,.714-4.156,37.081,37.081,0,0,0-.414-5.946,17.1,17.1,0,0,1,1.362-9.384,8.43,8.43,0,0,1,8.41-5.332,23.072,23.072,0,0,1,2.928.084,8.591,8.591,0,0,1,7.022,5.84,19.7,19.7,0,0,1,.954,10.246,21.508,21.508,0,0,0,.042,7.352c.266,1.332.733,2.363,2.336,2.605a5.488,5.488,0,0,1,3.234,2.129,13.486,13.486,0,0,1,2.724,5.587,3.714,3.714,0,0,1-1.961,4.356,19,19,0,0,1-7.028,2.1A61.74,61.74,0,0,1,543.121,440.671Zm.214-17.172a4.7,4.7,0,0,0,2.952-1.05,11.258,11.258,0,0,0,3.677-4.969c.133-.3.166-.648.478-.861a3,3,0,0,0,1.2-3.219c-.142-.508-.412-.628-.762-.215-.413.487-.793.305-1.24.073q-4.619-2.4-9.251-4.771a2.477,2.477,0,0,0-3.193.542,6.339,6.339,0,0,0-1.447,3.07c-.053.219,0,.463-.213.647-.965.83-1.063,2.953-.016,3.612a3.014,3.014,0,0,1,1.187,1.611,10.657,10.657,0,0,0,1.722,2.841C539.705,422.294,541.159,423.535,543.335,423.5Zm4.835-.365a7.406,7.406,0,0,1-4.976,2.221,7.761,7.761,0,0,1-4.963-2.214,2.529,2.529,0,0,1-1.335,1.71c-.576.279-.545.6-.19,1.008a6.7,6.7,0,0,0,.629.655,9.013,9.013,0,0,0,11.619.071,6.326,6.326,0,0,0,.826-.882c.237-.3.291-.59-.154-.8A2.919,2.919,0,0,1,548.17,423.133Z" transform="translate(-524.464 -399.729)" fill="#eb4887"></path>
                                                    </svg>
                                                </div>
                                            </Col>
                                            <Col flex={6}>
                                                <Title level={3} className='mb-0 text-right'>{genderWiseStudentInfoList?.female}</Title>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <Card title="Department Wise Student">
                                <Column
                                    data={chartDataList ? chartDataList : ""}
                                    height={393}
                                    xField="type"
                                    yField="value"
                                    color='blue'
                                />
                            </Card>
                        </Col>
                        <Col className='mb-20' xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }}>
                            <Card className='mb-20' title="Total HR">
                                <Row wrap={true}>
                                    <Col flex={1}>
                                        <div className='pr-3 '>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60.592" height="39.83" viewBox="0 0 60.592 39.83">
                                                <g id="Group_428" data-name="Group 428" transform="translate(7.192 -27.572)">
                                                    <g id="Group_393" data-name="Group 393" transform="translate(29.574 32.963)">
                                                        <g id="Group_390" data-name="Group 390" transform="translate(0 0)">
                                                            <path id="Path_261" data-name="Path 261" d="M-619.888-43.6l-1.734,4.387a.983.983,0,0,0,.876-.006c1.09-.383,1.65.126,2.2,1.015a43.8,43.8,0,0,0,3.3,4.59c-.361-1.28-.718-2.562-1.085-3.84-.486-1.691-.974-3.383-1.476-5.069a6.274,6.274,0,0,1-.374-2.549c.069-.578-.172-.72-.725-.735a6.867,6.867,0,0,1-4.7-1.632c1.709-.981,1.941-2.646,2.116-4.319a54.765,54.765,0,0,1,.353-5.616c.653-3.134,2.923-5.135,5.891-4.865a15.024,15.024,0,0,1,4.955,1.725c1.237.591,1.545,2.02,1.792,3.317a15.293,15.293,0,0,1,.108,4.319,9.266,9.266,0,0,0,.037,2.332,4,4,0,0,0,1.943,3.076,3.5,3.5,0,0,1-1.714,1.087,9.182,9.182,0,0,1-3.053.563c-.46.009-.63.177-.576.616a8,8,0,0,1-.606,3.378c-.847,2.723-1.693,5.447-2.408,8.251.41-.585.825-1.166,1.229-1.755.768-1.119,1.556-2.226,2.283-3.372.338-.533.68-.79,1.332-.584a8.863,8.863,0,0,0,1.427.194l-1.85-4.721c1.768.972,3.573,1.181,5.177,1.968a3.741,3.741,0,0,1,1.892,4.476c-.1.272-.346.38-.549.529a18.551,18.551,0,0,1-10.029,3.667,18.889,18.889,0,0,1-12.353-3.557,1.447,1.447,0,0,1-.7-1.345c.025-2.581.424-3.193,2.814-4.148C-622.762-42.76-621.3-42.957-619.888-43.6Z" transform="translate(626.914 62.265)" fill="#39ab41"></path>
                                                        </g>
                                                    </g>
                                                    <g id="businessman" transform="translate(-44.95 32.963)">
                                                        <g id="Group_392" data-name="Group 392" transform="translate(37.758 0)">
                                                            <path id="Path_262" data-name="Path 262" d="M135.072,12.424a6.2,6.2,0,0,0,3.81,4.72,4.175,4.175,0,0,0,2.8-.006,6.312,6.312,0,0,0,3.822-4.714c.291-.024.673-.431,1.086-1.893.564-2-.036-2.293-.546-2.245a5.291,5.291,0,0,0,.218-.819c.862-5.175-1.687-5.351-1.687-5.351A3.93,3.93,0,0,0,143.038.69a5.184,5.184,0,0,0-3.149-.667,4.7,4.7,0,0,0-1.256.237h0a5.519,5.519,0,0,0-1.383.71,7.252,7.252,0,0,0-1.42,1.177,5.762,5.762,0,0,0-1.535,2.694,5.849,5.849,0,0,0,.012,2.633h0a5.117,5.117,0,0,0,.218.819c-.51-.049-1.11.249-.546,2.245C134.4,11.994,134.78,12.4,135.072,12.424Z" transform="translate(-127.928 0)" fill="#39ab41"></path>
                                                            <path id="Path_263" data-name="Path 263" d="M60.125,277.034a19.243,19.243,0,0,1-5.618-2.56l-1.966,6.219-.37,1.171-.006-.018-.322,1L50.805,279.9c2.548-3.555-.516-3.519-.686-3.513s-3.234-.042-.686,3.513L48.4,282.841l-.322-1-.006.018-.37-1.171-1.972-6.219a19.243,19.243,0,0,1-5.618,2.56c-2.312.589-2.421,3.258-2.33,4.575,0,0,.133,1.79.267,2.579,0,0,4.514,2.93,12.074,2.937s12.074-2.937,12.074-2.937c.133-.789.267-2.579.267-2.579C62.546,280.293,62.436,277.623,60.125,277.034Z" transform="translate(-37.758 -257.821)" fill="#39ab41"></path>
                                                        </g>
                                                    </g>
                                                    <g id="businessman-2" data-name="businessman" transform="translate(-30.831 27.571)">
                                                        <g id="Group_392-2" data-name="Group 392" transform="translate(37.758 0)">
                                                            <path id="Path_262-2" data-name="Path 262" d="M135.546,16.888a8.423,8.423,0,0,0,5.179,6.416,5.675,5.675,0,0,0,3.8-.008,8.58,8.58,0,0,0,5.2-6.408c.4-.033.915-.586,1.476-2.573.767-2.713-.049-3.117-.742-3.051a7.191,7.191,0,0,0,.3-1.113c1.171-7.035-2.293-7.274-2.293-7.274A5.342,5.342,0,0,0,146.374.938a7.047,7.047,0,0,0-4.28-.907,6.391,6.391,0,0,0-1.707.322h0a7.5,7.5,0,0,0-1.88.965,9.856,9.856,0,0,0-1.93,1.6,7.832,7.832,0,0,0-2.086,3.662,7.95,7.95,0,0,0,.016,3.579h0a6.955,6.955,0,0,0,.3,1.113c-.693-.066-1.509.338-.742,3.051C134.63,16.3,135.15,16.855,135.546,16.888Z" transform="translate(-125.835 0)" fill="#39ab41"></path>
                                                            <path id="Path_263-2" data-name="Path 263" d="M68.16,277.954a26.157,26.157,0,0,1-7.637-3.48l-2.672,8.453-.5,1.592-.008-.025-.437,1.353-1.41-4c3.464-4.833-.7-4.783-.932-4.775-.231-.008-4.4-.058-.932,4.775l-1.41,4-.437-1.353-.008.025-.5-1.592-2.68-8.453a26.157,26.157,0,0,1-7.637,3.48c-3.142.8-3.291,4.429-3.167,6.218,0,0,.181,2.433.363,3.5,0,0,6.136,3.983,16.411,3.992s16.411-3.992,16.411-3.992c.181-1.072.363-3.5.363-3.5C71.451,282.383,71.3,278.754,68.16,277.954Z" transform="translate(-37.758 -251.838)" fill="#39ab41"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </Col>
                                    <Col flex={6}>
                                        <Title level={3} className='mb-0 text-right'>{genderWiseStaffInfoList?.totalStaff}</Title>
                                    </Col>
                                </Row>
                            </Card>
                            <Card className='mb-20' title="SMS Summary">
                                <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                    <strong>{smsBlance}</strong>
                                    <Button type="primary" >
                                        <Link to={ROUTES.SMS_PURCHASE}> Purchase SMS </Link>
                                    </Button>
                                </div>
                            </Card>
                            <Card className='mb-20' title="" style={{ background: "#4a1185", color: "#fff" }}>
                                <Row wrap={true}>
                                    <Col flex={1}>
                                        <div className='pr-3 '>
                                            <svg id="headphones" xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67">
                                                <g id="Group_410" data-name="Group 410" transform="translate(0 23.555)">
                                                    <g id="Group_407" data-name="Group 407">
                                                        <path id="Path_275" data-name="Path 275" d="M0,257.2v7.852a7.2,7.2,0,0,0,7.2,7.2h3.926a1.963,1.963,0,0,0,1.963-1.963V250H7.2A7.2,7.2,0,0,0,0,257.2Z" transform="translate(0 -240.84)" fill="#fff"></path>
                                                        <path id="Path_276" data-name="Path 276" d="M185.863,195.049V184.58a4.585,4.585,0,0,0-4.58-4.58h-15.7a4.585,4.585,0,0,0-4.58,4.58v10.469a4.585,4.585,0,0,0,4.58,4.58H170.1l7.306,6.088a1.963,1.963,0,0,0,3.22-1.508v-4.58h.654A4.585,4.585,0,0,0,185.863,195.049Zm-9.814-3.271h-5.234a1.963,1.963,0,0,1,0-3.926h5.234a1.963,1.963,0,0,1,0,3.926Z" transform="translate(-139.932 -180)" fill="#fff"></path>
                                                        <path id="Path_277" data-name="Path 277" d="M201,455.926a3.926,3.926,0,0,0,3.926,3.926h6.543a3.926,3.926,0,0,0,0-7.852h-6.543A3.926,3.926,0,0,0,201,455.926Z" transform="translate(-174.697 -416.406)" fill="#fff"></path>
                                                    </g>
                                                    <g id="Group_409" data-name="Group 409" transform="translate(44.337 9.16)">
                                                        <g id="Group_408" data-name="Group 408" transform="translate(0 26.172)">
                                                            <path id="Path_278" data-name="Path 278" d="M349.7,450v.393a1.965,1.965,0,0,1-1.963,1.963h-8.855a7.81,7.81,0,0,1-.068,3.926h8.922a5.9,5.9,0,0,0,5.889-5.889V450Z" transform="translate(-338.817 -450)" fill="#fff"></path>
                                                        </g>
                                                        <path id="Path_279" data-name="Path 279" d="M417.889,250H412v20.283a1.963,1.963,0,0,0,1.963,1.963h3.926a7.2,7.2,0,0,0,7.2-7.2V257.2A7.2,7.2,0,0,0,417.889,250Z" transform="translate(-402.423 -250)" fill="#fff"></path>
                                                    </g>
                                                </g>
                                                <path id="Path_280" data-name="Path 280" d="M88.252,8.279A28.266,28.266,0,0,0,40,28.266v.523h7.852v-.523a20.414,20.414,0,1,1,40.828,0v.523h7.852v-.523A28.081,28.081,0,0,0,88.252,8.279Z" transform="translate(-34.766)" fill="#fff"></path>
                                            </svg>
                                        </div>
                                    </Col>
                                    <Col flex={6}>
                                        <p className='mb-0 text-light'>Facing Difficulties? Call our customer support.</p>
                                        <Title level={3} className='mb-0 text-light' style={{ color: "#fff" }}>09612191919</Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
