import React, { useCallback, useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Badge } from 'antd';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectDepartment } from '../../../select/SelectDepartment';
import Table from "../../../../contents/AntTableResponsiveSearch";
import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';



export default function ClassTypeUpdate() {
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const shiftList = useStoreState((state) => state.student.shiftList);
    const semesterYearByDepartmentId = useStoreState((state) => state.student.semesterYearByDepartmentId);
    const getSemeterYear = useStoreActions((state) => state.student.fetchSemesterYearByDepartment);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const [selectedSessionYear, setselectedSessionYear] = useState();
    const [selectedClassDepartment, setSelectedClassDepartment] = useState();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const deleteStudentRegistration = useStoreActions((state) => state.student.deleteStudentRegistration);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchStudentListByClassConfigIdSessionYearSemesterYear = useStoreActions((state) => state.student.fetchStudentListByClassConfigIdSessionYearSemesterYear);
    const studentListByClassConfigIdSessionYearSemesterYear = useStoreState((state) => state.student.studentListByClassConfigIdSessionYearSemesterYear);
    const updateStudentOtherInfo = useStoreActions((state) => state.student.updateStudentOtherInfo);
    const [selectDisableStatus, setSelectDisableStatus] = useState(false);
    const [selectDisableReasonText, setSelectDisableReasonText] = useState(false);
    const [updateForm] = Form.useForm();
    useEffect(() => {
        fetchClassConfigList();
        fetchSessionList();
    }, []);

    const getSemesterYear = (value) => {
        setSelectedClassDepartment(value);
        getSemeterYear(value);
    }

    const getStudentReportList = (value) => {
        setSelectedSemesterYear(value);
    }

    const onFinish = (value) => {
        fetchStudentListByClassConfigIdSessionYearSemesterYear(value);
    }
    const [tableRowStore, setTableRowStore] = useState([]);

    useEffect(() => {
        setTableRowStore(studentListByClassConfigIdSessionYearSemesterYear);
    }, [studentListByClassConfigIdSessionYearSemesterYear]);


    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                     <Input placeholder="Student Roll" value={record.studentRoll} name="studentRoll" className="studentRoll" onChange={onchangeValueTable("studentRoll", record, record.index)} />
            ),
        },
        {
            title: 'Student Type',
            dataIndex: 'studentType',
            key: 'studentType',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div className="select-box">
                    <select
                        id="select-box1"
                        value={record.studentType}
                        onChange={onchangeValueTable("studentType", record, record.index)}
                        className='tableDropDown'
                    >
                        <option value={1}>Regular</option>
                        <option value={2}>Irregular</option>
                        <option value={3}>Improved</option>

                    </select>
                </div>
            ),
        },        
        {
            title: 'Disable Status',
            dataIndex: 'disableStatus',
            key: 'disableStatus',
            showOnResponse: true,
            showOnDesktop: true,
            
            render: (text, record, index) => (
                <div className="select-box">
                    <select
                        id="select-box1"
                        value={record.disableStatus}
                        onChange={onchangeValueTable("disableStatus", record, record.index)}
                        className='tableDropDown'
                    >
                        <option value={false}>Active</option>
                        <option value={true}>Inactive</option>

                    </select>
                </div>
            ),
        },
        {
            title: 'Disable Reason',
            dataIndex: 'disableReason',
            key: 'disableReason',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div>
                    <p>{record.disableStatus ? 
                     <Input placeholder="Disable Reason" value={record.disableReason} name="disableReason" className="disableReason" onChange={onchangeValueTable("disableReason", record, record.index)} />
                    : 'N/A'}</p>
                </div>
            ),
        },
        {
            title: 'Class Type',
            dataIndex: 'classType',
            key: 'classType',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text, record, index) => (
                <div className="select-box">
                    <select
                        id="select-box1"
                        value={record.classType}
                        onChange={onchangeValueTable("classType", record, record.index)}
                        className='tableDropDown'
                    >
                        <option value={1}>New</option>
                        <option value={2}>Old</option>

                    </select>
                </div>
            ),
        },
        // {
        //     title: 'Identification Id',
        //     dataIndex: 'identificationId',
        //     key: 'identificationId',
        //     showOnResponse: true,
        //     showOnDesktop: true,
        // },

    ];

    const onchangeValueTable =
        useCallback((key, data, index) => (e) => {
            console.log(e)
            //console.log(e);
            const newData = [...tableRowStore];
            newData[index][key] = e.target.value;
            setTableRowStore(newData);
        }, [tableRowStore]);

    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [selectedValue, setselectedValue] = useState([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // const updateOtherInfoForm = (value) => {
    //     let postData = [
    //         {
    //             studentType: value.studentType,
    //             disableStatus: value.disableStatus,
    //             disableReason: value.disableReason ? value.disableReason : '',
    //             classType: value.classType,
    //             identificationId: value.identificationId
    //         }
    //     ]
    //     updateStudentOtherInfo(postData);
    //     let getData = {
    //         classDeptConfId: selectedClassDepartment,
    //         semesterYear: selectedSemesterYear,
    //         sessionId: selectedSessionYear,
    //     }
    //     setIsModalVisible(false);
    //     setSelectDisableStatus(false)
    //     fetchStudentListByClassConfigIdSessionYearSemesterYear(getData);
    // }

    // const selectDisableStatusOnChange = (value) => {
    //     setSelectDisableStatus(value);
    // }

    const ondelete = () => {
        if (selectedValue.length === 0) {
            message.error('Please select atleast one student');
        }
        let data = selectedValue.map(item => item.identificationId).join(',');
        console.log(data);
        let getData = {
            classDeptConfId: selectedClassDepartment,
            semesterYear: selectedSemesterYear,
            sessionId: selectedSessionYear,
        }
        let postData = {
            ids: data,
            infos: getData
        }
        deleteStudentRegistration(postData)
        setselectedRowKeys([]);
        setselectedValue([]);
    }   
    
    const onUpdate = () => {
        if (selectedValue.length === 0) {
            message.error('Please select atleast one student');
            return
        }
     
        let postData = selectedValue.map(item => {
            return {
                identificationId: item.identificationId,
                studentType: item.studentType,
                disableStatus: item.disableStatus,
                disableReason: item.disableReason ? item.disableReason : '',
                classType: item.classType,
                studentRoll: item.studentRoll,
            }
        })
        updateStudentOtherInfo(postData);
       
        let getData = {
            classDeptConfId: selectedClassDepartment,
            semesterYear: selectedSemesterYear,
            sessionId: selectedSessionYear,
        }
        //console.log(getData);
        setTimeout(() => {
            fetchStudentListByClassConfigIdSessionYearSemesterYear(getData);  
        }, 1000);
    
        setselectedRowKeys([]);
        setselectedValue([]);
    }

    return (
        <>
            <Card title="Other Info Update">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="sessionId"
                                        label="Session"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select session" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select session"
                                            onChange={(e) => setselectedSessionYear(e)}
                                        >
                                            {sessionList ? (
                                                sessionList.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Session</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="classDeptConfId"
                                        label="Class Department"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select class-department" },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select class-department"
                                            onChange={(e) => getSemesterYear(e)}
                                        >
                                            {classConfigList ? (
                                                classConfigList.map((type, idx) => (
                                                    <Option key={type.configId} value={type.configId}>
                                                        {type.configName}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                    <Form.Item
                                        name="semesterYear"
                                        label="Semester Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select semester year" },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select semester year"
                                            optionFilterProp="children"
                                            onChange={(e) => getStudentReportList(e)}
                                        >
                                            {semesterYearByDepartmentId ? (
                                                semesterYearByDepartmentId.map((type, idx) => (
                                                    <Option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </Option>
                                                ))
                                            ) : (
                                                <Option value="fetching">Fetching Class</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="m-t-mo-30" style={{ display: tableRowStore.length > 0 ? 'block' : 'none' }}>
                    <Col span="24">
                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: tableRowStore,
                                filterData: tableRowStore,
                                pagination: true,
                                bordered: true,
                                rowKey: "studentId",
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                    </Col>
                    <Space size={"small"} style={{ float: "right", marginTop: 10 }} >
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => ondelete()}
                        >
                            <Button type="primary" icon={<DeleteOutlined />} > Delete</Button>
                        </Popconfirm>
                        <Button type="primary" onClick={() => onUpdate()}  icon={<SaveOutlined />} > Updte</Button>
                    </Space>
                </Row>
  
            </Card>
        </>
    )
}