import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Select, Space } from 'antd';
import { DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { Excel } from 'antd-table-saveas-excel';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import $ from 'jquery';
require('jspdf-autotable');
const getColumns = function () {
    return [
        { title: "Student Id", dataKey: "customStudentId" },
        { title: "Student Name", dataKey: "studentName" },
        { title: "Mobile", dataKey: "studentMobile" },
        { title: "Date of Birth", dataKey: "dob" },
        { title: "Father Name", dataKey: "fatherName" },
        { title: "Mother Name", dataKey: "motherName" },
        { title: "Religion", dataKey: "religion" },
        { title: "Gender", dataKey: "gender" },
    ];
};



export default function ClassDepartmentSessionNo() {

    const { Option } = Select;
    const [searchStudentForm] = Form.useForm();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);
    const fetchClassConfigList = useStoreActions((state) => state.student.fetchClassConfigList);
    const classConfigList = useStoreState((state) => state.student.classConfigList);
    const fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot = useStoreActions((state) => state.student.fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot);
    const fetchShiftList = useStoreActions((state) => state.student.fetchShiftList);
    const studentBasicInfosBySesssionAndClassDepartHasGoneOrNot = useStoreState((state) => state.student.studentBasicInfosBySesssionAndClassDepartHasGoneOrNot);

    useEffect(() => {
        fetchSessionList();
        fetchClassConfigList();
        fetchShiftList();
    }, []);

    const searchStudentList = (value) => {
        let payload = {
            classDeptConfId: value.classDepartment,
            sessionId: value.sessionYearName,
            hasGone: 'no',
        };

        fetchstudentBasicInfosBySesssionAndClassDepartHasGoneOrNot(payload);
        // setCurrentPayload(payload);
    }
    let sessionYearName = $(".sessionYearName").text();
    let classDepartment = $(".classDepartment").text();

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");
        var details = `Current Student List of Session: ${sessionYearName} & Class Department: ${classDepartment}`;
        pdfDataL(doc, "");
        doc.text(details, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };

        doc.autoTable(getColumns(), studentBasicInfosBySesssionAndClassDepartHasGoneOrNot, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            headerStyles: {
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                ledgerName: {
                    halign: "left",
                    fontSize: 8,
                    columnWidth: 75
                },
                debit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                },
                credit: {
                    halign: "right",
                    fontSize: 8,
                    columnWidth: 53.4
                }
            },

        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Mobile',
            dataIndex: 'studentMobile',
            key: 'studentMobile',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Father Name',
            dataIndex: 'fatherName',
            key: 'fatherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Mother Name',
            dataIndex: 'motherName',
            key: 'motherName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Religion',
            dataIndex: 'religion',
            key: 'religion',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            showOnResponse: true,
            showOnDesktop: true,
        }

    ];



    return (
        <>

            <Row>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                    <Form
                        layout="vertical"
                        id="sessionYearInfo"
                        onFinish={searchStudentList}
                        form={searchStudentForm}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="sessionYearName"
                                    label="Session Year"

                                    rules={[
                                        { required: true, message: "Please write session year" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select session"
                                        className="sessionYearName"
                                    >
                                        {sessionList ? (
                                            sessionList.map((type, idx) => (
                                                <Option key={type.id} value={type.id}>
                                                    {type.name}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Session</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                <Form.Item
                                    name="classDepartment"
                                    label="Class Department"

                                    rules={[
                                        { required: true, message: "Select class department" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Class Department"
                                        className="classDepartment"
                                    >
                                        {classConfigList ? (
                                            classConfigList.map((type, idx) => (
                                                <Option key={type.configId} value={type.configId}>
                                                    {type.configName}
                                                </Option>
                                            ))
                                        ) : (
                                            <Option value="fetching">Fetching Class</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Space size="small" >
                                    <Button type="primary" id="searchButton" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <div >
                <Row className="m-t-mo-30">
                    <Col span="24">
                        {studentBasicInfosBySesssionAndClassDepartHasGoneOrNot !== null && studentBasicInfosBySesssionAndClassDepartHasGoneOrNot.length > 0 &&
                            <>
                                <Table
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: studentBasicInfosBySesssionAndClassDepartHasGoneOrNot,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "studentId",
                                        searchable: true,
                                    }}
                                    mobileBreakPoint={768}

                                />
                                <Space size="middle" style={{ float: "right", marginTop: 15 }}>
                                    < Button
                                        type='primary'
                                        icon={<FileExcelOutlined />}
                                        onClick={() => {
                                            const excel: any = new Excel();
                                            excel
                                                .addSheet(`Sheet 1`)
                                                .addColumns(columns)
                                                .addDataSource(studentBasicInfosBySesssionAndClassDepartHasGoneOrNot)
                                                .saveAs(`Current Student List of Session: ${sessionYearName} & Class Department: ${classDepartment}.xlsx`);
                                        }}
                                    >
                                        Download Excel
                                    </ Button >
                                    <Button type="primary" onClick={exportPdf} icon={<DownloadOutlined />} >Download</Button>

                                </Space>
                            </>
                        }
                    </Col>
                </Row>
            </div>

        </>
    )
}