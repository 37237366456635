export const ROUTES = {
  DEFAULT: "/",
  
  LEDGER_LIST: "ledger-list",
  RECEIVE_TRN_TRANSACTION: "receive-trn-transaction",
  PAYMENT_TRN_TRANSACTION: "payment-trn-transaction",
  CONTRA_TRN_TRANSACTION: "contra-trn-transaction",
  JOURNAL_TRN_TRANSACTION: "journal-trn-transaction",
  DELETE_VOUCHER_GAVOUCHER: "delete-voucher-gavoucher",
  TRIAL_BALANCE_GAREPORT: "trial-balance-gareport",
  BALANCE_SHEET_GAREPORT: "balance-sheet-gareport",
  INCOME_STATEMENT_GAREPORT: "income-statement-gareport",
  JOURNAL_GAREPORT: "journal-gareport",
  FUNDS_FLOW_GAREPORT: "funds-flow-gareport",
  CASH_SUMMARY_GAREPORT: "cash-summary-gareport",
  ACCOUNT_BOOK: "account-book-gareport",
  STUDENT_WRAPPER: "student-setting",
  STUDENT_CLASS_CONFIG: "initial-setup-class-configuration",
  CLASS_SEMESTER_CONFIG: "initial-setup-semester-year-configuration",
  STUDENT_MULTIPLE_REGISTRATION: "student-multiple-registration",
  STUDENT_BASIC_UPDATE: "student-basic-update",
  STUDENT_CLASS_CONFIG_UPDATE: "student-class-config-update",
  STUDENT_CLASS_SESSION_YEAR: "student-session-year-update",
  STUDENT_SHIFT_UPDATE: "student-shift-update",
  STUDENT_ADDRESS_UPDATE: "student-address-update",
  STUDENT_OTHER_INFO_UPDATE: "student-other-info-update",
  STUDENT_CLASS_TYPE_UPDATE: "student-class-type-update",
  STUDENT_MIGRATION: "student-migration",
  STUDENT_MULTIPLE_REGISTRATION_EXCEL: "student-multiple-registration-excel",
  STUDENT_REPORT_CLASS_DEPARTMENT_SESSION: "student-report-class-department-session",
  STUDENT_REPORT_CLASS_DEPARTMENT_SEMESTER: "student-report-class-department-semester",

  STAFF_SETTINGS: "staff-settings",
  STAFF_SINGLE_REGISTRATION: "staff-single-registration",
  STAFF_MULTIPLE_REGISTRATION: "staff-multiple-registration",
  STAFF_UPDATE: "staff-update-update",
  STAFF_ASSIGN: "staff-assign",
  
  FEE_HEAD: "fee-head-settings",
  FEE_HEAD_WAIVER: "fee-head-waiver-settings",
  FEE_HEAD_LEDGER_CONFIGUARTION: "fee-head-ledger-configuration",
  FEE_FINE_LEDGER_CONFIGUARTION: "fee-fine-ledger-configuration",
  FEE_AMOUNT_CONFIGUARTION: "fee-amount-configuration",
  FEE_WAIVE_CONFIGUARTION: "fee-waive-configuration",
  FEE_HEAD_DELETE_CONFIGUARTION: "fee-head-delete-configuration",
  FEE_COLLECTION_MANUAL: "fee-collection-manual",
  HEAD_COLLECTION_REPORT: "head-collection-report",
  CLASS_DEPARTMENT_PAID_REPORT: "class-department-paid-report",
  SEMESTER_YEAR_PAID_REPORT: "semester-year-paid-report",
  LEDGER_BY_PAID_REPORT: "ledger-by-paid-report",
  VOUCHER_DELETE: "voucher-delete",
  OFPS_ACCOUNT_CONFIG: "ofps-account-configuration",
  SPG_STATEMENT: "spg-statement",
  STUDENT_FEE_DUE_REPORT: "student-fee-due-report",

  PAY_SLIP_CREATE: "payslip-create",
  PAY_SLIP_CREATE_SINGLE: "payslip-create-single",
  PAY_SLIP_COLLECT: "payslip-collect",
  PAY_SLIP_TEMPLATE: "payslip-template",
  PAYSLIP_DATE_UPDATE:"payslip-date-update",

  INSTITUTE_SETTINGS: "institute-settings",
  INSTITUTE_LIST: "institute-list",
  INSTITUTE_GO: "institute-go",
  OFPS_PROBLEM:"ofps-problem",
  INSTITUTE_STUDENT_COUNT:"institute-student-count",

  SMS_SEND_STUDENT: "sms-send-student",
  SMS_SEND_STAFF: "sms-send-staff",
  SMS_PURCHASE:"purchase-sms",

  PAYABLE_BILL_LIST:"payable-bill",
  PAID_BILL_LIST:"paid-bill",
 
  USER_LIST: "user-list",
};
