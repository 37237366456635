import { notification } from 'antd';
import { Action, Thunk, thunk, action } from 'easy-peasy';
import {  dashboardInfoUrl, dashboardGenderWiseStaffInfoUrl, dashboardGenderWiseStudentInfoUrl } from '../../../http/dashboard/dashboard';
import FileSaver from 'file-saver'
export interface Dashboard {

    ///
    fetchInstiuteInfoList: Thunk<Dashboard>;
    setInstiuteInfoList: Action<Dashboard, any>;
    instiuteInfoList:any;

    ///
    fetchGenderWiseStaffInfoList: Thunk<Dashboard>;
    setGenderWiseStaffInfoList: Action<Dashboard, any>;
    genderWiseStaffInfoList:any;

    ///
    fetchGenderWiseStudentInfoList: Thunk<Dashboard>;
    setGenderWiseStudentInfoList: Action<Dashboard, any>;
    genderWiseStudentInfoList:any;
}

export const dashboardStore: Dashboard = {
	
    instiuteInfoList:[],
    genderWiseStaffInfoList:[],
    genderWiseStudentInfoList:[],

    fetchInstiuteInfoList:thunk(async (actions) => {
        const response = await dashboardInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setInstiuteInfoList(body.item);
            
        } else {
            const body = await response.json();
            //notification.error({ message: body.message })
        }
    }),
    setInstiuteInfoList: action((state, payload) => {
        state.instiuteInfoList = payload
    }),

    fetchGenderWiseStaffInfoList:thunk(async (actions) => {
        const response = await dashboardGenderWiseStaffInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setGenderWiseStaffInfoList(body.item);
            
        } else {
            const body = await response.json();
            //notification.error({ message: body.message })
        }
    }),
    setGenderWiseStaffInfoList: action((state, payload) => {
        state.genderWiseStaffInfoList = payload
    }),

    fetchGenderWiseStudentInfoList:thunk(async (actions) => {
        const response = await dashboardGenderWiseStudentInfoUrl();
        if (response.status === 201 || response.status === 200) {
            const body = await response.json();
            actions.setGenderWiseStudentInfoList(body.item);
            
        } else {
            const body = await response.json();
            //notification.error({ message: body.message })
        }
    }),
    setGenderWiseStudentInfoList: action((state, payload) => {
        state.genderWiseStudentInfoList = payload
    }),
}
