import { post, get, del} from "../http";


export const createClassWisePayslip = (payload) => post("/payslip/create/class-wise", payload);
export const collectPayslip = (payload) => post("/payslip/collect", payload);

export const fetchSinglePaySlipOld = (payload) => get(`/payslip/report/list/single?customStudentId=${payload?.customStudentId}&semesterYearId=${payload?.semesterYearId}`);
export const fetchSinglePaySlip = (payload) => get(`/payslip/report/paid-unpaid/list/single?customStudentId=${payload?.customStudentId}&semesterYearId=${payload?.semesterYearId}&payslipStatus=${payload?.payslipStatus}`);
export const fetchMultiPlePaySlip = (payload) => get(`/payslip/report/list/class-department?classDepartmentConfigId=${payload.classDepartmentConfigId}&semesterYearId=${payload.semesterYearId}&sessionId=${payload.sessionId}`);
export const deletePayslip = (payload) => del("/payslip/delete/single?payslipId="+payload);
export const deletePayslipPaid = (payload) => del("/payslip/delete/paid/single?payslipId="+payload);

export const fetchdateToDateFeeHeadCollection = (payload) => get(`/student/accounts-report/date-to-date/fee-head/collection?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchclassDepartmentDateToDateInvoiceList = (payload) => get(`/student/accounts-report/invoice/list/class-department?classDepartmentConfigId=${payload.classDepartmentConfigId}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchledgerByDateToDateInvoiceList = (payload) => get(`/student/accounts-report/invoice/list/ledger-by?fromDate=${payload.fromDate}&toDate=${payload.toDate}&ledgerIds=${payload.ledgerIds}`);
export const downloadPayslip = (payload) => get(`/payslip/download/single?payslipIds=${payload}`);


export const fetchdateToDateStudentFeeHeadCollection = (payload) => post("/student/accounts-report/date-to-date/student/fee-head/collection", payload);
export const fetchshowStudentFeeHeadDue = (payload) => post("/student/accounts-report/student/fee-head/due", payload);

export const fetchdefaultPayslipTemplateList = () => get(`/payslip/template/default/list`);
export const fetchviewPayslipTemplate = () => get(`/payslip/template/view`);
export const savePayslipTemplate = (payload) => post(`/payslip/template/save?payslipDefaultId=${payload}`);


export const fetchviewByPayslipId = (payload) => get(`/payslip/report/view/by/payslip-id?payslipId=${payload}`);
export const savepayslipUpdateDate = (payload) => post(`/payslip/update/date?payslipDate=${payload?.payslipDate}&payslipId=${payload?.payslipId}`);
export const fetchonlyDateToDateStudentFeeHeadCollection = (payload) => post(`/student/accounts-report/only/date-to-date/student/fee-head/collection?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);