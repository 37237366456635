import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { DeleteOutlined, EditOutlined, PayCircleOutlined, SearchOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';

const { Option } = Select;
var year = (new Date().getFullYear()) * 1;

export default function PaidBill() {

    const fetchMasterSettingPaidList = useStoreActions<any>((state) => state.common.fetchMasterSettingPaidList);
    const masterSettingPaidList = useStoreState((state) => state.common.masterSettingPaidList);
    const loading = useStoreState((state) => state.common.loading);
    const instituteInfo = useStoreState(state => state.auth.instituteInfo)

    let columns = [
        { title: 'Year', dataIndex: 'billingYear', key: 'billingYear', showOnResponse: true, showOnDesktop: true },
        { title: 'Month', dataIndex: 'billingMonth', key: 'billingMonth', showOnResponse: true, showOnDesktop: true },
        { title: 'Billing Cycle', dataIndex: 'billingCycle', key: 'billingCycle', showOnResponse: true, showOnDesktop: true },
        { title: 'Paid Amount', dataIndex: 'paidAmount', key: 'paidAmount', showOnResponse: true, showOnDesktop: true },
        { title: 'Generated Date', dataIndex: 'generateDate', key: 'generateDate', showOnResponse: true, showOnDesktop: true },
        // {title: 'Action', dataIndex: 'status', key: uuidv4(), showOnResponse: true, showOnDesktop: true, render: (text: any, record: any, index) => (
        //     <>
        //         <Button type='primary' icon={<PayCircleOutlined />} onClick={() => payableAmountMethod(record.billId)} />
        //     </>
        // )}
    ];


    const [onsubmitForm] = Form.useForm();

    const createSubmitForm = (value) => {
        let payload = {
            clientProvideId: instituteInfo?.instituteId,
            year: value.year,
        };
        fetchMasterSettingPaidList(payload);
    };


    return (
        <>

            <Card title="Paid Bill List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 8 }} xl={{ span: 12, offset: 8 }}>
                        <Form
                            layout="vertical"
                            id="sessionInfo"
                            onFinish={createSubmitForm}
                            form={onsubmitForm}
                        >
                            <Row>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                    <Form.Item
                                        name="year"
                                        label="Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >

                                        <Select placeholder="Select Year" allowClear>
                                            <Option value={year - 1}>{year - 1}</Option>
                                            <Option value={year}>{year}</Option>
                                            <Option value={year + 1}>{year + 1}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                        {!loading &&
                            <div className="datatable-responsive-demo">
                                {masterSettingPaidList?.length > 0 &&
                                    <TableView
                                        antTableProps={{
                                            showHeader: true,
                                            columns,
                                            dataSource: masterSettingPaidList,
                                            filterData: masterSettingPaidList,
                                            pagination: true,
                                            bordered: true,
                                            rowKey: "id",
                                        }}
                                        mobileBreakPoint={768}
                                    />
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    )
}