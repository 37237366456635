import { post, get, del} from "../http";

export const fetchfeeHeadList = () => get("/initial-setup/feehead/list");
export const createFeeHead = (payload) => post("/initial-setup/feehead/create", payload);
export const updateFeeHead = (payload) => post("/initial-setup/feehead/update", payload);
export const deleteFeeHead = (payload) => del("/initial-setup/feehead/delete?id="+payload,);

export const fetchwaiverList = () => get("/initial-setup/waiver/list");
export const createWaiver = (payload) => post("/initial-setup/waiver/create", payload);
export const updateWaiver = (payload) => post("/initial-setup/waiver/update", payload);
export const deleteWaiver = (payload) => del("/initial-setup/waiver/delete?id="+payload,);

export const fetchfeeHeadLedgerConfigurationList = () => get("/fee-head/ledger-configuration/list");
export const saveFeeHeadLedgerConfiguration = (payload) => get(`/fee-head/ledger-configuration/save?feeHeadId=${payload.feeHeadId}&ledgerId=${payload.ledgerId}`);
export const deleteFeeHeadLedgerConfiguration = (payload) => del("/fee-head/ledger-configuration/delete?configId="+payload);

export const fetchfeeFineLedgerConfigurationList = () => get("/fee-fine/ledger-configuration/list");
export const savefeeFineLedgerConfiguration = (payload) => get(`/fee-fine/ledger-configuration/save?feeHeadId=${payload.feeHeadId}&ledgerId=${payload.ledgerId}`);
export const deletefeeFineLedgerConfiguration = (payload) => del("/fee-fine/ledger-configuration/delete?configId="+payload);


export const fetchfeeAmountConfigurationList = () => get("/fee-amount/configuration/list");
export const saveFeeAmountConfiguration = (payload) => post("/fee-amount/configuration/save", payload);
export const updateFeeAmountConfiguration = (payload) => post("/fee-amount/configuration/update", payload);
export const deleteFeeHeadLedgerConfigurationAmount = (payload) => del("/fee-amount/configuration/delete?configId="+payload,);

export const fetchstudentWaiverList = (payload) => get(`/student/waiver/assign/list?classDepartmentConfigId=${payload.classDeptConfId}&semesterYearId=${payload.semesterYear}&sessionId=${payload.sessionId}`);
export const saveStudentWaiver = (payload) => post("/student/waiver/assign/save", payload);
export const saveStudentWaiverBatch = (payload) => post("/student/waiver/assign/save/batch", payload);
export const updateStudentWaiver = (payload) => get(`/student/waiver/assign/update?amount=${payload.amount}&assignId=${payload.assignId}&waiverId=${payload.waiverId}`);
export const deleteStudentWaiver = (payload) => del("/student/waiver/assign/delete?assignId="+(payload.assignId),);


export const feeheadDeleteSave = (payload) => get(`/student-accounts/feehead-delete/save?feeHeadId=${payload.feeHeadId}&identificationIds=${payload.identificationIds}`);
export const fetchfeeheadDeletedList = (payload) => get(`/student-accounts/feehead-delete/list?classDepartmentConfigId=${payload.classDeptConfId}&semesterYearId=${payload.semesterYear}&sessionId=${payload.sessionId}`);
export const deletestudentFeeHeadLedgerConfiguration = (payload) => del("/student-accounts/feehead-delete/delete?id="+(payload?.id));

export const fetchstudentCollecionView = (payload) => get(`/student/fees-collection/view?customStudentId=${payload.customStudentId}&semesterYearId=${payload.semesterYearId}`);
export const savestudentCollecionView = (payload) => post("/student/fees-collection/save", payload);
export const createSinglePayslip = (payload) => post("/payslip/create/single", payload);
export const moneyReceiptDownload = (payload) => get("/money-receipt/download?invoiceIds="+payload);
export const fetchviewFeeInvoice = (payload) => get("/fee-invoice/view/for/delete?invoiceId="+payload);
export const deleteFeeInvoice = (payload) => del("/fee-invoice/delete?masterId="+payload);
export const fetchofpsconfigurationView = () => get("/spg/configuration/view");
export const saveofpsconfigurationView = (payload) => post("/spg/configuration/save", payload);
export const fetchfeeAmountConfigurationListBySessionClassDepartment = (payload) => get(`/fee-amount/configuration/list/by/session/class/department?classId=${payload.classId}&departmentId=${payload.departmentId}&sessionId=${payload.sessionId}`);
export const fetchspgStatement = (payload) => get(`/ofps/transaction/report/date-to-date/success/transaction?fromDate=${payload.fromDate}&toDate=${payload.toDate}`);
export const fetchclassDepartmentSessionsemesterPaidInvoiceList = (payload) => get('/student/accounts-report/invoice/list/class-department/session/semester?classDepartmentConfigId='+payload.classDepartmentConfigId+'&sessionId='+payload.sessionId+'&semesterYearId='+payload.semesterYearId);