import { ContainerOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Table from "../../../contents/AntTableResponsive";
import { SelectSession } from '../../select/SelectSession';
import { SelectClassDepartment } from '../../select/SelectClassDepartment';
import { SelectSemesterYearByClassDepartment } from '../../select/SelectSemesterYearByClassDepartment';
import { SelectLedgerCashEqui } from '../../select/SelectLedgerCashEqui';
import moment from 'moment';


export default function ColllectClassWise(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const multiPlePaySlip = useStoreState((state) => state.payslip.multiPlePaySlip);
    const fetchmultiPlePaySlip = useStoreActions((state) => state.payslip.fetchmultiPlePaySlip);
    const collectPayslip = useStoreActions((state) => state.payslip.collectPayslip);
    const deletePayslip = useStoreActions((state) => state.payslip.deletePayslip);
    const downloadPayslip = useStoreActions((state) => state.payslip.downloadPayslip);



    // useEffect(() => {
    //     fetchfeeHeadList();
    // }, [])
    const [data, setData] = useState<any>();
    const formSubmit = (value) => {
        setData(value)
        fetchmultiPlePaySlip(value)
    }

    const updateFomrSubmit = (value) => {
        value.payslipIds = selectedValue.map(item => item.payslipId);
        value.paymentDate = moment(value?.paymentDate).format("YYYY-MM-DD");
        collectPayslip(value);
        setIsModalVisible(false);
        updateForm.resetFields()
        setselectedRowKeys([]);
        setselectedValue([]);
        setTimeout(() => {
            fetchmultiPlePaySlip(data)
        }, 1000);


    }


    const columns = [

        {
            title: 'Payslip Id',
            dataIndex: 'payslipId',
            key: 'payslipId',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Student Id',
            dataIndex: 'customStudentId',
            key: 'customStudentId',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Student Name',
            dataIndex: 'studentName',
            key: 'studentName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Payable Amount',
            dataIndex: 'payableAmount',
            key: 'payableAmount',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Status',
            dataIndex: 'payslipStatusString',
            key: 'payslipStatusString',
            showOnResponse: true,
            showOnDesktop: true,
        },

        {
            title: 'Action',
            key: 'payslipId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">

                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            deletePayslip(record?.payslipId);
                            setTimeout(() => {
                                fetchmultiPlePaySlip(data)
                            }, 1000);
                        }}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                    <Tooltip title="Download">
                        <Button type="primary" onClick={() => downloadPayslip(record?.payslipId)} icon={<DownloadOutlined />} />
                    </Tooltip>

                </Space>
            ),
        },


    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [dep, setDep] = useState<any>();
    const [semy, setsemy] = useState<any>();

    const [selectedRowKeys, setselectedRowKeys] = useState<any>([]);
    const [selectedValue, setselectedValue] = useState<any>([]);

    const onSelectChange = (selectedRowKeys, value) => {
        setselectedRowKeys(selectedRowKeys);
        setselectedValue(value);
        // console.log(value)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const openModal = () => {

        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }

        setIsModalVisible(true)
    }

    const download = () => {
        if (selectedValue.length === 0) {
            notification.error({ message: "Please select student" });
            return;
        }
        downloadPayslip(selectedValue.map(item => item.payslipId).join(","));
    }
    return (
        <>

            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="sessionId"
                            label="Session"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select session " },
                            ]}
                        >

                            <SelectSession />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="classDepartmentConfigId"
                            label="Class Department"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please class department" },
                            ]}
                        >

                            <SelectClassDepartment selected={dep} onChange={e => { setDep(e); setsemy(null) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={5} xl={5}>
                        <Form.Item
                            name="semesterYearId"
                            label="Semester Year"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select year" },
                            ]}
                        >

                            <SelectSemesterYearByClassDepartment selected={semy} onChange={e => { setsemy(e) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SearchOutlined />}>
                                Search
                            </Button>
                        </Space>
                    </Col>

                </Row>
            </Form>

            {multiPlePaySlip?.length > 0 &&
                <>
                    <div className="datatable-responsive">
                        {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                        <Table
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: multiPlePaySlip,
                                pagination: false,
                                bordered: true,
                                rowKey: "studentId",
                                rowSelection: rowSelection,
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                    <br />
                    <Space size="middle" style={{ float: 'right' }}>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => download()}> Download</Button>
                        <Button type="primary" icon={<ContainerOutlined />} onClick={() => openModal()}> Collect</Button>
                    </Space>
                </>
            }


            <Modal
                title="Collect"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Save"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >
                    <Form.Item
                        name="paymentDate"
                        label="Payment Date"
                        className="title-Text"
                        initialValue={moment()}
                        rules={[
                            { required: true, message: "Please select date" },
                        ]}
                    >

                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item
                        name="debitLedgerId"
                        label="Ledger:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select fee head" },
                        ]}
                    >
                        <SelectLedgerCashEqui style={{ width: "100%" }} />
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
