import React from 'react'
import { Card, Tabs } from 'antd';
import CreatePaySlipBatch from './CreatePaySlipBatch.page';
import PaySlipCreateSingle from './PaySlipCreateSingle';


export default function StudentWrapper(props) {

    const { TabPane } = Tabs;

    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Payslip">
                <Tabs defaultActiveKey="1" onChange={(e) => { setActiveTab(e) }}>
                    <TabPane tab="Single" key="1">
                        {activeTab === "1" && <PaySlipCreateSingle />}
                    </TabPane>                    
                    <TabPane tab="Batch" key="2">
                        {activeTab === "2" && <CreatePaySlipBatch />}
                    </TabPane>

                </Tabs>
            </Card>
        </>
    )
}