import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Table from "../../../../contents/AntTableResponsive";
import { SelectClass } from '../../../select/SelectClass';
import { SelectSession } from '../../../select/SelectSession';
import { SelectDepartmentByClass } from '../../../select/SelectDepartmentByClass';
import { SelectSemesterYearByClass } from '../../../select/SelectSemesterYearByClass';
import moment from 'moment';
import { SelectFeeHead } from '../../../select/SelectFeeHead';
import GetColumnSearchProps from '../../../utils/getColumnSearchProps';

export default function FeeAmountConfig(props) {

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const feeAmountConfigurationListBySessionClassDepartment = useStoreState((state) => state.saccount.feeAmountConfigurationListBySessionClassDepartment);
    const fetchfeeAmountConfigurationListBySessionClassDepartment = useStoreActions((state) => state.saccount.fetchfeeAmountConfigurationListBySessionClassDepartment);
    const saveFeeAmountConfiguration = useStoreActions((state) => state.saccount.saveFeeAmountConfiguration);
    const updateFeeAmountConfiguration = useStoreActions((state) => state.saccount.updateFeeAmountConfiguration);
    const deleteFeeHeadLedgerConfigurationAmount = useStoreActions((state) => state.saccount.deleteFeeHeadLedgerConfigurationAmount);
    const fetchdepartmentListByClassId = useStoreActions((state) => state.common.fetchdepartmentListByClassId);
    const fetchsessionYearListByClassId = useStoreActions((state) => state.common.fetchsessionYearListByClassId);
    const [selectedSessionYear, setselectedSessionYear] = useState<any>();
    const [selectedClass, setSelectedClass] = useState<any>();
    const [selectedSemesterYear, setSelectedSemesterYear] = useState<any>();
    const [selectedDepartment, setselectedDepartment] = useState<any>();
    const fetchSessionList = useStoreActions((state) => state.student.fetchSessionList);
    const sessionList = useStoreState((state) => state.student.sessionList);


    const formSubmit = (value) => {
        //console.log(value)
        value.feePayableEndDate = moment(value?.feePayableEndDate).format("YYYY-MM-DD");
        value.feePayableStartDate = moment(value?.feePayableStartDate).format("YYYY-MM-DD");
        value.fineActiveDate = moment(value?.fineActiveDate).format("YYYY-MM-DD");
        saveFeeAmountConfiguration(value);
        // form.resetFields();
        // setClassId(null);
        // setDepId(null);
        // setSemy(null);
    }

    const updateFomrSubmit = (value) => {

        //console.log(value)
        value.configId = configId;
        value.feePayableEndDate = moment(value?.feePayableEndDate).format("YYYY-MM-DD");
        value.feePayableStartDate = moment(value?.feePayableStartDate).format("YYYY-MM-DD");
        value.fineActiveDate = moment(value?.fineActiveDate).format("YYYY-MM-DD");
        updateFeeAmountConfiguration(value);
        setIsModalVisible(false);
    }

    const [configId, setconfigId] = useState<any>();

    const [updatName, setupdateName] = useState<any>();

    const columns = [


        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
            ...GetColumnSearchProps('className', 'Class'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Department',
            dataIndex: 'departmentName',
            key: 'departmentName',
            ...GetColumnSearchProps('departmentName', 'Department'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Semester',
            dataIndex: 'semesterYear',
            key: 'semesterYear',
            ...GetColumnSearchProps('semesterYear', 'Semester'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Session',
            dataIndex: 'sessionName',
            key: 'sessionName',
            ...GetColumnSearchProps('sessionName', 'Session'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Student Type',
            dataIndex: 'studentType',
            key: 'studentType',
            ...GetColumnSearchProps('studentType', 'Student Type'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Head',
            dataIndex: 'feeHead',
            key: 'feeHead',
            ...GetColumnSearchProps('feeHead', 'Fee Head'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fee Amount',
            dataIndex: 'feeAmount',
            key: 'feeAmount',
            ...GetColumnSearchProps('feeAmount', 'Fee Amount'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fine Amount',
            dataIndex: 'fineAmount',
            key: 'fineAmount',
            ...GetColumnSearchProps('fineAmount', 'Fine Amount'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Fine Active Date',
            dataIndex: 'fineActiveDate',
            key: 'fineActiveDate',
            ...GetColumnSearchProps('fineActiveDate', 'Fine Active Date'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Payable Start Date',
            dataIndex: 'feePayableStartDate',
            key: 'feePayableStartDate',
            ...GetColumnSearchProps('feePayableStartDate', 'Payable Start Date'),
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Payable End Date',
            dataIndex: 'feePayableEndDate',
            key: 'feePayableEndDate',
            ...GetColumnSearchProps('feePayableEndDate', 'Payable End Date'),
            showOnResponse: true,
            showOnDesktop: true
        },


        {
            title: 'Action',
            key: 'ledgerId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setupdateName(record.feeHead);
                            setconfigId(record.configId);
                            updateForm.setFieldsValue({
                                feeAmount: record.feeAmount,
                                fineAmount: record.fineAmount,
                                feePayableEndDate: record.feePayableEndDate == null ? null : moment(record.feePayableEndDate, 'YYYY-MM-DD'),
                                feePayableStartDate: record.feePayableStartDate == null ? null : moment(record.feePayableStartDate, 'YYYY-MM-DD'),
                                fineActiveDate: record.fineActiveDate == null ? null : moment(record.fineActiveDate, 'YYYY-MM-DD'),
                            });
                        }}
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                            deleteFeeHeadLedgerConfigurationAmount(record?.configId);
                            setTimeout(() => {
                                fetchfeeAmountConfigurationListBySessionClassDepartment(dta)
                            }, 1000)
                        }}
                    >
                        <Tooltip title="Delete">
                            <Button danger icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [classId, setClassId] = useState<any>();
    const [depId, setDepId] = useState<any>();
    const [semY, setSemy] = useState<any>();
    const [submitForm] = Form.useForm();

    const [dta, setDta] = useState<any>(null)

    const showList = (value) => {
        setDta(value);
        fetchfeeAmountConfigurationListBySessionClassDepartment(value)
    }
    return (
        <>
            <Card title="Fee Amount Configure" >
                <Form
                    layout="vertical"
                    onFinish={formSubmit}
                    id="basic-info"
                    form={form}
                >
                    <Row gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="feeHeadId"
                                label="Fee Head"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select fee head" },
                                ]}
                            >
                                <SelectFeeHead />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="sessionId"
                                label="Session"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select session" },
                                ]}
                            >
                                <SelectSession />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="classId"
                                label="Class"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Select Class" },
                                ]}
                            >

                                <SelectClass selected={classId} onChange={e => { setClassId(e); setDepId(null); setSemy(null); fetchdepartmentListByClassId(e); fetchsessionYearListByClassId(e) }} />
                            </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="departmentId"
                                label="Department"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select Department" },
                                ]}
                            >

                                <SelectDepartmentByClass selected={depId} onChange={e => setDepId(e)} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="semesterYearId"
                                label="Semester Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select sesiion" },
                                ]}
                            >
                                <SelectSemesterYearByClass selected={semY} onChange={e => setSemy(e)} />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="studentType"
                                label="Student Type"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select student type" },
                                ]}
                            >

                                <Select placeholder='Student Type'>
                                    <Select.Option value={1}>Regular</Select.Option>
                                    <Select.Option value={2}>Irregular</Select.Option>
                                    <Select.Option value={3}>Improved</Select.Option>

                                </Select>
                            </Form.Item>
                        </Col>



                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>


                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="feeAmount"
                                label="Fee Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert fee amount" },
                                ]}
                            >

                                <Input placeholder="Fee Amount" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fineAmount"
                                label="Fine Amount"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please insert fine amount" },
                                ]}
                            >

                                <Input placeholder="Fine Amount" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="fineActiveDate"
                                label="Fine Active Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />

                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>

                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="feePayableStartDate"
                                label="Fee Payable Start Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Form.Item
                                name="feePayableEndDate"
                                label="Fee Payable End Date"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select date" },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />

                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                            <Button type="primary" htmlType="submit" style={{ height: 40 }} icon={<SaveOutlined />}>
                                Save
                            </Button>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={3} xl={3}> </Col>


                    </Row>
                </Form>
                <Card title='Fee Amount Configured List' style={{ boxShadow: '0px 0px 0px 0px rgb(82 63 105 / 15%)', border: '0px solid #f0f0f0' }}>
                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                            <Form
                                layout="vertical"
                                id="classConfigInfo"
                                form={submitForm}
                                onFinish={showList}
                            >
                                <Row>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}> </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                        <Form.Item
                                            name="sessionId"
                                            label="Session"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select session" },
                                            ]}
                                        >
                                            <SelectSession />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                        <Form.Item
                                            name="classId"
                                            label="Class"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select class" },
                                            ]}
                                        >
                                            <SelectClass selected={selectedClass} onChange={(e) => { fetchsessionYearListByClassId(e); fetchdepartmentListByClassId(e); setSelectedClass(e); setselectedDepartment(null); setSelectedSemesterYear(null); submitForm.setFieldsValue({ departmentId: null }) }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                                        <Form.Item
                                            name="departmentId"
                                            label="Department"
                                            className="title-Text"
                                            rules={[
                                                { required: true, message: "Please select department" },
                                            ]}
                                        >
                                            <SelectDepartmentByClass selected={selectedDepartment} onChange={(e) => { setselectedDepartment(e) }} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                        <Button type="primary" htmlType="submit" style={{ height: 40 }} >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                    {feeAmountConfigurationListBySessionClassDepartment?.length > 0 &&
                        <div className="datatable-responsive">
                            {/* <Table bordered={true} ledgerList={ledgerList} columns={columns} className="p-datatable-responsive-demo" /> */}

                            <Table
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: feeAmountConfigurationListBySessionClassDepartment,
                                    pagination: true,
                                    rowKey: "configId",
                                    bordered: true
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    }
                </Card>
            </Card>

            <Modal
                title={`Update Fee Amount `}
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => { setIsModalVisible(false); setupdateName('') }}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"

                    onFinish={updateFomrSubmit}
                    id="update"
                    form={updateForm}
                >

                    <strong >Fee Name: {updatName}</strong>  <br />
                    <Form.Item
                        name="feeAmount"
                        label="Fee Amount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input Fee Head name" },
                        ]}
                    >
                        <Input placeholder='Fee Head Name' />
                    </Form.Item>


                    <Form.Item
                        name="fineAmount"
                        label="Fine Amount:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please input serial" },
                        ]}
                    >
                        <Input placeholder='Note' />
                    </Form.Item>

                    <Form.Item
                        name="fineActiveDate"
                        label="Fine Active Date:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select date" },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                    </Form.Item>

                    <Form.Item
                        name="feePayableStartDate"
                        label="Payable Start Date:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select date" },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                    </Form.Item>

                    <Form.Item
                        name="feePayableEndDate"
                        label="Payable End Date:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select date" },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                    </Form.Item>
                </Form>

            </Modal>
        </>
    )
}
