import React, { useEffect } from 'react'
import { useState } from 'react';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Upload } from 'antd'
import { RestOutlined, SaveOutlined } from '@ant-design/icons';

export default function PurchaseSms() {
    const [form] = Form.useForm();
    const fetchSmsBlance = useStoreActions((state) => state.common.fetchSmsBlance);
    const smsBlance = useStoreState((state) => state.common.smsBlance);
    useEffect(() => {
        fetchSmsBlance();
    }, [])


    const purchaseSubmit = (value) => {
        let smsRate = value.totalAmount;
        if (smsRate == '' || smsRate < 15) {
            notification.error({ message: 'You need to purchase sms value more than 15 TK' })
        } else {
            let url = localStorage.getItem("url") + "/epay/sms/purchase/pay?totalSms=" + value.totalSms + "&access_token=" + encodeURIComponent(localStorage.getItem("tok") as any);;
            window.open(url, "_blank");
        }
    }

    const totalSmsCount = (value) => {
        form.setFieldsValue({
            totalAmount: (value * .30).toFixed(2)
        });
    }


    return (
        <>
            <Card title="Purchase SMS">
                <strong>{smsBlance}</strong>
                <br /> <br />
                <Form
                    layout="vertical"
                    onFinish={purchaseSubmit}
                    id="basic-info"
                    form={form}
                >

                    <Row>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="totalSms"
                                label="Total SMS"
                                className="title-Text"
                            >
                                <InputNumber onChange={(e) => totalSmsCount(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item
                                name="totalAmount"
                                label="Total Amount"
                                className="title-Text"
                            >
                                <InputNumber disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <div className="pull-right">
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >Purchase</Button>
                                &nbsp;&nbsp;
                                <Button type="primary" danger icon={<RestOutlined />} onClick={() => form.resetFields()} >Reset</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}